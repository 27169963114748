import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import makeSelectUserPortal from "containers/UserPortal/selector";
import reducer from "containers/UserPortal/reducer";
import saga from "containers/UserPortal/saga";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import { styled as styledMui } from "@mui/material/styles";
import {
  ButtonBase,
  Box,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { IconCheckCircle } from "images/icons/svg-components";
import { formattedDate } from "utils/date";
import { redeemReward } from "containers/UserPortal/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { checkMediaType } from "utils/checkMediaType";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import API from "utils/userAPI";
import { ImageUserPortalDefault } from "images";
import ImageLoader from "utils/imageLoader";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const QuestPage = ({
  quests,
  questHoldings,
  setSelectedQuest,
  memberships,
  membershipId,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("allquests");
  const [displayedQuests, setDisplayedQuests] = useState(quests);
  const [progress, setProgress] = useState({});
  const tabs = [
    { name: t("UserQuests.allquests"), tab: "allquests" },
    { name: t("UserQuests.inprogress"), tab: "inprogress" },
    { name: t("UserQuests.completed"), tab: "completed" },
  ];
  const userId = localStorage.getItem("userId");
  const { membershipHoldings } = useSelector((state) => state.userPortal);
  useEffect(() => {
    async function fetchData() {
      if (userId && userId !== "") {
        const questsProgress = await API.get(
          `users/getMatrix/${membershipId}?qrId=${localStorage.getItem("qr")}`
        );
        if (
          questsProgress.data?.status === "success" &&
          questsProgress.data?.data
        ) {
          let progressObj = {};
          Object.entries(questsProgress.data?.data).forEach(
            ([questId, results]) => {
              let progress = results.reduce((questProgress, action) => {
                return action ? questProgress + 1 : questProgress;
              }, 0);
              const checkMembership =
                membershipHoldings?.length > 0 &&
                membershipHoldings
                  ?.map((m) => m.typeId)
                  ?.some((id) =>
                    quests
                      ?.filter((quest) => quest._id === questId)[0]
                      ?.membershipTier?.includes(id)
                  );
              progress = checkMembership ? progress + 1 : progress;
              progressObj[questId] = (
                (progress / (results.length + 1)) *
                100
              ).toFixed(0);
            }
          );
          setProgress(progressObj);
        }
      }
    }

    fetchData();
  }, [quests, userId]);

  useEffect(() => {
    if (selectedTab === "allquests") {
      setDisplayedQuests(quests);
    } else if (selectedTab === "inprogress") {
      const toDisplay = quests.filter(
        (q) => progress[q._id] > 0 && progress[q._id] < 100
      );
      setDisplayedQuests(toDisplay);
    } else if (selectedTab === "completed") {
      const toDisplay = quests.filter((q) => progress[q._id] == 100);
      setDisplayedQuests(toDisplay);
    }
  }, [selectedTab]);

  const renderProgressText = (quest) => {
    const questProgress = progress[quest._id] || 0;
    if (questProgress == 0) {
      return (
        <RedeemButton status="todo" onClick={() => setSelectedQuest(quest)}>
          {t("UserQuests.todo")}{" "}
        </RedeemButton>
      );
    } else if (questProgress == 100) {
      return (
        <RedeemButton
          status="completed"
          onClick={() => setSelectedQuest(quest)}
        >
          {" "}
          {t("UserQuests.completed")}
        </RedeemButton>
      );
    } else {
      return (
        <RedeemButton
          status="inprogress"
          onClick={() => setSelectedQuest(quest)}
        >
          <FiberManualRecordIcon
            sx={{
              width: "8px",
              height: "8px",
              color: "#0bd680",
            }}
          />{" "}
          {t("UserQuests.inprogress")}{" "}
        </RedeemButton>
      );
    }
  };
  return (
    <Wrapper>
      <QuestTabs>
        {tabs.map((tab) => (
          <QuestTab
            className={`${selectedTab === tab.tab ? "active" : ""}`}
            onClick={() => {
              setSelectedTab(tab.tab);
            }}
          >
            {tab.name}
          </QuestTab>
        ))}
      </QuestTabs>
      <QuestList>
        {displayedQuests?.map((quest) => (
          <QuestCard>
            <QuestContent>
              <ImageLoader
                src={quest?.image}
                styles={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "12px",
                  objectFit: "cover",
                }}
              />
              <TextWrapper>
                <QuestName>{quest?.name}</QuestName>
                <QuestDesc>{quest?.description}</QuestDesc>
              </TextWrapper>
              {renderProgressText(quest)}
            </QuestContent>
            <QuestProgress>
              <Box sx={{ width: "100%" }}>
                <StyledLinearProgress
                  variant="determinate"
                  value={progress[quest._id] || 0}
                />
              </Box>
              <ProgressText>
                {progress[quest._id] || 0}% {t("UserQuests.completed")}
              </ProgressText>
            </QuestProgress>
            <QuestFooter>
              <DateContent>
                <span className="date-prefix"> {t("UserPortal.endson")}</span>
                <span>{formattedDate(quest?.endDate)}</span>
              </DateContent>
              <MembershipList>
                {quest?.membershipTier?.map((tier) => (
                  <MembershipTier>
                    {memberships.filter((m) => m._id === tier)[0]?.name}
                  </MembershipTier>
                ))}
              </MembershipList>
            </QuestFooter>
          </QuestCard>
        ))}
      </QuestList>
    </Wrapper>
  );
};

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.darkerGrey,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#0bd680",
  },
}));

const QuestProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;
const ProgressText = styled.div`
  ${(props) => props.theme.typography.bodyS};
  color: var(--Green, #0bd680);
`;

const DateContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyS};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
  .date-prefix {
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;
const MembershipList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MembershipTier = styled.div`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkerGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const RedeemButton = styled.div`
  display: flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  cursor: pointer;
  border: ${(props) => `1px solid ${props.theme.palette.darkGrey}`};
  ${(props) => props.theme.typography.bodySMedium};
  color: ${(props) =>
    props.status === "todo" ? props.theme.palette.lightGrey : "#0bd680"};
  background: ${(props) =>
    props.status !== "todo" && props.theme.palette.darkerGrey};
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodyXSMedium};
  }
`;

const QuestFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const QuestName = styled.div`
  align-self: stretch;
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
`;
const QuestDesc = styled.div`
  align-self: stretch;
  ${(props) => props.theme.typography.bodyS};
  color: ${(props) => props.theme.palette.lightGrey};
  line-height: normal;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;
const QuestList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;
const QuestContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const QuestCard = styled.div`
  display: flex;
  width: 562px;
  height: 229px;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  border: ${(props) => `1px solid ${props.theme.palette.darkGrey}`};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
`;

const QuestTabs = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const QuestTab = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: flex-start;
  gap: 10px;
  line-height: normal;
  cursor: pointer;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
  &.active {
    color: ${(props) => props.theme.palette.lightBlue};
  }
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.subHeader};
  }
`;

export default QuestPage;
