/*
 *
 * UserPortal actions
 *
 */

import {
  DEFAULT_ACTION,
  CREATE_USER,
  VERIFY_EMAIL,
  REDEEM_REWARD,
  MINT_NFT_TO_USER,
  FETCH_USER_MEMBERSHIP_HOLDINGS,
  FETCH_USER_REWARD_HOLDINGS,
  INIT_DATA,
  FETCH_USER_PROFILE,
  TOGGLE_SNACKBAR,
  TOGGLE_TX_SNACKBAR,
  LOGOUT_USER,
  TOGGLE_VERIFY_MOBILE_SNACKBAR,
  TOGGLE_ERROR_SNACKBAR,
  FETCH_USER_QUEST_HOLDINGS,
} from "./constants";

import API from "utils/userAPI";
export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function createUser(data) {
  return async (dispatch) => {
    API.post("auth/user/register", data)
      .then((result) => {
        dispatch({ type: CREATE_USER, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
}

export const toggleVerifyMobileSnackbar = () => ({
  type: TOGGLE_VERIFY_MOBILE_SNACKBAR,
});

export function verifyEmail(token) {
  return async (dispatch) => {
    API.get(`auth/verify/${token}`)
      .then((result) => {
        dispatch({ type: VERIFY_EMAIL, payload: result.data });
        localStorage.setItem("userTokens", JSON.stringify(result.data?.data));
      })
      .catch((err) => console.log(err));
  };
}

export function mintNftToUser(email, membershipId) {
  return async (dispatch) => {
    API.post("users/mint", { email, membershipId })
      .then((result) => {
        dispatch({ type: MINT_NFT_TO_USER, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
}

export function initialiseUserPortal(brandId) {
  return async (dispatch) => {
    API.get(`/landingPage/${brandId}`)
      .then((result) => {
        dispatch({
          type: INIT_DATA,
          payload: {
            membershipData: result.data.data.memberships,
            rewardData: result.data.data.rewards,
            brand: result.data.data.brand,
          },
        });
      })
      .catch((err) => console.log(err));
  };
}

export function fetchUserRewardHoldings() {
  return async (dispatch) => {
    API.get("users/holdings/rewards")
      .then((result) => {
        console.log(result.data, "result.data");
        dispatch({ type: FETCH_USER_REWARD_HOLDINGS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
}

export function fetchUserQuestHoldings() {
  return async (dispatch) => {
    API.get("users/holdings/quests")
      .then((result) => {
        dispatch({ type: FETCH_USER_QUEST_HOLDINGS, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
}

export function fetchUserMembershipHoldings(
  brandId,
  toggle = false,
  toggleTx = false
) {
  return async (dispatch) => {
    try {
      API.get("users/holdings/memberships")
        .then(async (result) => {
          // dispatch({ type: FETCH_USER_MEMBERSHIP_HOLDINGS, payload: result.data });
          let res = await API.get("users/holdings/rewards");
          let res2 = await API.get("users/holdings/quests");
          // let res3 = await API.get("users/holdings/rewards");
          dispatch({
            type: FETCH_USER_MEMBERSHIP_HOLDINGS,
            payload: {
              membershipHoldings: result?.data?.data, // gets latest one
              // membershipHoldings: result.data.data[result.data.data.length - 1], // gets latest one
              // membershipHoldingsAll: result.data.data, // gets latest one
              // rewardData: res2?.data?.data?.rewards,
              rewardHoldings: res?.data?.data,
              questHoldings: res2?.data?.data,
              // membershipData: res2?.data?.data?.memberships?.filter(
              //   (a) =>
              //     a.transactionStatus !== undefined &&
              //     a.transactionStatus !== null &&
              //     a.transactionStatus !== "" && a.isDefault === false
              // ),
              // brand: res2.data.data.brand,
            },
          });
          if (toggle) {
            // toggles successfully claimed membership snackbar
            dispatch(toggleSnackbar());
          } else if (toggleTx) {
            // toggles successful TX snackbar
            dispatch(toggleTxSnackbar());
          }
        })
        .catch((err) => {
          API.get(`/landingPage/${brandId}`).then((result) => {
            console.log(result, "result");
            dispatch({
              type: INIT_DATA,
              payload: {
                membershipData: result.data.data.memberships,
                rewardData: result.data.data.rewards,
                brand: result.data.data.brand,
              },
            });
          });
        });
    } catch (error) {
      console.log("error while fetching holdings", error);
    }
  };
}

// Checks if a device has streamed a song
export const checkDeviceStreamedSong = (
  deviceId,
  trackId,
  userId = undefined
) => {
  return async (dispatch) => {
    return API.get("/users/check_signup_song", {
      params: { deviceId, trackId, userId },
    })
      .then((result) => {
        dispatch({ type: DEFAULT_ACTION, payload: result.data });
        return result;
      })
      .catch((e) => {
        // user has not streamed song
        console.log("Device has not streamed song");
      });
  };
};

// inputs userId has listened to track
export const inputUserIdStreamedSong = (userId, trackId) => {
  API.post(`/users/save_signup_song`, { userId, trackId })
    .then((result) => {
      dispatch({ type: DEFAULT_ACTION, payload: result.data });
    })
    .catch((err) => console.log(err));
};

export const redeemReward = (rewardId, userId) => {
  // console.log("to the api call runs");
  return async (dispatch) => {
    API.post("users/redeem", { rewardId, userId })
      .then((result) => {
        dispatch({ type: REDEEM_REWARD, payload: result.data });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchMe = (userId) => {
  return async (dispatch) => {
    API.get("users/me", { userId })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
};

// export const fetchUserProfile = (brandId, save = false) => {
//   return async (dispatch) => {
//     API.get(`users/profile?brandId=${brandId}`)
//       .then((result) => {
//         dispatch({ type: FETCH_USER_PROFILE, payload: result.data.data });
//         if (save) {
//           let userStore = JSON.parse(localStorage.getItem("userTokens"));
//           userStore.user = result.data.data.user;
//           localStorage.setItem("userTokens", JSON.stringify(userStore));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };

export const fetchUserProfile = (
  membershipId = undefined,
  brandId,
  save = false
) => {
  return async (dispatch) => {
    if (membershipId && brandId) {
      API.get(`users/profile?membershipId=${membershipId}&brandId=${brandId}`)
        .then((result) => {
          dispatch({ type: FETCH_USER_PROFILE, payload: result.data.data });
          if (save) {
            let userStore = JSON.parse(localStorage.getItem("userTokens"));
            userStore.user = result.data.data.user;
            localStorage.setItem("userTokens", JSON.stringify(userStore));
          }
        })
        .catch((err) => console.log(err));
      return;
    }
    API.get(`users/profile`)
      .then((result) => {
        dispatch({ type: FETCH_USER_PROFILE, payload: result.data.data });
        if (save) {
          let userStore = JSON.parse(localStorage.getItem("userTokens"));
          userStore.user = result.data.data.user;
          localStorage.setItem("userTokens", JSON.stringify(userStore));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const toggleSnackbar = () => ({
  type: TOGGLE_SNACKBAR,
});

export const toggleTxSnackbar = () => ({
  type: TOGGLE_TX_SNACKBAR,
});

export const toggleErrorSnackbar = () => ({
  type: TOGGLE_ERROR_SNACKBAR,
});

export const logoutUser = () => {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT_USER,
    });
  };
};
