import React from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Backdrop, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Check, Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 540,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "40px",
  color: "black",
  borderRadius: 4,
  background: "#1A1A22",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
};

const codeStyle = {
  background: "#141417",
  width: "100%",
  borderRadius: "8px",
  color: "#7F86AD",
  padding: "16px",
  color: "#7F86AD",
  lineHeight: "28px",
  fontSize: "16px",
  fontFamily: "Inter",
  wordWrap: "break-word", 
};


const EmbedModal = ({ open, setOpen, link }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const iframeString = `<iframe id='chat-iframe' src='${link}'></iframe>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(iframeString);
    setIsCopied(true);
  };

  const onClose = () => {
    setOpen(false);
    setIsCopied(false);
  };

  return (
    <Modal
      open={open}
      slots={{ backdrop: Backdrop }}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            fontWeight={600}
            fontFamily={"Inter"}
            color="#F4F3EE"
            fontSize={"30px"}
            lineHeight={"36.31px"}
          >
            Embed Code
          </Typography>
          <Close onClick={onClose} sx={{ color: "#FFF", cursor: "pointer" }} />
        </Box>
        <StepsWrapper>
          <Label>How to embed your landing page?</Label>
          <Item>1. Ensure your landing page is ready to be launched</Item>
          <Item>2. Copy the embed code below</Item>
          <Item>3. Paste this code into a blank page on your website</Item>
        </StepsWrapper>

        <EmbedCodeWrapper>
          <Label>Embed code</Label>
          <div style={codeStyle}>
            {iframeString}
          </div>
        </EmbedCodeWrapper>
        <CopyButton
          onClick={handleCopy}
          sx={{ backgroundColor: isCopied ? "#00BC6D" : "" }}
        >
          {!isCopied ? (
            <>
              Copy embed code
              <ContentCopyIcon fontSize="14px" />
            </>
          ) : (
            <>
              Copied! <Check />
            </>
          )}
        </CopyButton>
      </Box>
    </Modal>
  );
};

const StepsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const EmbedCodeWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CopyButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
  width: "100%",
  height: "56px",
  borderRadius: "8px",
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: "400",
  fontFamily: "Inter",
  textTransform: "none",
  padding: "8px 20px",
  gap: "12px",
}));

const Label = styled("div")`
  color: ${(props) => props.theme.palette.lightBlue};
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const Item = styled("div")`
  color: ${(props) => props.theme.palette.lightGrey};
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
`;

export default EmbedModal;
