/**
 *
 * AddRewardModal
 *
 */

import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, CircularProgress } from "@mui/material";
import makeSelectOnboardingPage from "containers/OnboardingPage/selectors";
import { createUser } from "containers/UserPortal/actions";
import CheckoutForm from "components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import "./style.css";
import { ImageCampaignLogo } from "images";
import { Close } from "@mui/icons-material";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function RedeemModel({
  showModal,
  setShowModal,
  page
}) {


  const handleClose = () => {
    // setError("");
    setShowModal(false);
  };



  return (
    <>
    <ModalLayout
      open={showModal}
      onClose={handleClose}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      style={{zIndex:9}}
      aria-describedby="modal-modal-description"
    >
      <ModalWrapperBox>
        <CustomModal>
          <Close
            sx={{
              position: "absolute",
              top: "36px",
              right: "36px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
            
          <Content>
          <Header>
              <PayHeader> {page=="auth" ?"":"Redeem"}</PayHeader>
            </Header>
          {page=="redeem" && "Reward redeemed!!"} 
          </Content>
        </CustomModal>
      </ModalWrapperBox>
    </ModalLayout>
    </>
  );
}
const EmptyMembership = styled.div`
  height: 400px;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightGrey};
  gap: 8px;
`;

const MainText = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  align-self: center;
`;

const PayHeader = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
  align-self: flex-start;
`;

const SecondaryText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const ModalLayout = styledMui(Modal)(() => ({}));

const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 60px 40px 60px;
  width: 100%;
  height: 100%;
`;

const ModalWrapperBox = styledMui(Box)(({ theme, clientSecret }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: clientSecret ? "690px" : "627px",
  background: theme.palette.darkestGrey,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ErrorWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: -16px 0;
`;

const Error = styled.div`
  position: absolute;
  font-size: 14px;
  color: red;
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  ${(props) => props.theme.typography.bodyMBold};

  .body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.palette.lightGrey};
    gap: 8px;
  }

  .brand-name {
    ${(props) => props.theme.typography.header2};
    color: ${(props) => props.theme.palette.lightGrey};
  }

  .membership-name {
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
  }
`;

const BrandImage = styled.img`
  width: 300px;
  border-radius: 12px;
`;

const Total = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkerGrey};
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const DividerStyles = {
  border: "1px solid #352B42",
  width: "100%",
  margin: "10px 0",
};

const PayButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 0",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

RedeemModel.propTypes = {
  setShowModal: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
 
});

function mapDispatchToProps(dispatch) {
  
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(RedeemModel);
