/**
 *
 * RewardDetailsForm
 *
 */

import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { FormControl, NativeSelect, InputBase } from "@mui/material";
import RadioButtonGroup from "components/RadioButtonGroup";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { RemoveButton, handleRemoveFile } from "components/RemoveButton";



function RewardDetailsForm({ formValues, handleChange, categories }) {
  const { name, description, redemptionText, category, soulbound, audio } = formValues;
  const { t } = useTranslation();
  const ref = useRef();
  const [imageRemovable, setImageRemovable] = useState(!!audio ?? false);
  const imgUrl = audio instanceof File ? URL.createObjectURL(audio) : audio;


  function renderCategoryOptions() {
    return categories.map((category) => (
      <option value={category} key={category}>
        {category}
      </option>
    ));
  }

  return (
    <Fields>
      <Field>
        <Label>{t("RewardDetailsForm.name")}</Label>
        <Sublabel> {t("RewardDetailsForm.nameSublabel")}</Sublabel>
        <FormField
          sx={formFieldOverrides}
          placeholder={t("RewardDetailsForm.nameplaceholder")}
          inputProps={{
            "aria-label": "name",
            maxLength: "75",
          }}
          fullWidth
          value={name}
          onChange={(event) => {
            handleChange(event, "name");
          }}
        />
      </Field>
      <Field>
        <Label>{t("RewardDetailsForm.description")}</Label>
        <Sublabel> {t("RewardDetailsForm.descriptionSublabel")}</Sublabel>
        <LargeFormField
          sx={formFieldOverrides}
          placeholder={t("RewardDetailsForm.placeholderdescription")}
          inputProps={{
            "aria-label": "description",
            maxLength: "300",
          }}
          fullWidth
          multiline
          maxRows={4}
          value={description}
          onChange={(event) => {
            handleChange(event, "description");
          }}
        />
      </Field>
      <Field>
        <Label>{t("RewardDetailsForm.redemptionText")}</Label>
        <Sublabel> {t("RewardDetailsForm.redemptionTextSublabel")}</Sublabel>
        <LargeFormField
          sx={formFieldOverrides}
          placeholder={t("RewardDetailsForm.placeholderRedemptionText")}
          inputProps={{
            "aria-label": "redemptionText",
            maxLength: "300",
          }}
          fullWidth
          multiline
          maxRows={4}
          value={redemptionText}
          onChange={(event) => {
            handleChange(event, "redemptionText");
          }}
        />
      </Field>
      <DoubleField>
        <Field>
          <Label>{t("RewardDetailsForm.type")}</Label>
          <Sublabel> {t("RewardDetailsForm.typeSublabel")}</Sublabel>
          <FormControl>
            <NativeSelect
              id="category"
              value={category}
              onChange={(event) => {
                handleChange(event, "category");
              }}
              IconComponent={() => <KeyboardArrowDownOutlinedIcon />}
              input={
                <FormField
                  sx={{
                    ...formFieldOverrides,
                    width: "100%",
                    minWidth: "281px",
                  }}
                />
              }
            >
              {renderCategoryOptions()}
            </NativeSelect>
          </FormControl>
        </Field>
        <Field>
          <RadioButtonGroup
            label={t("RewardDetailsForm.transferrable")}
            subLabel={t("RewardDetailsForm.transferrableSublabel")}
            onChange={(event) => {
              handleChange(event, "soulbound");
            }}
          />
        </Field>
      </DoubleField>
      {category === 'Content' && (
              <Field>
              <Label>{t("RewardEligibilityForm.uploadAudiolabel")}</Label>
                <Sublabel>
                  {t("RewardEligibilityForm.uploadAudioSublabel")}
                </Sublabel>
              <UploadSection>
                <UploadWrapper>
                  <FileUploadButton variant="contained" component="label">
                    <AddIcon />
                    Upload File
                    <input
                      id="logo"
                      hidden
                      ref={ref}
                      accept="audio/*,video/*"
                      type="file"
                      onChange={(event) => {
                        document.getElementById("audioName").innerText =
                          event.target.files[0].name;
                        setImageRemovable(true);
                        handleChange(event, "audio");
                      }}
                    />
                  </FileUploadButton>
                </UploadWrapper>
                <FileRemoveWrapper>
                  <FileName id="audioName">{audio?.name || imgUrl}</FileName>
                  {imageRemovable && (
                    <RemoveButton
                      sx={{ color: "#6096ff" }}
                      onClick={() => {
                        handleRemoveFile(ref, "audio", handleChange);
                        setImageRemovable(false);
                        document.getElementById("audioName").innerText = "";
                      }}
                    />
                  )}
                </FileRemoveWrapper>
              </UploadSection>
              </Field>
      )}

    </Fields>
  );
}

const DoubleField = styled.div`
  display: flex;
  gap: 50px;
  width: 100%;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 49px;
`;
const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const UploadSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const FileUploadButton = styledMui(Button)(() => ({
  justifyContent: "flex-start",
  background: "transparent",
  border: "1px solid #352B42",
  borderRadius: 6,
  textTransform: "none",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#7F86AD",
  padding: "13px 20px",
  "&.MuiButton-root": { display: "flex", gap: "8px" },
  "&:hover": {
    backgroundColor: "#7F86AD",
    color: "#352B42",
  },
}));

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileName = styled.span`
  ${(props) => props.theme.typography.bodyM}
  color: ${(props) => props.theme.palette.lightBlue};
`;

const FileRemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

export const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

export const Sublabel = styled(Label)`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const FormField = styledMui(InputBase)({
  display: "flex",
});

const LargeFormField = styledMui(FormField)({
  minHeight: "128px",
  overflow: "hidden",
  alignItems: "flex-start",
});

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  svg: {
    color: "#F4F3EE",
  },

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
  textarea: {
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

RewardDetailsForm.propTypes = {
  formValues: PropTypes.object,
  handleChange: PropTypes.func,
};

export default memo(RewardDetailsForm);
