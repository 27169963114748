import React, { useState, useEffect } from "react";
import styled from "styled-components";
const ImageLoader = ({ src, alt, styles, className, onClick = () => {} }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;

    image.onload = () => {
      setIsLoaded(true);
    };
  }, [src]);

  return (
    <div className={className}>
      {isLoaded ? (
        <img src={src} alt={alt} style={styles} onClick={onClick} />
      ) : (
        <SkeletonLoading style={styles} />
      )}
    </div>
  );
};

const SkeletonLoading = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(33, 33, 46, 0) 0%,
    #352b42 54.57%,
    rgba(33, 33, 46, 0) 100%
  );
  background-size: 200% 100%;
  background-position: 0 0;
  background-repeat: repeat-y;
  animation: loading 1.5s infinite;
  background-color: #21212e;
  @keyframes loading {
    100% {
      background-position: -200% 0;
    }
    0% {
      background-position: 200% 0;
    }
  }
`;

export default ImageLoader;
