import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  InputBase,
  FormControl,
  NativeSelect,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { LANDING_PAGE_THEME } from "utils/constants";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { postDeviceIdStreamedSong } from "../../actions";
import { IconSpotify, IconPeople } from "images/icons/svg-components";
import qs from "qs";
import { Support } from "aws-sdk";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import ImageLoader from "utils/imageLoader";

function EditSignUpModalDisplay({ formValues, brandId,defaultMembershipId }) {
  const {
    theme,
    fontFamily,
    headerImage,
    text,
    spotifyText,
    membershipText,
    spotifyLink = "",
  } = formValues;
  const queryParams = queryString.parse(location.search);
  const [spotifyId, setSpotifyId] = useState(
    queryParams?.spotifyId || localStorage.getItem("spotifyId") || ""
  );
const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const themeValues = LANDING_PAGE_THEME[theme || "dark"];
  const scopes =
    "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const renderboi = 0;
  const headerImageUrl =
    headerImage instanceof File
      ? URL.createObjectURL(headerImage)
      : headerImage;

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      navigate(-1);
    }
  };

  let deviceId = localStorage.getItem("deviceId");
  // If not, generate a new identifier and store it in local storage
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  if (!deviceId) {
    deviceId = generateUniqueId();
    localStorage.setItem("deviceId", deviceId);
  }

  function saveDevice() {
    dispatch(postDeviceIdStreamedSong(deviceId, "3JsH3qnwhYGs20PBMsCgNx"));
  }

  function checkSpotifyLink(spotifyLink) {
    if (spotifyLink.startsWith("https://open.spotify.com/track")) {
      return "Song";
    }
    else if (spotifyLink.startsWith("https://open.spotify.com/album")) {
      return "Album";
    }
    else if (spotifyLink.startsWith("https://open.spotify.com/playlist")) {
      return "Playlist";
    }
  }

  return (
    <Wrapper
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {spotifyId === "" ? (
        <>
          <ChipWrapper
            backgroundColor={themeValues.backgroundColor}
            color={themeValues.color}
            fontFamily={fontFamily}>
            <ImageLoader src={headerImageUrl} styles={{
              padding: 0,
              margin: 0,
              objectFit: "cover",
              width: "320px",
              height: "320px"
            }} />
            <GroupedText>
              <MainText color={themeValues.color} fontFamily={fontFamily}>{text[0]}</MainText>
              <BaseText color={themeValues.color} fontFamily={fontFamily}>{text[1]}</BaseText>
            </GroupedText>
            <BaseText color={themeValues.color} fontFamily={fontFamily}>{text[2]}</BaseText>
            <ButtonGroup>
              <SpotifyButton
                variant="contained"
                disableRipple
                onClick={() =>{
                  dataLayer.push({
                    'event': 'click_spotify',
                    'eventCategory': 'Button Click',
                    'eventAction': 'Click',
                  });
                  defaultMembershipId&& (window.location.href =
                  "https://accounts.spotify.com/authorize?" +
                  qs.stringify({
                    response_type: "code",
                    client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
                    scope: scopes,
                    redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
                    state: JSON.stringify({ brandId, spotifyLink,id:localStorage.getItem("userId") || "" ,membershipId:defaultMembershipId || ""}),
                  }))
                }
                }
                fontFamily={fontFamily}>
                <IconSpotify className="spotify-icon" />
                {spotifyText}
              </SpotifyButton>
            </ButtonGroup>
          </ChipWrapper>
          <BottomTextWrapper>
            <BottomText fontFamily={fontFamily}>
              {t("EditSignUpModalDisplay.subdescription")}{" "}
              <span>
                <Link to={"/privacy-policy"}> {t("EditSignUpModalDisplay.privacypolicy")}</Link>
              </span>
            </BottomText>
            <BottomText fontFamily={fontFamily}>
            {t("EditSignUpModalDisplay.and")}{" "}
              <span>
                <Link to={"/terms-of-use"}> {t("EditSignUpModalDisplay.terms")}</Link>
              </span>
              .
            </BottomText>
            {/* <BottomText><span>Manage your permissions</span></BottomText> */}
          </BottomTextWrapper>
        </>
      ) : (
        <ContentWrapper>
          <Box>
            <ImageLoader src={headerImageUrl} styles={{
              display: "flex",
              width: "120px",
              height: "120px",
              "@media (max-width: 768px)": {
                width: "200px",
                height: "200px"
              }
            }} />
            <div className="text-wrapper">
              <div className="text-top">
                <div className="header2">{text[0]}</div>
                <div className="bodyS">{text[1]}</div>
              </div>
              <div className="bodyG">
                {t(`UserPortal.${checkSpotifyLink(spotifyLink)}`)}{" "}{t("UserPortal.hassaved")}
              </div>
            </div>
          </Box>
          <MainTextWrapper>
            <span className="title">{t("EditSignUpModalDisplay.join")}</span>
            <span className="subtitle">{t("EditSignUpModalDisplay.exclusive")} {text[0]}</span>
          </MainTextWrapper>
          <SubTextWrapper>
            <Checkbox
              disableRipple
              color="default"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              checkedIcon={
                <CheckedBox>
                  <CheckIcon fontSize="24px" />
                </CheckedBox>
              }
              icon={<CheckBoxIcon />}
              sx={checkboxStyles}
            />
            {/* <Checkbox defaultChecked sx={checkboxStyles} checkedIcon={<CheckIcon/>}/> */}
            <span className="subtitle">
            {t("EditSignUpModalDisplay.joinlabel")} {" "}
              <span>
                <Link to={"/privacy-policy"}>{t("EditSignUpModalDisplay.privacypolicy")}</Link>
              </span>
              .
            </span>
          </SubTextWrapper>
          <JoinMembershipButton
            onClick={() => {
              navigate("/login", {
                state: {
                  brandId: brandId,
                  spotifyId: spotifyId,
                  albumSaved: true,
                  isChecked: checked,
                  membershipId:defaultMembershipId,
                },
              });
            }}
          >
           {t("EditSignUpModalDisplay.join")}
          </JoinMembershipButton>
        </ContentWrapper>
      )}
    </Wrapper>
  );
}

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 18,
  height: 18,
  background: theme.palette.lightGrey,
  padding: 0,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));
const checkboxStyles = {
  svg: {
    color: "#F4F3EE",
  },
  borderRadius: "4px",
  color: "#F4F3EE",
  padding: 0,
};

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const SupportWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

const ChipWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 12px;
`;

const BottomTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

const GroupedText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const BottomText = styled.div`
  color: var(--white, #f4f3ee);
  text-align: center;
  font-family: ${(props) => props.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span {
    text-decoration-line: underline;
    a {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const Box = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border: 1px solid #352b42;
  border-radius: 6px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    border: none;
    padding: 0;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  img {
    display: flex;
    width: 120px;
    height: 120px;
    @media (max-width: 768px) {
      width: 200px;
      height: 200px;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    .top-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-self: stretch;
    }
    .header2 {
      ${(props) => props.theme.typography.subHeader}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyS {
      ${(props) => props.theme.typography.bodyM}
      color: ${(props) => props.theme.palette.white};
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .bodyG {
      ${(props) => props.theme.typography.bodyS};
      color: #0bd680;
      line-height: normal;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .title {
    text-align: center;
    ${(props) => props.theme.typography.header1};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.header2};
    }
  }
  .subtitle {
    text-align: center;
    ${(props) => props.theme.typography.header3};
    color: ${(props) => props.theme.palette.white};
    line-height: normal;
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
  }
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 12px;
  .subtitle {
    ${(props) => props.theme.typography.bodyS};
    font-size: 15px;
    line-height: normal;
    color: ${(props) => props.theme.palette.lightGrey};
    span {
      a {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;

const MainText = styled.div`
  color: ${(props) => props.color};
  ${(props) => props.theme.typography.header2};
  font-family: ${(props) => props.fontFamily};
  line-height: normal;
  word-wrap: break-word;
  text-align: center;
`;

const BaseText = styled.div`
  color: ${(props) => props.color};
  ${(props) => props.theme.typography.header3};
  font-family: ${(props) => props.fontFamily};
  line-height: normal;
  word-wrap: break-word;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  padding: 12px 0px 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background-color: #1ed760;
  border-radius: 6px;
  .spotify-icon {
    width: 40px;
    height: 40px;
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }
`;

const SpotifyButton = styled.div`
  ${(props) => props.theme.typography.header2}
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${(props) => props.theme.palette.black};
  font-family: ${(props) => props.fontFamily};
  line-height: normal;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodySMedium}
    gap: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 60px;
  gap: 32px;
  border-radius: 16px;
  max-width: 627px;
  background: #131316;
  @media (max-width: 768px) {
    width: 350px;
    padding: 40px 32px;
    gap: 20px;
  }
`;

const JoinMembershipButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  height: "60px",
  lineHeight: "normal",
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    height: "48px",
  },
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

EditSignUpModalDisplay.propTypes = {
  formValues: PropTypes.object,
  membershipData: PropTypes.array,
};

export default memo(EditSignUpModalDisplay);
