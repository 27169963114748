import axios from "axios";
import { setupCache } from "axios-cache-adapter";
// Create `axios-cache-adapter` instance
// const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
// });

let baseURL = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/`;
const jwtInterceptor = axios.create({
  withCredentials: true,
  baseURL,
  // adapter: cache.adapter,
});

jwtInterceptor.interceptors.request.use((config) => {
  let tokens = localStorage.getItem("userTokens");
  if (tokens) {
    tokens = JSON.parse(tokens);
    if (tokens && tokens.accessToken) {
      if (tokens.accessToken) {
        config.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
      }
    }
  }
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response && error.response?.status === 401) {
      if (error.response.data.message === "Invalid email or password") {
        return Promise.reject(error);
      }
      let tokens = localStorage.getItem("userTokens");
      let refreshToken = "";
      if (tokens) {
        tokens = JSON.parse(tokens);
        refreshToken = tokens.refreshToken;
      }
      try {
        let apiResponse = await axios.post(`${baseURL}auth/refresh`, {
          refreshToken,
        });
        if (tokens) {
          tokens["accessToken"] = apiResponse.data.data.accessToken;
          localStorage.setItem("tokens", JSON.stringify(tokens));
        }
        error.config.headers[
          "Authorization"
        ] = `Bearer ${apiResponse.data.data.accessToken}`;
        return axios(error.config);
      } catch (error) {
        // Auto-logout function
        localStorage.removeItem("userTokens");
        localStorage.removeItem("userId");
      }
    } else {
      return Promise.reject(error);
    }
  }
);

// Define the auto-logout function
function autoLogout() {
  console.log("autoLogout");
  localStorage.removeItem("tokens");
  // Redirect user to the login page
  window.location.href = "/";
}
export default jwtInterceptor;
