import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { func } from "prop-types";
import { useDebounce } from "utils/hooks";
import API from "utils/brandAPI";
import { last } from "lodash";
import { Image, TextOverlay } from "containers/ProfileSettings";
import { IconUpload } from "images/icons/svg-components";
import { ImageDefaultBrand } from "images";
import { uploadFile } from "utils/s3";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { isEmpty } from 'utils/object';

function VerifiedSection({ onUpdateName, setSignUpForm, signUpForm }) {
  const [artistId, setArtistId] = useState("");
  const [innerError, setInnerError] = useState("");
  const debouncedArtistId = useDebounce(artistId, 500);
  const [error, setError] = useState([]);
  const [artistInfo, setArtistInfo] = useState({
    status: "",
    name: "",
    avatar: ImageDefaultBrand,
  });

  const uploadAvatarRef = useRef();

  const [artistsuggest, serArtistSuggest] = useState([]);
  const addError = (keyToAdd, valueToAdd, currentErrors) => {
    if (!currentErrors.some((err) => err.key === keyToAdd)) {
      return [...currentErrors, { key: keyToAdd, value: valueToAdd }];
    }
    return currentErrors;
  };

  const removeError = (keyToRemove, currentErrors) => {
    return currentErrors.filter((err) => err.key !== keyToRemove);
  };

  const validateDetails = () => {
    let newErrors = [...error]; // create a copy of the error state
    if (!signUpForm.userFullName) {
      newErrors = addError("name", "Name is required", newErrors);
    } else {
      newErrors = removeError("name", newErrors);
    }

    if (!signUpForm.name) {
      newErrors = addError("company", "Company name is required", newErrors);
    } else {
      newErrors = removeError("company", newErrors);
    }

    if (!artistInfo.avatar) {
      newErrors = addError(
        "avatar",
        "Upload avtar or fill artist Id",
        newErrors
      );
    } else {
      newErrors = removeError("avatar", newErrors);
    }

    // If newErrors is empty then validation passed otherwise failed
    setError(newErrors);
    return newErrors.length === 0;
  };

  const handleAvatar = (event) => {
    setArtistInfo((prev) => ({ ...prev, avatar: event.target.files[0] }));
  };

  const handleSelectionChange = async (event, value) => {
    try {
      const res = await API.get(`spotify/artist/${value.id}`);
      const avatar =
        res.data?.images && res.data?.images.length > 0
          ? last(res.data?.images)?.url
          : "";
      setArtistInfo({
        status: "success",
        name: res.data?.name,
        avatar,
      });
      setSignUpForm((state) => ({
        ...state,
        artistId: value.id,
        artistAvatar: avatar,
        artistName: res.data?.name,
      }));
    } catch (error) {
      setInnerError(error?.response?.data?.message);
      setArtistInfo({
        status: "error",
        name: "",
        avatar: "",
      });
      setSignUpForm((state) => ({
        ...state,
        artistId: null,
        artistAvatar: "",
        artistName: "",
      }));
    }
  };
  const getArtistInfo = async () => {
    if (debouncedArtistId) {
      try {
        const res = await API.get(`spotify/artist-search/${debouncedArtistId}`);
        if (!isEmpty(res.data) && res?.data?.artists?.items?.length > 0) {
          const artists = res?.data?.artists?.items?.map((artist) => {
            let avatar = last(artist?.images)?.url
            return { label: artist?.name, id: artist?.id, avatar: avatar, popularity: artist?.popularity };
          });
          serArtistSuggest(artists);
        } else {
          handleSelectionChange(undefined, { id: debouncedArtistId });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    getArtistInfo();
  }, [debouncedArtistId]);

  useEffect(() => {
    const callback = () => {
      uploadAvatarRef.current.value = null;
    };
    if (uploadAvatarRef?.current) {
      uploadAvatarRef.current?.addEventListener("click", callback);
    }
    return () => {
      uploadAvatarRef?.current?.removeEventListener("click", callback);
    };
  }, []);

  const handleClickStart = async () => {
    if (validateDetails()) {
      if (artistInfo.avatar instanceof File) {
        try {
          const avatarUploadedUrl = await uploadFile(artistInfo.avatar);
          setSignUpForm((state) => ({
            ...state,
            artistAvatar: avatarUploadedUrl,
          }));
          onUpdateName(avatarUploadedUrl);
        } catch (error) {
          console.error("Error while uploading the artist avatar");
        }
      } else {
        onUpdateName(artistInfo.avatar);
      }
    } else {
      return;
    }
  };

  const avatarUrl =
    artistInfo.avatar instanceof File
      ? URL.createObjectURL(artistInfo.avatar)
      : artistInfo.avatar;
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Your account is verified!</Title>
        <Subtitle>
          Start creating memberships and rewards by telling us a bit more about
          you!
        </Subtitle>
      </TitleWrapper>
      <FieldWrapper>
        <LeftWrapper>
          <LabelWrapper>
            <Label>Full Name</Label>
          </LabelWrapper>
          <FormField
            sx={formFieldOverrides}
            placeholder="eg. John Doe"
            inputProps={{
              "aria-label": "fullName",
            }}
            onChange={(e) => {
              setSignUpForm((state) => ({
                ...state,
                userFullName: e.target.value,
              }));
            }}
            value={signUpForm.userFullName}
          />
          <LabelWrapper>
            <Label>Company Name</Label>
          </LabelWrapper>
          <FormField
            sx={formFieldOverrides}
            placeholder="Eg. Campaign"
            inputProps={{
              "aria-label": "companyName",
            }}
            onChange={(e) =>
              setSignUpForm((state) => ({ ...state, name: e.target.value }))
            }
            value={signUpForm.name}
          />
          <LabelWrapper>
            <Label>Spotify Artist ID (Optional)</Label>
          </LabelWrapper>
          <Autocomplete
            freeSolo
            id="combo-box-demo"
            options={artistsuggest}
            sx={{
              ...autocomplete,
              '& .MuiAutocomplete-popupIndicator svg': {
                display: "none"
              },
              input: {
                color: "#F4F3EE",
                '& .MuiAutocomplete-popupIndicator svg': {
                  display: "none"
                },
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={option.label} src={option.avatar} sx={{ width: 40, height: 40, marginRight: 2 }} />
                <Box sx={{ flexGrow: 1 }}>{option.label}</Box>
              </Box>
            )}
            onChange={handleSelectionChange}
            onInputChange={(e) => setArtistId(e.target.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  color: "#F4F3EE", '& .MuiAutocomplete-popupIndicator svg': {
                    display: "none"
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#45475c',
                  },
                }}
                placeholder="Artist Name"
              />
            )}
          />

          {/* {artistInfo.status === "success" ? (
			<div>Artist Name: {artistInfo.name}</div>
			) : innerError ? (
			<ArtistError>{innerError}</ArtistError>
			) : null} */}
          {error.length > 0 && (
            <ErrorSection>
              {error.length > 0 &&
                error.map((e, index) => (
                  <ErrorRow
                    key={`${index}key`}
                  >{`${e.key}: ${e.value}`}</ErrorRow>
                ))}
            </ErrorSection>
          )}
          <ButtonsField>
            <EnterButton variant="contained" onClick={() => handleClickStart()}>
              Start
            </EnterButton>
          </ButtonsField>
        </LeftWrapper>
        <RightWrapper>
          <LabelWrapper>
            <Label>Profile Picture</Label>
          </LabelWrapper>
          <Image>
            {<img src={avatarUrl ? avatarUrl : ImageDefaultBrand} />}
            <TextOverlay
              onClick={(event) => {
                event.stopPropagation();
                uploadAvatarRef.current.click();
              }}
            >
              <input
                ref={uploadAvatarRef}
                id="uploadArtistAvatar"
                hidden
                accept="image/*"
                type="file"
                onChange={handleAvatar}
              />
              <IconUpload />
              Upload Image
            </TextOverlay>
          </Image>
        </RightWrapper>
      </FieldWrapper>
    </Wrapper>
  );
}

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const ErrorSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-top: 16px;
`;
const ErrorRow = styled.div`
  font-size: 12px;
  color: red;
`;

const ArtistError = styled.div`
  font-family: "Inter";
  color: ${(props) => props.theme.palette.fuschia100};
`;
const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Error = styled.div`
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
`;

const FormField = styledMui(InputBase)({
  width: "476px",
});

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const autocomplete = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  width: "478px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};
const ButtonsField = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const ResendButton = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightBlue};
  cursor: pointer;
  text-decoration: underline;
`;

const EnterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

VerifiedSection.propTypes = {
  onUpdateName: func,
};

export default memo(VerifiedSection);
