import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import {
  ButtonBase,
  Box,
  LinearProgress,
  linearProgressClasses,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { formattedDate, isDateInRange } from "utils/date";
import { useTranslation } from "react-i18next";
import API from "utils/userAPI";
import ImageLoader from "utils/imageLoader";
import QuestAccordion from "./QuestAccordion";
import { IconSpotify } from "images/icons/svg-components";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import LockIcon from "@mui/icons-material/Lock";
import { SignInButton } from "containers/UserPortal";
import { useNavigate } from "react-router";
import { fetchUserQuestHoldings } from "containers/UserPortal/actions";

const ViewQuest = ({
  memberships,
  quest,
  rewards,
  membership,
  brandId,
  user,
}) => {
  const [progress, setProgress] = useState(0);
  const [isClaiming, setIsClaiming] = useState(false);
  const { membershipHoldings, questHoldings } = useSelector(
    (state) => state.userPortal
  );
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const claimBadge = () => {
    if (isClaiming) {
      return;
    }
    setIsClaiming(true);
    API.post("users/redeemquests", {
      questId: quest._id,
      membershipId: membership._id,
      language: i18n?.language || "en",
    })
      .then((result) => {
        dispatch(fetchUserQuestHoldings());
        setIsClaiming(false);
      })
      .catch((err) => {
        console.log(err);
        setIsClaiming(false);
      });
  };

  const renderClaimBadge = () => {
    const userId = localStorage.getItem("userId");
    const isClaimed =
      questHoldings.filter((q) => q.typeId === quest._id).length > 0;
    const inRange = isDateInRange(quest.startDate, quest.endDate);
    if (JSON.stringify(user) === "{}" || !userId || userId === "") {
      // Not logged in
      return (
        <SignInButton
          onClick={() => {
            navigate("/login", {
              state: {
                brandId: brandId,
                membershipId: membership?._id,
                membershipType: membership?.membershipType,
              },
            });
          }}
        >
          {t("UserPortal.singinbutton")}
        </SignInButton>
      );
    } else if (isClaimed) {
      return (
        <ClaimBanner>
          {t("UserQuests.claimed")}
          <CheckIcon
            style={{
              color: "white",
              background: "#294BFC",
              borderRadius: "50%",
              fontSize: "20px",
              padding: "4px",
            }}
          />
        </ClaimBanner>
      );
    } else if (!inRange) {
      return (
        <ClaimBanner>
          <LockIcon
            style={{
              color: "#F4F3EE",
              fontSize: "20px",
            }}
          />
          {t("UserQuests.questinaccessible")}
        </ClaimBanner>
      );
    } else if (progress < 100) {
      return (
        <ClaimBanner>
          <LockIcon
            style={{
              color: "#F4F3EE",
              fontSize: "20px",
            }}
          />
          {t("UserQuests.badgelocked")}
        </ClaimBanner>
      );
    } else if (progress == 100) {
      return (
        <ClaimBanner onClick={claimBadge}>
          {isClaiming ? (
            <CircularProgress size={28} />
          ) : (
            t("UserQuests.claimbadge")
          )}
        </ClaimBanner>
      );
    }
  };

  return (
    <QuestMenuWrapper>
      <ImageLoader
        className="desktop-quest-item"
        src={quest.image}
        styles={{
          width: "180px",
          height: "180px",
          borderRadius: "12px",
          objectFit: "cover",
        }}
      />
      <QuestContent>
        <Header>
          <TextWrapper>
            <QuestBadge>{t("UserQuests.quest")}</QuestBadge>
            <QuestName>{quest.name}</QuestName>
          </TextWrapper>
          <div className="content-wrapper">
            <QuestFooter>
              <DateContent>
                <span className="date-prefix">{t("UserPortal.endson")}</span>
                <span>{formattedDate(quest.endDate)}</span>
              </DateContent>
              <MembershipList>
                {quest.membershipTier.map((tier) => (
                  <MembershipTier>
                    {memberships.filter((m) => m._id === tier)[0]?.name}
                  </MembershipTier>
                ))}
              </MembershipList>
            </QuestFooter>
            <QuestProgress>
              <Box sx={{ width: "100%" }}>
                <StyledLinearProgress variant="determinate" value={progress} />
              </Box>
              <ProgressText>
                {progress}% {t("UserQuests.completed")}
              </ProgressText>
            </QuestProgress>
          </div>
        </Header>
        <ImageLoader
          className="mobile-quest-item"
          src={quest.image}
          styles={{
            borderRadius: "8px",
            width: "100%",
            height: "200px",
            objectFit: "cover",
          }}
        />
        <QuestAccordion id="about-quest" title={t("UserQuests.aboutquest")}>
          <QuestDesc>{quest.description}</QuestDesc>
        </QuestAccordion>
        <QuestAccordion id="benefits-quest" title={t("UserQuests.benefits")}>
          <BenefitImages>
            {rewards.map((reward) => {
              return (
                <Tooltip title={reward.name} placement="bottom">
                  <div>
                    <ImageLoader
                      src={reward.image}
                      styles={{
                        width: "77px",
                        height: "77px",
                        borderRadius: "8px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
              );
            })}
          </BenefitImages>
        </QuestAccordion>
        <QuestAccordion id="tasks-quest" title={t("UserQuests.questTasks")}>
          <QuestTasks>
            <QuestActions
              quest={quest}
              membershipId={membership._id}
              brandId={brandId}
              user={user}
              setProgress={setProgress}
              memberships={memberships}
              membershipHoldings={membershipHoldings}
            />
          </QuestTasks>
        </QuestAccordion>
        <div className="redeem-button desktop-quest-item">
          {renderClaimBadge()}
        </div>
      </QuestContent>
      <div className="redeem-button mobile-quest-item">
        {renderClaimBadge()}
      </div>
    </QuestMenuWrapper>
  );
};

function QuestActions({
  quest,
  user,
  membershipId,
  brandId,
  setProgress,
  memberships,
  membershipHoldings,
}) {
  const { t } = useTranslation();
  const [actionResults, setActionResults] = useState([]);
  const [QRText, setQRText] = useState("");
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    async function fetchData() {
      if (userId && userId !== "") {
        const questAction = await API.get(
          `users/getMatrix/${membershipId}/${
            quest._id
          }?qrId=${localStorage.getItem("qr")}`
        );
        setActionResults(questAction.data?.results);
      }
      setQRText(quest?.qrText || "");
    }

    fetchData();
  }, [quest]);

  const SaveSpotifyButton = ({ spotifyLink }) => {
    const [loadingSpotify, setLoadingSpotify] = useState(false);
    const scopes =
      "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
    return user?.spotifyId === undefined ||
      user?.spotifyId === "" ||
      user?.reAuthSpotify ? (
      <div style={{ marginLeft: "auto" }}>
        <SpotifyButton
          onClick={() => {
            dataLayer.push({
              event: "click_spotify",
              eventCategory: "Button Click",
              eventAction: "Click",
            });

            window.location.href =
              "https://accounts.spotify.com/authorize?" +
              qs.stringify({
                response_type: "code",
                client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
                scope: scopes,
                redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
                state: JSON.stringify({
                  brandId: brandId,
                  spotifyLink: spotifyLink,
                  membershipId: membershipId,
                  id: userId,
                }),
              });
          }}
          variant="contained"
          disableRipple
        >
          <IconSpotify className="spotify-icon" />
          {t("UserPortal.authenticate")}
        </SpotifyButton>
      </div>
    ) : (
      <div style={{ marginLeft: "auto" }}>
        {
          <>
            {loadingSpotify ? (
              <CircularProgress style={{ color: "#1ed760" }} />
            ) : (
              <SpotifyButton
                onClick={async () => {
                  setLoadingSpotify(true);
                  try {
                    await API.post("/spotify/saveSpotifyLink", {
                      spotifyLink: spotifyLink,
                    });
                    const questAction = await API.get(
                      `users/getMatrix/${membershipId}/${quest._id}`
                    );
                    setActionResults(questAction.data?.results);
                    setLoadingSpotify(false);
                  } catch (e) {
                    setLoadingSpotify(false);
                    console.log(e);
                  }
                }}
                variant="contained"
                disableRipple
              >
                <IconSpotify className="spotify-icon" />
                {t("UserPortal.save")}
              </SpotifyButton>
            )}
          </>
        }
      </div>
    );
  };

  function renderActions(actions, actionResults) {
    let questProgress = 0;
    let actionsDiv = actions.map((action, actionIndex) => {
      const { name, trackName, count, minutes, spotifyData } = action;
      if (name === "SpotifyLinkSaveAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("UserPortal.save") +
              ` "` +
              spotifyData?.name +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/" +
                  spotifyData?.type +
                  "/" +
                  spotifyData?.id
                }
              />
            )}
          </div>
        );
      } else if (name === "SpotifyStreamAction") {
        const check = actionResults[actionIndex] > count;
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("NewCampaign.listen") +
              ` "` +
              trackName +
              `" ` +
              count +
              " " +
              t("NewCampaign.time") +
              t("UserPortal.onspotify")}
          </div>
        );
      } else if (name === "SpotifyMinutesAction") {
        const check = actionResults[actionIndex] > minutes;
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("NewCampaign.listen") +
              ` "` +
              trackName +
              `" ` +
              minutes +
              " " +
              t("NewCampaign.time") +
              t("UserPortal.onspotify")}
          </div>
        );
      } else if (name === "SpotifySongSaveAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("UserPortal.save") +
              ` "` +
              trackName +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/track/" + action?.trackId
                }
              />
            )}
          </div>
        );
      } else if (name === "ScanQRAction") {
        const check = actionResults[actionIndex];
        questProgress = check ? questProgress + 1 : questProgress;
        return (
          <div
            key={actionIndex}
            className="need-item"
            style={{
              color: check ? "#6096FF" : "#7F86AD",
            }}
          >
            {check ? (
              <CheckIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
            {t("UserPortal.scanqrcode")} {" - "} {QRText}
          </div>
        );
      }
    });

    const checkMembership =
      membershipHoldings?.length > 0 &&
      membershipHoldings
        ?.map((m) => m.typeId)
        ?.some((id) => quest?.membershipTier?.includes(id));
    questProgress = checkMembership ? questProgress + 1 : questProgress;
    const membershipNames = quest.membershipTier.map(
      (tier) => memberships.filter((m) => m._id === tier)[0]?.name
    );
    const membershipRequirement = (
      <div
        className="need-item"
        style={{
          color: checkMembership ? "#6096FF" : "#7F86AD",
        }}
      >
        {checkMembership ? (
          <CheckIcon fontSize="small" />
        ) : (
          <RemoveIcon fontSize="small" />
        )}
        {membershipNames.length === 1
          ? `${t("UserQuests.holding")} ${membershipNames[0]} ${t(
              "UserQuests.membership"
            )}`
          : `${t("UserQuests.holdingoneof")} ${membershipNames.join(", ")} ${t(
              "UserQuests.memberships"
            )}`}
      </div>
    );

    actionsDiv = [membershipRequirement, ...actionsDiv];
    setProgress(((questProgress / (actions.length + 1)) * 100).toFixed(0));
    return actionsDiv;
  }

  return <>{renderActions(quest.actions, actionResults)}</>;
}

const ClaimBanner = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  cursor: pointer;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkestGrey};
  ${(props) => props.theme.typography.bodyMBold};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
  @media (max-width: 768px) {
    background: ${(props) => props.theme.palette.darkerGrey};
  }
`;

const SpotifyButton = styled.div`
  ${(props) => props.theme.typography.header2}
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.palette.black};
  font-family: ${(props) => props.fontFamily};
  background-color: #1ed760;
  font-size: 15px;
  line-height: normal;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  .spotify-icon {
    width: 20px;
    height: 20px;
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodySMedium}
    gap: 10px;
  }
`;

const QuestTasks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  .need-item {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 20px;
    gap: 12px;
    background: ${(props) => props.theme.palette.darkerGrey};
    border-radius: 6px;

    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.lightBlue};
    &.disabled {
      color: ${(props) => props.theme.palette.lightGrey};
    }
    @media (max-width: 768px) {
      ${(props) => props.theme.typography.bodyS};
      line-height: normal;
    }
  }
`;

const BenefitImages = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
`;

const QuestMenuWrapper = styled.div`
  display: inline-flex;
  gap: 40px;
  margin: 0 auto;
  align-items: stretch;
  .mobile-quest-item {
    display: none;
  }
  .redeem-button {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin: 0;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    .redeem-button {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 12px 20px;
      background-color: ${(props) => props.theme.palette.darkestGrey};
    }
    .desktop-quest-item {
      display: none;
    }
    .mobile-quest-item {
      display: flex;
    }
  }
`;

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.darkerGrey,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#0bd680",
  },
}));

const QuestProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;
const ProgressText = styled.div`
  ${(props) => props.theme.typography.bodyS};
  color: var(--Green, #0bd680);
`;

const DateContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.typography.bodyS};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
  .date-prefix {
    color: ${(props) => props.theme.palette.lightGrey};
  }
  @media (max-width: 768px) {
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
const MembershipList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MembershipTier = styled.div`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  background: ${(props) => props.theme.palette.darkerGrey};
  color: ${(props) => props.theme.palette.lightGrey};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const QuestFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const QuestName = styled.div`
  align-self: stretch;
  ${(props) => props.theme.typography.header};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.header2};
  }
`;
const QuestBadge = styled.div`
  align-self: stretch;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodySMedium};
  }
`;
const QuestDesc = styled.div`
  align-self: stretch;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodyS};
    line-height: normal;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;
const QuestContent = styled.div`
  display: flex;
  width: 649px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  @media (max-width: 768px) {
    gap: 20px;
    padding: 20px;
    width: 100%;
  }
`;

export default ViewQuest;
