import React, { memo, useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Main from "./components/Main";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Verify from "./components/Verify";
import Verified from "./components/Verified";
import { validateEmail } from "utils/formatters";
import { ImageCampaignLogo, ImageGiftboxLogo } from "images";
import API from "utils/userAPI";
import { staticBrandId } from "containers/UserPortal";
import {
  fetchUserMembershipHoldings,
  fetchUserProfile,
  initialiseUserPortal,
} from "containers/UserPortal/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
export default function UserOnboarding() {
  // useInjectReducer({ key: "UserOnboarding", reducer });
  // useInjectSaga({ key: "UserOnboarding", saga });
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const [page, setPage] = useState(state?.page ? state?.page : "main");

  const spotifyId = state?.spotifyId || localStorage.getItem("spotifyId") || "";
  const onEmailChange = (evt) => setEmail(evt.target.value);
  const onPasswordChange = (evt) => setPassword(evt.target.value);
  const navigate = useNavigate();
  const onLogin = () => {
    handleClose();
    if (state?.brandId && state?.membershipId) {
      navigate(`/userhome/${state.brandId}/${state.membershipId}`, {
        state: {
          albumSaved: state?.albumSaved,
        },
      });
    } else {
      navigate("/userhome");
    }
  };
  useEffect(() => {
    console.log(state);
    if (state?.page == "verify") {
      setSignUpForm({
        ...signUpForm,
        ["email"]: state?.email,
      });
      setPage("verify");
    }
  }, [state]);
  // new
  // const onLogin = () => {
  //   navigate("/brands/" + state.brandId + "/" + state.memberShipId);
  // };

  const [error, setError] = useState("");
  const [signUpForm, setSignUpForm] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleClose = () => {
    setError("");
    setSignUpForm({
      name: "",
      email: "",
      password: "",
    });
    setPage("main");
  };

  const handleLogin = () => {
    try {
      setIsLoading(true);
      const { email } = signUpForm;
      if (!validateEmail(email) || email === "" || email === undefined) {
        setError("Please enter a valid email.");
        setIsLoading(false);
      } else {
        API.post(`auth/user/login`, {
          email,
          brandId: state?.brandId,
          spotifyId,
          joinConsent: state?.isChecked,
          language: i18n.language ? i18n.language : 'en'
        })
          .then((res) => {
            if (res.data.status === "success") {
              navigate(`/verify-user-email`, {
                state: {
                  formValues: signUpForm,
                  brandId: state?.brandId,
                  stateValues: state ? state : "",
                },
              });
              // setPage("verify");
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            // setPage("signUp");
            setError("Unable to reach servers.");
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = (code) => {
    try {
      setIsLoading(true);
      API.post(`auth/google/login/user`, {
        code: code,
        brandId: state?.brandId,
        spotifyId,
        joinConsent: state?.isChecked,
      })
        .then((res) => {
          if (res.data.status === "success") {
            dataLayer.push({
              'event': 'login_with_google',
              'eventCategory': 'Button Click',
              'eventAction': 'Click',
            });
            localStorage.setItem("userTokens", JSON.stringify(res.data.data));
            localStorage.setItem("userId", res.data.data.user._id);
            dispatch(initialiseUserPortal(state?.brandId));
            dispatch(fetchUserMembershipHoldings(state?.brandId));
            dispatch(fetchUserProfile(state?.membershipId, state?.brandId));
            handleVerify(res.data.data.user.spotifyId);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError("Unable to reach servers.");
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setError(error);
      setIsLoading(false);
    }
  };

  const handleVerifyEmail = (email) => {
    if (!validateEmail(email) || email === "" || email === undefined) {
      setError("Please enter a valid email.");
    } else {
      API.get(`auth/user/${email}`)
        .then((res) => {
          const { isRegistered } = res.data.data;
          if (isRegistered) {
            setPage("login");
          } else {
            setPage("signUp");
          }
          setError("");
        })
        .catch((err) => {
          console.log(err);
          // setPage("signUp");
          setError("Unable to reach servers.");
        });
    }
  };
  const handleClickLogin = async () => {
    const { email, password } = signUpForm;
    try {
      if (page === "login") {
        if (!validateEmail(email) || email === "" || email === undefined) {
          setError("Please enter a valid email.");
        } else {
          const res = await API.post("/auth/user/login", {
            email,
            password,
          }).then((result) => {
            const { isVerified } = result.data.data;
            if (isVerified) {
              localStorage.setItem(
                "userTokens",
                JSON.stringify(result.data.data)
              );
              localStorage.setItem("userId", result.data.data.userId);
              onLogin();
            } else {
              setPage("verify");
              setError("");
            }
          });
        }
      } else if (page === "signUp") {
        // console.log(signUpForm);
        const { email, password } = signUpForm;
        if (!validateEmail(email) || email === "" || email === undefined) {
          setError("Please enter a valid email.");
        }
        await API.post("/auth/user/register", {
          email,
          password,
          brandId,
          spotifyId,
        }).then((r) => {
          setPage("verify");
          setError("");
        });
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (page === "main") {
        handleLogin();
      } else if (page === "signUp") {
        handleSignup();
      } else {
        // handleClickLogin();
      }
    }
  };
  const handleVerify = async (spotifyId) => {
    if (state?.membershipType === "NFT-based membership" || spotifyId != "") {
      onLogin();
    } else if (!state?.brandId && !state?.membershipId) {
      navigate("/userhome");
    }
    else {
      navigate(`/link-spotify`, {
        state: {
          formValues: signUpForm,
          brandId: state?.brandId,
          membershipId: state?.membershipId,
        },
      });
    }
  };

  const handleFormValueChanges = (event, name) => {
    setSignUpForm({
      ...signUpForm,
      [name]: event.target.value,
    });
  };

  const handleSignup = async () => {
    setPage("verify");
  };

  const handleClaimMembership = () => {
    onLogin(signUpForm.email);
  };

  return (
    <OnboardingLayout>
      <Image isCampaign={staticBrandId !== state?.brandId}>
        <img src={staticBrandId !== state?.brandId ? ImageCampaignLogo : ImageGiftboxLogo}></img>
      </Image>
      <Content onKeyDown={handleKeyPress} tabIndex="0" page={page}>
        {page === "main" && (
          <Main
            formValues={signUpForm}
            onFormValueChanges={handleFormValueChanges}
            error={error}
            handleLogin={handleLogin}
            handleGoogleLogin={handleGoogleLogin}
            loading={isLoading}
            brandId={state?.brandId}
            membershipId={state?.membershipId}
            membershipType={state?.membershipType}
          />
        )}
        {/* {page === "login" && (
                    <Login
                        formValues={signUpForm}
                        onFormValueChanges={handleFormValueChanges}
                        error={error}
                        handleClickLogin={handleClickLogin}
                    />
                )}
                {page === "signUp" && (
                    <SignUp
                        formValues={signUpForm}
                        onFormValueChanges={handleFormValueChanges}
                        onSignup={handleSignup}
                        onLogin={onLogin}
                        brandId={state.brandId}
                        spotifyId={spotifyId}
                    />
                )} */}
        {page === "verify" && (
          <Verify
            formValues={signUpForm}
            onVerify={handleVerify}
            brandId={state?.brandId}
            state={state}
          />
        )}
        {page === "verified" && (
          <Verified
            formValues={signUpForm}
            brandId={state?.brandId}
            membershipId={state?.membershipId}
          />
        )}
      </Content>
      {
        staticBrandId === state?.brandId &&
        <Footer>
          Powered by <img src={ImageCampaignLogo} width="150px" height="24px" />
        </Footer>
      }
    </OnboardingLayout >
  );
}

const Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props) => (props.page !== "verified" ? "60px" : "0px")};
  width: 627px;
  border-radius: 16px;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.darkestGrey};
  @media (max-width: 768px) {
    width: 100%;
    background: none;
    padding: 0px 20px;
  }
`;
const OnboardingLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  gap: 32px;
  margin-top: 48px;
  @media (max-width: 768px) {
    gap: 65px;
    margin-top: 45px;
  }
`;
const Image = styled.div`
  img {
    height: ${(props) => (props.isCampaign ? "36px" : "51px")};
    max-width: 217px;
    width: 100%;
    @media (max-width: 768px) {
      height: ${(props) => (props.isCampaign ? "28px" : "40px")};
      max-width: 168px;
    }
  }
`;
// UserOnboarding.propTypes = {};

// const mapStateToProps = createStructuredSelector({
//     onboardingPage: makeSelectUserOnboarding(),
// });

// function mapDispatchToProps(dispatch) {
//     return {
//         dispatch,
//     };
// }

// const withConnect = connect(mapStateToProps, mapDispatchToProps);

// export default compose(withConnect, memo)(UserOnboarding);
