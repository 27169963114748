import React, { memo } from "react";
import styled from "styled-components";
import { Snackbar, Slide } from "@mui/material";
import { Close } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { IconMsgFill } from "images/icons/svg-components";
import { toggleVerifyMobileSnackbar } from "../../actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import makeSelectUserPortal from "containers/UserPortal/selector";

function VerifyNumberSnackBar({ userPortalState, dispatch }) {
    const { showVerifyMobileSnackbar } = userPortalState;

    //const phoneNumber = userPortalState?.profile?.user?.phoneNumber;
    console.log(userPortalState,"tada")
    const handleClose = () => {
        if (showVerifyMobileSnackbar) {
            dispatch(toggleVerifyMobileSnackbar());
        }
    };


    return (
        <>
            <Snackbar
                autoHideDuration={3000}
                open={showVerifyMobileSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
                //TransitionComponent={TransitionLeft}
                key={"verifyNumberSnackbar"}
            >
                <ContentWrapper>
                    <IconsWrapper>
                        <IconMsgFill style={{ width: "36px", height: "36px", color: "#6096FF" }} />
                        <div className="check-mark">
                            <CheckIcon style={{ fontSize: "18px" }} />
                        </div>
                    </IconsWrapper>
                    <Label>Your phone number is succesfully verified.</Label>
                    <Close
                        onClick={handleClose}
                        style={{
                            fontSize: "24px",
                            cursor: "pointer",
                            position: "absolute",
                            top: "13px",
                            right: "13px",
                        }}
                    />
                </ContentWrapper>
            </Snackbar>
        </>
    );
}

const IconsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  .check-mark{
    margin-left: -12px;
    border-radius: 50px;
    background: #0bd680;
    color: ${(props) => props.theme.palette.black};
    svg {
      font-size: xxx-large;
    }
  }
`;

const Label = styled.div`
  ${(props) => props.theme.typography.bodyS};
  line-height: normal;
  width: 252px;
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Sublabel = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};

  span {
    color: ${(props) => props.theme.palette.lightBlue};
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
    display: flex;
    padding: 16px 20px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: ${(props) => props.theme.palette.darkerGrey};
`;

const mapStateToProps = createStructuredSelector({
    userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(VerifyNumberSnackBar);