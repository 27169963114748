/**
 *
 * LeftMenu
 *
 */

import React, { memo, useEffect } from "react";
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { FaDiscord } from "react-icons/fa";
import Button from "@mui/material/Button";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FaShopify } from "react-icons/fa";
import { func, string } from "prop-types";
import {
  IconAirdrop,
  IconCampaigns,
  IconMembershipCrown,
  IconDashboard,
  IconDesktop,
  IconDocument,
  IconGift,
  IconDiscord,
  IconTwitter,
  IconInsta,
  IconShopify,
  IconQuest
} from "images/icons/svg-components";
import { useTranslation } from "react-i18next";

export const sideBarTabs = [
  // { name: t("LeftMenu.analytics"), icon: IconDashboard, route: "/dashboard" },
  {
    name: "LeftMenu.memberships",
    icon: IconMembershipCrown,
    route: "/memberships",
  },
  { name: "LeftMenu.quests", icon: IconQuest, route: "/quests" },
  { name: "LeftMenu.benefits", icon: IconGift, route: "/rewards" },
  {
    name: "LeftMenu.landingPage",
    icon: IconDesktop,
    route: "/landingpage-preview",
  },
  { name: "LeftMenu.members", icon: IconDocument, route: "/members" },
  // { name: t("LeftMenu.campaigns"), icon: IconCampaigns, route: "/campaigns" },
  // { name: t("LeftMenu.airdrops"), icon: IconAirdrop, route: "/airdrop" },
  // { name: t("LeftMenu.integrations"), icon: IconDocument, route: "/integrations" },
  // { name: "twitter", icon: IconTwitter, route: "/twitters" },
  // {
  //   name: "instagram",
  //   icon: IconInsta,
  //   route: "/instagramDashboard",
  // },
  // { name: "shopify", icon: IconShopify, route: "/shopifyStores" },
  // { name: "campaigns", icon: IconCampaigns, route: "/campaigns" },
  // { name: "airdrop", icon: IconAirdrop, route: "/airdrop" },
  // { name: "integrations", icon: IconDocument, route: "/integrations" },

  // { name: "reports", icon: IconDocument, route: "/reports" },
];

function LeftMenu({
  activeTab,
  setActiveTab,
  setManageRewardPage,
  setSelectedMembership,
}) {
  const { t } = useTranslation();

  const firstCapitalized = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const renderMenuItems = () =>
  sideBarTabs
      .map((item) => ({ ...item, name: t(item.name) }))
      .map((tab) => {
        const Icon = tab.icon;
        if (activeTab === tab.name) {
          return (
            <ActiveMenuItem
              variant="contained"
              disableElevation
              onClick={() => {
                if (activeTab === tab.name) return;
                if (tab.name === "benefits") setManageRewardPage("rewardCards");
                setActiveTab(tab.name);
              }}
              startIcon={
                <Icon color="#FFFFFF" style={{ height: "25", width: "24" }} />
              }
              key={tab.name}
            >
              {tab.name === "landingPage"
                ? "Fan Portal Builder"
                : firstCapitalized(tab.name)}
            </ActiveMenuItem>
          );
        }

        return (
          <Link to={tab.route} key={tab.name}>
            <MenuItem
              variant="contained"
              onClick={() => {
                // setSelectedMembership({});
                // setActiveTab(tab.name);
                // setManageRewardPage("rewardCards");
              }}
              disableElevation
              startIcon={<Icon style={{ height: "25", width: "24" }} />}
            >
              {tab.name === "landingPage"
                ? "Fan Portal Builder"
                : firstCapitalized(tab.name)}
            </MenuItem>
          </Link>
        );
      });
  return (
    <Wrapper>
      <Menu>
        <MenuItemWrapper>{renderMenuItems()}</MenuItemWrapper>
      </Menu>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  padding: 20px 27.5px;
  border-right: 1px solid #352b42;
  @media (max-width: 500px) {
    display: none;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const MenuItem = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  justifyContent: "flex-start",
  width: "100%",
  color: theme.palette.lightBlue,
  textTransform: "none",
  padding: "16px 28px",
  background: "transparent",
  borderRadius: 6,
  gap: "20px",
  "&:hover": {
    backgroundColor: "#222239",
    filter: "none",
    color: theme.palette.white,
  },
  "& .MuiButton-startIcon": {
    margin: 0,
  },
}));

const ActiveMenuItem = styledMui(MenuItem)(({ theme }) => ({
  backgroundColor: "#222239",
  color: theme.palette.white,
}));

LeftMenu.propTypes = {
  activeTab: string,
  setActiveTab: func,
  setManageRewardPage: func,
  setSelectedMembership: func,
};

export default memo(LeftMenu);
