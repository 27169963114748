import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconCopy,
  IconMail,
  IconSpotify,
  IconWallet,
} from "images/icons/svg-components";
import { mainTheme } from "theme/mainTheme";
import { ImageUserPortalDefault, ImageUserProfileMock } from "images";
import { Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { formattedDate } from "utils/date";
import { isEmpty } from "utils/object";
import queryString from "query-string";
import qs from "qs";
import { MembershipData } from "containers/ManageMembership/mock";
import DiscordVerified from "components/UserModal/components/DiscordVerify";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";
import TwitterVerified from "components/UserModal/components/TwitterVerify";
import InstagramVerified from "components/UserModal/components/InstagramVerify";
import FacebookVerified from "components/UserModal/components/facebookVerify";
import { useTranslation } from "react-i18next";
import { staticBrandId } from "containers/UserPortal";
import { useParams } from "react-router";
import { formatNumber } from "utils/dollar";
import { ROYALTY_CONTRACT } from "constants/contants";
import RoyaltyAbi from "../../constants/RoyaltyAbi.json";
import { ConnectWallet, ThirdwebSDK, useSigner } from "@thirdweb-dev/react";
import { Web3Button, useConnectionStatus } from "@thirdweb-dev/react";
import { ethers } from "ethers";

// import TwitterShareButton from "components/TwitterShare";

const AccountDetail = ({
  onClose,
  data,
  totalRewardForUser,
  userId,
  totalReward,
  membershipData,
  setExpandImage,
  staticMembershipId
}) => {
  const connectionStatus = useConnectionStatus();
  const signer = useSigner();
  const [isCopied, setIsCopied] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { brandId, membershipId } = useParams();
  const handleCopyClick = () => {
    navigator.clipboard.writeText(data?.user?.walletAddress ?? "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };
  const { t } = useTranslation();

  const renderRecentActivities = () => {
    const recentActivities = data?.recentActivity;
    console.log(membershipData, "account details");
    return recentActivities?.length === 0 ? (
      <NoActivity>{t("AccountDetail.noactivity")}</NoActivity>
    ) : (
      recentActivities?.map(
        (item, idx) =>
          (item?.rewardName[0]?.membershipId === membershipData?._id ||
            item?.membershipName[0]?._id === membershipData?._id) && (
            <div className="recentActivity" key={`${idx}key`}>
              {item.rewardName.length > 0 &&
              item.rewardName[0]?.membershipId === membershipData?._id ? (
                <div className="title">
                  {t("AccountDetail.redeemed")} {item.rewardName[0].name}
                </div>
              ) : item.membershipName.length > 0 &&
                item.membershipName[0]?._id === membershipData?._id ? (
                <div className="title">
                  {t("AccountDetail.joined")} {item.membershipName[0].name}
                </div>
              ) : (
                ""
              )}
              <div className="date">{formattedDate(item.date, 2)}</div>
            </div>
          )
      )
    );
  };

  const [isRoyalityClaimableMembership, setisRoyalityClaimableMembership] =
    useState(false);

  const [claimableRoyaltyAmount, setclaimableRoyaltyAmount] = useState(0);
  const claimRoyaltyFunction = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-mainnet.g.alchemy.com/v2/LOkJ--QLF-A3DrGFMMSJl1yDnLt7ilxc"
      );

      const royaltyContract = new ethers.Contract(
        ROYALTY_CONTRACT,
        RoyaltyAbi.abi,
        provider
      );

      let claimableAmount = 0;
      if (data?.user?.walletAddress && data?.user?.walletAddress !== "") {
        claimableAmount = await royaltyContract.getClaimableAmount(
          data?.user?.walletAddress,
          membershipData?.tokenId
        );

        const claimableAmountWei = ethers.utils.formatUnits(claimableAmount.toString(), 6);

        setclaimableRoyaltyAmount(claimableAmountWei.toString());
      }

      console.log("ClaimableAmount:", claimableAmount.toString());
    } catch (e) {
      console.log(e, "yello");
    }
  };

  useEffect(() => {
    if (membershipData && staticMembershipId) {
      if (staticMembershipId?.includes(membershipData?._id)) {
        claimRoyaltyFunction();
        setisRoyalityClaimableMembership(true);
      }
    }

  }, [membershipData, staticMembershipId]);

  const claimableAmount = async () => {
    try {
      if (connectionStatus === "connected" && signer) {
        const royaltyContract = new ethers.Contract(
          ROYALTY_CONTRACT,
          RoyaltyAbi.abi,
          signer
        );

        const tx = await royaltyContract.getcla(membershipData?.tokenId).call();

        const hash = tx.wait();
        console.log(hash, "hash final");
      } else {
        window.alert("wallet not connected");
      }
    } catch (e) {}
  };

  return (
    <Wrapper className={onClose ? "gap" : ""}>
      {/* {data?.membership && (
        <> */}
      {data?.user?.email !== undefined && data?.user?.email !== "" && (
        <Box>
          <HeaderWrapper>
            <Header>{t("AccountDetail.account")}</Header>
            {onClose && <CloseIcon className="icon" onClick={onClose} />}
          </HeaderWrapper>
          <div className="account">
            <div className="content">
              <IconMail
                style={{
                  height: "24px",
                  width: "24px",
                  color: mainTheme.palette.lightBlue,
                  flexShrink: 0,
                }}
              />
              <span className="bodyM" style={{ wordBreak: "break-all" }}>
                {data?.user?.email}
              </span>
            </div>
            {data?.user?.spotifyId !== undefined &&
              data?.user?.spotifyId !== "" && (
                <div className="content">
                  <IconSpotify
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "#1ED760",
                    }}
                  />
                  <span className="bodyM" style={{ wordBreak: "break-all" }}>
                    {data?.user?.spotifyId === undefined ||
                    data?.user?.spotifyId === ""
                      ? "Not Authenticated"
                      : data?.user?.spotifyId}
                  </span>
                </div>
              )}
            {data?.user?.phoneNumber !== undefined &&
              data?.user?.phoneNumber !== "" && (
                <div className="content">
                  <PhoneIphoneIcon
                    style={{
                      height: "24px",
                      width: "24px",
                      color: mainTheme.palette.lightBlue,
                      flexShrink: 0,
                    }}
                  />
                  <span className="bodyM" style={{ wordBreak: "break-all" }}>
                    {data?.user?.phoneNumber}
                  </span>
                </div>
              )}
            {/* <YoutubeVerified formValues={data?.user} brandId={brandId} />
          <TwitterVerified
            userData={data?.user}
            brandId={brandId}
            userId={userId}
          />
          <DiscordVerified formValues={data?.user} brandId={brandId} />

          <InstagramVerified formValues={data?.user} brandId={brandId} /> */}

            {/* <FacebookVerified formValues={data?.user} brandId={brandId} /> */}

            {/* <div className="content">
            <IconWallet
              style={{
                height: "24px",
                width: "24px",
                stroke: mainTheme.palette.lightBlue,
                flexShrink: 0,
              }}
            />

            <span className="walletAddress">{data?.user?.walletAddress}</span>
            <IconCopy
              style={{
                cursor: "pointer",
                flexShrink: 0,
                color: isCopied
                  ? mainTheme.palette.white
                  : mainTheme.palette.lightBlue,
              }}
              onClick={handleCopyClick}
            />
          </div> */}
          </div>
        </Box>
      )}
      {!isEmpty(data?.membership) && (
        <Box>
          <HeaderWrapper className="membership-header">
            <Header>{t("AccountDetail.membership")}</Header>
            {/* <TwitterShareButton /> */}
          </HeaderWrapper>
          <div className="account">
            <div className="membership">
              <img
                onClick={() =>
                  setExpandImage
                    ? setExpandImage(data?.membership?.membershipImage)
                    : null
                }
                src={
                  data?.membership?.membershipImage ||
                  membershipData?.image ||
                  ImageUserPortalDefault
                }
              />
              <div className="text-wrapper">
                <div className="header2">
                  {data?.membership?.membershipName}
                </div>
                {membershipData?.name && membershipData?.name !== "" ? (
                  <div className="header">
                    {data?.membership?.membershipName ||
                      membershipData?.name ||
                      ""`'s Membership`}
                  </div>
                ) : null}
                <div className="bodyS">
                  {(() => {
                    let date = formattedDate(
                      data?.membership?.membershipJoined,
                      1
                    );
                    return date === "Invalid Date"
                      ? t("AccountDetail.Hasntjoined")
                      : date;
                  })()}
                </div>
              </div>
            </div>
          </div>
        </Box>
      )}

      <Box>
        <div
          className="stats"
          style={{
            justifyContent:
              staticBrandId !== brandId ? "space-around" : "center",
          }}
        >
          {staticBrandId !== brandId && (
            <div className="stat-wrapper">
              <div className="header">{totalRewardForUser}</div>
              <div className="bodyS">{t("AccountDetail.benefits")}</div>
            </div>
          )}
          {staticBrandId !== brandId && (
            <Divider
              sx={{
                height: "100%",
                borderLeft: "1px solid #352B42",
                borderColor: mainTheme.palette.darkGrey,
              }}
            />
          )}
          <div className="stat-wrapper">
            <div className="header">
              {data?.membership?.membershipId ? 1 : 0}
            </div>
            <div className="bodyS">{t("AccountDetail.membership")}</div>
          </div>
          {isRoyalityClaimableMembership && (
            <div className="stat-wrapper">
              <div className="header">
                {`$${claimableRoyaltyAmount ?  claimableRoyaltyAmount : 0}`}
              </div>
              <div
                className="bodyS"
                style={{ textAlign: "-webkit-center", lineHeight: "23px" }}
              >
                {t("AccountDetail.royaltyamountcollected")}
              </div>
            </div>
          )}
          {/** Uncomment when implement campaigns and change backend to reflect correct # of campaigns
          <Divider
            sx={{
              height: "100%",
              borderLeft: "1px solid #352B42",
            }}
            orientation="vertical"
          />
          <div className="stat-wrapper">
            <div className="header">
              {data.campaigns}
            </div>
            <div className="bodyS">Campaign</div>
          </div>
          */}
        </div>
      </Box>
      {data?.user?.walletAddress && data?.user?.walletAddress !== "" && (
        <Box>
          <HeaderWrapper>
            <Header>{t("AccountDetail.walletaddress")}</Header>
          </HeaderWrapper>
          <div className="account">
            <div
              className="content"
              style={{ background: "#21212E", padding: "10px" }}
            >
              {/* <IconWallet
              style={{
                height: "24px",
                width: "24px",
                stroke: mainTheme.palette.lightBlue,
                flexShrink: 0,
              }}
            /> */}
              <span className="bodyM" style={{ wordBreak: "break-all" }}>
                {data?.user?.walletAddress}
              </span>
              <IconCopy
                style={{
                  cursor: "pointer",
                  flexShrink: 0,
                  color: isCopied
                    ? mainTheme.palette.white
                    : mainTheme.palette.lightBlue,
                }}
                onClick={handleCopyClick}
              />
            </div>
          </div>
        </Box>
      )}
      <Box>
        <Header>{t("AccountDetail.recentactivity")}</Header>
        <Divider
          sx={{
            width: "100%",
            borderLeft: "1px solid #352B42",
            borderColor: mainTheme.palette.darkGrey,
          }}
        />
        {renderRecentActivities()}
      </Box>
    
      {/* {
        isRoyalityClaimableMembership &&

        <Web3Button
        contractAddress={
          ROYALTY_CONTRACT
        }
        action={claimRoyaltyFunction}
        style={{
          width: "100%",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "24px",
          color: "#F4F3EE",
          backgroundColor: "#294BFC",
          textTransform: "none",
          borderRadius: "6px",
          padding: "18px 0",
          marginTop: "20px",
          "&:hover": {
            backgroundColor: "#F4F3EE",
          },
        }}
      >
        Continue with Purchase
      </Web3Button>
      } */}

      {/* </> */}
      {/* )} */}
    </Wrapper>
  );
};
const NoActivity = styled.div`
  color: #eee;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};

  .icon {
    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.lightGrey};

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 40px;

  &.gap {
    gap: 48px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    :nth-of-type(1) {
      order: 2;
    }
    :nth-of-type(2) {
      order: 1;
    }
    :nth-of-type(3) {
      order: 3;
    }
    :nth-of-type(4) {
      order: 4;
    }
    :nth-of-type(5) {
      order: 5;
    }
    .membership-header {
      display: none;
    }
    .account {
      .membership {
        border: none;
        flex-direction: column;
        img {
          width: 209px;
          height: 209px;
          border-radius: 6px;
          object-fit: cover;
        }
        .text-wrapper {
          gap: 8px;
          .header {
            display: block;
            margin: 0;
            text-align: center;
          }
          .bodyS {
            text-align: center;
          }
          .header2 {
            display: none;
          }
        }
      }
    }
  }

  .recentActivity {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid #352b42;
    padding-bottom: 20px;
    .title {
      ${(props) => props.theme.typography.bodyM};
      color: ${(props) => props.theme.palette.white};
    }
    .date {
      ${(props) => props.theme.typography.bodyS};
      color: ${(props) => props.theme.palette.lightGrey};
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .walletAddress {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.white};
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stats {
    display: flex;

    .stat-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      .header {
        ${(props) => props.theme.typography.header}
        color: ${(props) => props.theme.palette.white};
        margin-bottom: 0px;
      }

      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.lightGrey};
      }
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .content {
      display: flex;
      align-items: center;
      padding: 5px 0;
      border-radius: 6px;
      gap: 10px;

      .bodyM {
        ${(props) => props.theme.typography.bodyM}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .membership {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 24px;

    border: 1px solid #6096ff;
    border-radius: 6px;

    img {
      height: 80px;
      width: 80px;
      border-radius: 8px;
      opacity: 80%;
      object-fit: cover;
      cursor: pointer;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .header {
        display: none;
      }
      .header,
      .header2 {
        ${(props) => props.theme.typography.header2}
        color: ${(props) => props.theme.palette.white};
      }
      .bodyS {
        ${(props) => props.theme.typography.bodyS}
        color: ${(props) => props.theme.palette.white};
      }
    }
  }
`;
export default AccountDetail;
