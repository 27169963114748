/**
 *
 * LoginSection
 *
 */

import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal } from "@mui/material";

import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import { object, func } from "prop-types";
import { clearErrors } from "containers/OnboardingPage/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageChanger from "languageProvider/languagechanger";

function LoginSection({
  formProps,
  onSignupClick,
  onLoginClick,
  onForgotPasswordClick,
  error,
}) {
  const { t } = useTranslation();
  const { password, onPasswordChange } = formProps;
  const dispatch = useDispatch();
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      onLoginClick();
    }
  };
  useEffect(() => {
    dispatch(clearErrors());
  }, []);
  return (
    <Wrapper onKeyDown={handleKeyPress}>
      <Title>{t("LoginSection.title")}</Title>
      <FieldWrapper>
        <LabelWrapper>
          <Label>{t("LoginSection.inputlabel")}</Label>
          <ForgotPasswordButton onClick={onForgotPasswordClick}>
            {t("LoginSection.forgotpasswordbutton")}
          </ForgotPasswordButton>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder={t("LoginSection.inputplaceholder")}
          inputProps={{
            "aria-label": "password",
          }}
          type="password"
          fullWidth
          autoFocus
          value={password}
          onChange={onPasswordChange}
        />
      </FieldWrapper>
      {error && <Error>{error}</Error>}
      <LoginButton onClick={onLoginClick} variant="contained">
        {t("LoginSection.loginbutton")}
      </LoginButton>
      <LanguageChanger style={{justifyContent:"flex-end"}}/>
      {/* <SignUpButton onClick={onSignupClick} variant="contained">
        Sign Up
      </SignUpButton> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Error = styled.div`
  color: red;
  font-size: 20px;
  text-align: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const FormField = styledMui(InputBase)({});

const ForgotPasswordButton = styled.div`
  display: flex;
  text-decoration: underline;
  cursor: pointer;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const BaseButton = styledMui(Button)(() => ({
  width: "100%",
  height: 58,
  fontSize: 24,
  fontWeight: 400,
  borderRadius: 10,
  textTransform: "none",
  fontFamily: "Poppins",
}));

const SignUpButton = styledMui(BaseButton)(() => ({
  color: "#ffffff",
  border: "1px solid #D9D9D9",
  backgroundColor: "rgba(217, 217, 217, 0.2)",
  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  marginTop: 40,
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

LoginSection.propTypes = {
  formProps: object,
  onSignupClick: func,
  onForgotPasswordClick: func,
  onLoginClick: func,
};

export default memo(LoginSection);
