/**
 *
 * ForgotPasswordSection
 *
 */

import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { bool, func } from "prop-types";
import BackButton from "../BackButton/Loadable";
import { clearErrors } from "containers/OnboardingPage/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
function ForgotPasswordSection({
  enterNewPassword = false,
  onBackClick,
  onNewPasswordClick,
  error,
  formProps,
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState(formProps.email);
  const dispatch = useDispatch();

  const onHandleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const onSendPassword = () => {
    onNewPasswordClick(email);
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, []);

  // render enter new password input fields
  if (enterNewPassword) {
    return (
      <Wrapper>
        {/* <BackButton onClick={onBackClick} /> */}
        <TitleWrapper>
          <Title>{t("ForgotPassword.title")}</Title>
          <Subtitle>
            {`${t("ForgotPassword.subtitle1")} ${email} ${t("ForgotPassword.subtitle2")}`}
          </Subtitle>
        </TitleWrapper>
        <FieldWrapper>
          <Subtitle>
            {t("ForgotPassword.buttonlabel")}
            <ResendButton onClick={onSendPassword}>{t("ForgotPassword.buttontext")}</ResendButton>
          </Subtitle>
        </FieldWrapper>
      </Wrapper>
    );
  }
  // else render forgot password
  return (
    <Wrapper>
      {/* <BackButton onClick={onBackClick} /> */}
      <TitleWrapper>
        <Title>{t("ForgotPassword.title")}</Title>
        <Subtitle></Subtitle>
      </TitleWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>{t("ForgotPassword.label")}</Label>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder="Eg. members@company.com"
          inputProps={{
            "aria-label": "email",
          }}
          onChange={onHandleEmailChange}
          value={email}
        />
      </FieldWrapper>
      {error && <Error>{error}</Error>}
      <ButtonsField>
        <EnterButton variant="contained" onClick={onSendPassword}>
          {t("ForgotPassword.buttontext2")}
        </EnterButton>
      </ButtonsField>
    </Wrapper>
  );
}

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const Error = styled.div`
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
`;

const FormField = styledMui(InputBase)({
  maxWidth: "476px",
});

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

const ButtonsField = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const ResendButton = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightBlue};
  cursor: pointer;
  text-decoration: underline;
`;

const EnterButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

ForgotPasswordSection.propTypes = {
  onBackClick: func,
  onNewPasswordClick: func,
  enterNewPassword: bool,
};

export default memo(ForgotPasswordSection);
