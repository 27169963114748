import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import SongCard from "./components/SongCard";
import {
  Button,
  FormControl,
  InputBase,
  NativeSelect,
  Table,
  TableBody,
  Tooltip,
} from "@mui/material";
import Percentage from "./components/Percentage";
import { mainTheme } from "theme/mainTheme";
import {
  IconInstagram,
  IconSpotify,
  IconTwitter,
} from "images/icons/svg-components";
import ArtistChart from "./components/ArtistChart";
import {
  ArrowRight,
  CalendarMonthOutlined,
  Circle,
  Rotate90DegreesCcw,
} from "@mui/icons-material";
import { styled as styledMui } from "@mui/material/styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import API from "utils/brandAPI";
import { formatSreams } from "./utils";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";


const data = {
  name: "Campaign Name 1",
  totalListeners: 15.2,
  totalStreams: 87.8,
  weeklyMovements: 15.6,
  launchDate: "12 Aug 2023 - 19 Aug 2023",
};

const MockData = [
  // data,
  // data,
  // {
  //   name: "Campaign Name 2",
  //   totalListeners: 18.2,
  //   totalStreams: 67.8,
  //   weeklyMovements: -25.6,
  //   launchDate: "12 Aug 2023 - 19 Aug 2023",
  // },
  // data,
];

const ArtistDashboard = ({
  artistInfo,
  topStreams,
  artistStats,
  chartData = [],
  artistTotalStream,
}) => {
  const [selectedSong, setSelectedSong] = useState("");
const { t } = useTranslation();

  const navigate = useNavigate();
  const renderStreams = () => {
    return chartData?.topStreamData?.map((item, idx) => (
      <Stream key={idx}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "22px",
          }}
        >
          <span>{idx + 1}</span>
          <span>{item.albumName}</span>
        </div>
        <span style={{ color: "#6096FF" }}>{formatSreams(item.count)}</span>
      </Stream>
    ));
  };

  const RenderCards = () => {
    return artistStats
      .slice(0, 5)
      .map((item, idx) => <SongCard data={item} key={idx}></SongCard>);
  };

  const renderDropdownMenu = () => {
    return (
      <ChartMenu>
        {/* <div className="header">Streams Per Song</div> */}
        <div className="dropdown-menu">
          {/* <FormControl>
            <NativeSelect
              onChange={(event) => {
                handleSongSelect(JSON.parse(event.target.value));
              }}
              label="Filter"
              input={<FormField />}
              IconComponent={() => (
                <KeyboardArrowDownOutlinedIcon fontSize="small" />
              )}
            >
              {artistStats.map((option) => (
                <option key={option.id} value={JSON.stringify(option)}>
                  {option.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl> */}
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <span
              style={{
                ...mainTheme.typography.header1,
                color: mainTheme.palette.lightBlue,
              }}
            >
              {artistTotalStream?.total}
            </span>
            <span
              style={{
                ...mainTheme.typography.bodyS,
                color: mainTheme.palette.lightGrey,
              }}
            >
              {t("ArtistDashboard.streams")}
            </span>
          </div>
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Percentage
              value={parseFloat(artistTotalStream?.growth) * 100}
              style={mainTheme.typography.header1}
              iconSize={22}
            />
            <span
              style={{
                ...mainTheme.typography.bodyS,
                color: mainTheme.palette.lightGrey,
              }}
            >
               {t("ArtistDashboard.lastweek")}
            </span>
          </div>
        </div>
      </ChartMenu>
    );
  };

  const renderedSideImage = useMemo(() => renderDropdownMenu(), [selectedSong]);

  const handleSongSelect = (song) => {
    setSelectedSong(song);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const handleTableRowClick = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  return (
    <Wrapper>
      <SongsWrapper>
        <ContentTitle> {t("ArtistDashboard.songs")}</ContentTitle>
        <CardsWrapper>{RenderCards()}</CardsWrapper>
        <ChartWrapper>
          <ChartContainer>
            {renderedSideImage}
            <ArtistChart
              chartData={chartData?.campaignsData}
              selectedSong={selectedSong}
            ></ArtistChart>
            <div
              style={{ marginLeft: "40px", marginTop: "30px" }}
              className="legend"
            >
              <Circle style={{ color: "#294BFC" }} />
              {t("ArtistDashboard.launchedcampaigns")}
            </div>
          </ChartContainer>
          <TopStreams>
            <Title> {t("ArtistDashboard.topstreams")}</Title>
            <StreamWrapper>{renderStreams()}</StreamWrapper>
          </TopStreams>
        </ChartWrapper>
      </SongsWrapper>
      <CampaignsWrapper>
        <ContentTitleWrapper>
          <ContentTitle> {t("ArtistDashboard.campaigns")}</ContentTitle>
          <CreateCampaignButton onClick={() => navigate("/create-campaign")}>
          {t("ArtistDashboard.createcampaign")}
          </CreateCampaignButton>
        </ContentTitleWrapper>
        <Table>
          <thead>
            <TableListWrapper>
              <Tableheader style={{ width: "50px" }}>#</Tableheader>
              <Tableheader style={{ width: "175px", textAlign: "left" }}>
              {t("ArtistDashboard.campaignstitle")}
              </Tableheader>
              <Tableheader style={{ width: "120px" }}>
              {t("ArtistDashboard.listeners")}
              </Tableheader>
              <Tableheader style={{ width: "120px" }}>
              {t("ArtistDashboard.totalstreams")}
              </Tableheader>
              {/* <Tableheader style={{ width: "120px" }}>
                Weekly Movements
              </Tableheader> */}
              {/* <Tableheader style={{ width: "120px" }}>Requirements</Tableheader> */}
              <Tableheader style={{ width: "200px" }}> {t("ArtistDashboard.launchdate")}</Tableheader>
            </TableListWrapper>
          </thead>
          <TableBody>
            {MockData.map((item, index) => {
              return (
                <>
                  <TableListWrapper
                    key={`${index}key`}
                    onClick={() => handleTableRowClick(index)}
                  >
                    <TableList width={50}>{index + 1}</TableList>
                    <TableList width={175} style={{ textAlign: "left" }}>
                      {item.name}
                    </TableList>
                    <TableList width={120}>{item.totalListeners}k</TableList>
                    <TableList width={120}>{item.totalStreams}k</TableList>
                    {/* <TableList width={120}>
                      <Percentage
                        value={item.weeklyMovements}
                        style={{
                          ...mainTheme.typography.bodyMBold,
                          justifyContent: "center",
                        }}
                      ></Percentage>
                    </TableList> */}
                    <TableList width={120}>{item.launchDate}</TableList>
                    <td width={20}>
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{
                          color: `${mainTheme.palette.lightGrey}`,
                        }}
                        sx={{
                          transition: "transform 0.1s ease",
                          transform:
                            selectedRow === index ? "rotate(90deg)" : "",
                        }}
                      />
                    </td>
                  </TableListWrapper>
                  {selectedRow === index && (
                    <tr>
                      <td colSpan="6">
                        <TableRowDropdown>
                          <div className="contents">
                            <div className="left-content">
                              <div className="breakdowns">
                                <div className="title">Breakdowns</div>
                                <div className="list">
                                  <div className="subtitle">
                                    <span>Song Name</span>
                                    <span>Streams</span>
                                  </div>
                                  <div className="list-item">
                                    <span>
                                      Remember (feat. Stevie Appleton)
                                    </span>
                                    <span>65.2k</span>
                                  </div>
                                  <div className="list-item">
                                    <span>
                                      Remember (feat. Stevie Appleton)
                                    </span>
                                    <span>65.2k</span>
                                  </div>
                                  <div className="list-item">
                                    <span>
                                      Remember (feat. Stevie Appleton)
                                    </span>
                                    <span>65.2k</span>
                                  </div>
                                  <div className="list-item">
                                    <span>
                                      Remember (feat. Stevie Appleton)
                                    </span>
                                    <span>65.2k</span>
                                  </div>
                                  <div className="list-item">
                                    <span>
                                      Remember (feat. Stevie Appleton)
                                    </span>
                                    <span>65.2k</span>
                                  </div>
                                  <div className="list-item">
                                    <span>
                                      Remember (feat. Stevie Appleton)
                                    </span>
                                    <span>65.2k</span>
                                  </div>
                                  <div className="total">
                                    <span>Total</span>
                                    <span>130.2k</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="right-content">
                              <div className="requireds">
                                <div className="title">Required Actions</div>
                                <div className="list">
                                  <div className="list-item">
                                    <IconSpotify color="#1ED760" />
                                    <span>Listen to Remember</span>
                                  </div>
                                  <div className="list-item">
                                    <IconInstagram />
                                    <span>Add #SonnyFedora hashtag</span>
                                  </div>
                                  <div className="list-item">
                                    <IconTwitter />
                                    <span>
                                      Subscribe to Sonny Fedora Twitter
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="rewards">
                                <div className="title">Rewards</div>
                                <div className="list">
                                  <div className="list-item">
                                    <span>Raffle for VIP Ticket</span>
                                    <span>1700/2500</span>
                                  </div>
                                  <div className="list-item">
                                    <span>Raffle for VIP Ticket</span>
                                    <span>1700/2500</span>
                                  </div>
                                  <div className="list-item">
                                    <span>Raffle for VIP Ticket</span>
                                    <span>1700/2500</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableRowDropdown>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </CampaignsWrapper>
    </Wrapper>
  );
};

const TableRowDropdown = styled.div`
  display: flex;
  padding: 30px 70px 70px 70px;
  margin-top: 20px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};

  .contents {
    display: flex;
    gap: 60px;
    width: 100%;

    .left-content {
      display: flex;
      width: 100%;
    }

    .right-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 40px;
    }

    .title {
      ${(props) => props.theme.typography.header2};
      color: ${(props) => props.theme.palette.white};
    }

    .breakdowns {
      display: flex;
      flex-direction: column;
      gap: 20px;
      white-space: nowrap;
      width: 100%;

      .list {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .list-item {
          display: flex;
          justify-content: space-between;

          ${(props) => props.theme.typography.bodyMBold};
          color: ${(props) => props.theme.palette.lightGrey};
        }

        .subtitle {
          display: flex;
          justify-content: space-between;

          ${(props) => props.theme.typography.bodyMBold};
          color: ${(props) => props.theme.palette.white};
        }

        .total {
          display: flex;
          justify-content: space-between;

          ${(props) => props.theme.typography.bodyMBold};
          color: ${(props) => props.theme.palette.white};
        }
      }
    }

    .requireds {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      .list {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .list-item {
          display: flex;
          gap: 16px;
          align-items: center;

          ${(props) => props.theme.typography.bodyMBold};
          color: ${(props) => props.theme.palette.lightGrey};
        }
      }
    }

    .rewards {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      .list {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          ${(props) => props.theme.typography.bodyMBold};
          color: ${(props) => props.theme.palette.lightGrey};
        }
      }
    }
  }
`;

const CustomTooltip = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 40px;
  padding: 32px;
  background: ${(props) => props.theme.palette.black};

  .breakdowns {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      ${(props) => props.theme.typography.header2};
      color: ${(props) => props.theme.palette.lightBlue};
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 18px;

      .item {
        display: flex;
        justify-content: space-between;
        ${(props) => props.theme.typography.bodyMBold};
        color: ${(props) => props.theme.palette.lightGrey};
      }

      .total {
        display: flex;
        justify-content: space-between;
        ${(props) => props.theme.typography.bodyMBold};
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  .requirements {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .title {
      ${(props) => props.theme.typography.header2};
      color: ${(props) => props.theme.palette.lightBlue};
    }
  }
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.lighGrey,
  borderRadius: "6px",
  padding: "12px 12px 12px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "54px",
  fontSize: 18,
  width: "357px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "0px",
    },
  },

  "&:first-child": {
    marginRight: "16px",
  },
}));

const ChartMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 80px;
  margin-bottom: 27px;

  .header {
    ${(props) => props.theme.typography.header2}
    color: ${(props) => props.theme.palette.white};
  }

  .dropdown-menu {
    display: flex;
    align-items: center;
    gap: 28px;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 73%;
  padding: 40px 20px;
  background: #1b212f;
  height: 500px;

  .legend {
    display: flex;
    align-items: center;
    gap: 16px;
    ${(props) => props.theme.typography.bodyM}
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const Tableheader = styled.th`
  ${(props) => props.theme.typography.bodyMBold}
  color: ${(props) => props.theme.palette.lightBlue};
  padding-bottom: 20px;
  word-wrap: break-word;
`;

const TableList = styled.td`
  ${(props) => props.theme.typography.bodyMBold}
  color: ${(props) => props.theme.palette.lightGrey};
  text-align: center;
  padding-block: 20px;
  word-wrap: break-word;
`;

const TableListWrapper = styled.tr`
  border-bottom: 1px solid #352b42;
  border-collapse: collapse;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ContentTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ContentTitle = styled.div`
  ${(props) => props.theme.typography.header1};
  color: ${(props) => props.theme.palette.white};
`;

const CreateCampaignButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "fit-content",
  color: theme.palette.white,
  textTransform: "none",
  padding: "14px 20px",
  background: theme.palette.campaignBlue,
  borderRadius: 6,

  "&:hover": {
    backgroundColor: "#222239",
    filter: "none",
    color: theme.palette.white,
  },
}));

const CardsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const ChartWrapper = styled.div`
  display: flex;
  border-radius: 12px 0 0 12px;
`;

const SongsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CampaignsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StreamWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stream = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.typography.bodyMBold}
  color: ${(props) => props.theme.palette.lightGrey};
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const TopStreams = styled.div`
  width: 27%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
  border-radius: 0 12px 12px 0;
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.lightBlue};
  ${(props) => props.theme.typography.header2};
`;

export default ArtistDashboard;
