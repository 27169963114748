import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { InputBase, Button, Divider } from "@mui/material";
import API from "utils/userAPI";
import { useTranslation } from "react-i18next";
import LanguageChanger from "languageProvider/languagechanger";

const LockedSection = ({
    setPage
}) => {
    const { t } = useTranslation();
    const [passKey, setPassKey] = useState('')
    const checkPassKey = async() => {
        const res = await API.post('/auth/check-password', {
            "passkey" : passKey
        })
        if(res.data.status === "success") {
            setPage("main");
        }
        else{
            alert("Please enter the correct password!")
        }
    }

    const handleKeyEvent = (e) => {
        if (e.key === "Enter") {
            checkPassKey();
        }
    };


    return (
        <ContentWrapper>
            <Title>{t("LockedSection.title")}</Title>
            <FieldWrapper>
                <Field>
                    <Label>{t("LockedSection.inputlabel")}</Label>
                    <FormField
                        sx={formFieldOverrides}
                        placeholder={t("LockedSection.inputplaceholder")}
                        fullWidth
                        value={passKey}
                        onChange={(e) => setPassKey(e.target.value)}
                        autoFocus
                        type="password"
                        onKeyDown={handleKeyEvent}
                    />
                </Field>
                <LoginButton variant="contained" onClick={checkPassKey}>
                    {t("LockedSection.buttonlabel")}
                </LoginButton>
            </FieldWrapper>
            <LanguageChanger style={{justifyContent:"flex-end"}}/>
        </ContentWrapper>
    );
};

const LoginButton = styledMui(Button)(({ theme }) => ({
    ...theme.typography.bodyM,
    fontSize: "20px",
    color: theme.palette.white,
    backgroundColor: theme.palette.campaignBlue,
    textTransform: "none",
    borderRadius: "6px",
    padding: "16px 0",

    "&:hover": {
        backgroundColor: "#D9D9D988",
    },
}));


const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const FormField = styledMui(InputBase)({});

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  gap: 32px;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const formFieldOverrides = {
    border: "1px solid #352B42",
    borderRadius: "6px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#F4F3EE",
    padding: "13px 20px",
    
    input: {
        padding: 0,
        "&::placeholder": {
            color: "#7F86AD",
        },
    },
};

export default LockedSection;
