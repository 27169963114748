export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthNamesAbbr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function convertTZ(date, tzString) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}
export const formattedDate = (startTime, format) => {
  if (!startTime || startTime === undefined) return "Invalid Date";
  const date = new Date(startTime.replace(/-/g, "/").replace(/T.+/, "")); // to get correct date
  const dateString = date.toDateString().split(" ");

  if (format === 2) {
    // June 1, 2023
    let dateObj = new Date(dateString);
    if (Object.prototype.toString.call(dateObj) === "[object Date]" && isNaN(dateObj)) {
      dateObj = new Date(date);
    }
    const month = dateObj.toLocaleString("default", { month: "long" });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  if (format === 1) {
    // returns June 2023
    const dateObj = new Date(startTime);
    const month = dateObj.toLocaleString("default", { month: "long" });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  }

  if (format === 0) {
    //returns in 24 August 2023 format
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day < 10 ? "0" + day : day} ${
      monthNames[monthIndex]
    } ${year}`;

    return formattedDate;
  }
  if (format === "mm/dd/yyy") {
    const year = date.toLocaleString("default", { year: "2-digit" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });
    return `${month}/${day}/${year}`;
  }
  if (dateString.length === 4) {
    return `${dateString[1]} ${dateString[2].replace(
      /^0+/,
      ""
    )}, ${dateString[3].substring(0, 4)}`;
  }
  return "";
};

// for converting timestamp to hour string
export const getHours = (startTime, timezone) => {
  if (!startTime || startTime === undefined) return "Invalid Date";
  const date = new Date(startTime);
  const convertedDate = convertTZ(date, timezone);

  const hours = convertedDate.getHours();
  const minutes = convertedDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from military time to standard time
  const standardHours = hours % 12 || 12;

  // Add leading zeroes to minutes if necessary
  const paddedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Combine everything into the desired format
  const time = `${standardHours}:${paddedMinutes} ${ampm}`;

  return time;
};

export const formatTime = (time) => {
  const timeParts = time.split(":");
  const hours24 = parseInt(timeParts[0]);
  const hours12 = hours24 % 12 || 12;
  const minutes = timeParts[1];
  const ampm = hours24 >= 12 ? "PM" : "AM";
  const timeString12 = `${hours12}:${minutes} ${ampm}`;

  return timeString12;
};

export function isDateInRange(startDateStr, endDateStr) {
  const currentDate = new Date();
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  return startDate <= currentDate && currentDate <= endDate;
}

// function formatDate(date) {
//   //date typed input takes and returns in yyyy-mm-dd
//   //this function formats it to dd-mm-yyyy
//   const modifiedDate = new Date(date);
//   const year = modifiedDate.toLocaleString("default", { year: "2-digit" });
//   const month = modifiedDate.toLocaleString("default", { month: "2-digit" });
//   const day = modifiedDate.toLocaleString("default", { day: "2-digit" });
//   return `${month}/${day}/${year}`;
// }
