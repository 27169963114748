import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  Drawer,
  NativeSelect,
} from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { mainTheme } from "theme/mainTheme";
import { format, parse } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { connect, useDispatch, useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconFullScreen, IconUpload } from "images/icons/svg-components";
import { Table, TableHead, TableBody, Checkbox } from "@mui/material";
import makeSelectMembers from "containers/Members/selectors";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { fetchMembers } from "containers/Members/actions";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import AccountDetail from "components/AccountDetails";
import { useTranslation } from "react-i18next";

const MemberTable = ({
  formValues,
  members,
  isCheckBoxesRemoved = false,
  handleChange,
  isEdit
}) => {
  const [selectedRows, setSelectedRows] = useState(isEdit?formValues?.users:[]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState({});
  const { t } = useTranslation();

  function CustomCheckbox(props) {
    return (
      <StyledCheckbox
        disableRipple
        checkedIcon={
          <CheckedBox>
            <CheckIcon fontSize="small" sx={{ color: "white" }} />
          </CheckedBox>
        }
        indeterminateIcon={
          <IndeterminateBox>
            <RemoveIcon fontSize="small" />
          </IndeterminateBox>
        }
        icon={<CheckBoxIcon />}
        {...props}
      />
    );
  }

  const handleRowSelect = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, item]);
      handleChange([...selectedRows, item]);
    } else {
      const _selectedRows = selectedRows;
      const _newSelectedRows = _selectedRows.filter(
        (row) => row.user.email !== item.user.email
      );
      handleChange(_newSelectedRows);
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row.user.email !== item.user.email)
      );
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(members);
      handleChange(members);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      handleChange([]);
      setSelectAll(false);
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <StyledTableRow>
            {!isCheckBoxesRemoved && (
              <StyledTableHeadCell
                style={{ width: "20px", paddingRight: "28px" }}
              >
                <CustomCheckbox
                  checked={selectAll}
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < members.length
                  }
                  onChange={handleSelectAll}
                />
              </StyledTableHeadCell>
            )}
            <StyledTableHeadCell style={{ paddingLeft: 0 }}>
              {t("MemberTable.email")}
            </StyledTableHeadCell>
            <StyledTableHeadCell> {t("MemberTable.membership")}</StyledTableHeadCell>
            <StyledTableHeadCell> {t("MemberTable.rewards")}</StyledTableHeadCell>
            <StyledTableHeadCell> {t("MemberTable.campaigns")}</StyledTableHeadCell>
            <StyledTableHeadCell>{t("MemberTable.streams")}</StyledTableHeadCell>
            <StyledTableHeadCell></StyledTableHeadCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {members.map((row, index) => (
            <StyledTableRow key={index}>
              {!isCheckBoxesRemoved && (
                <StyledTableBodyCell style={{ paddingRight: 0 }}>
                  <CustomCheckbox
                    checked={
                      formValues?.users.filter(
                        (selectedRow) => selectedRow?.user?.email === row?.user?.email).length > 0
                    }
                    onChange={(event) => handleRowSelect(event, row)}
                  />
                </StyledTableBodyCell>
              )}
              <StyledTableBodyCell style={{ paddingLeft: 0 }}>
                {row.user.email}
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                {row.membershipName}
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                {row?.userbenefits}
                {" " + "Redeemed"}
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                {row?.campaigns} {t("MemberTable.completed")}
              </StyledTableBodyCell>
              <StyledTableBodyCell style={{ paddingRight: 0 }}>
                {formatSreams(row.streams)} {t("MemberTable.streams")}
              </StyledTableBodyCell>
              <StyledTableBodyCell style={{ paddingLeft: 0, paddingRight: 0 }}>
                <IconFullScreen
                  className="icon"
                  onClick={() => {
                    setSelectedModalData(row);
                    setshowModal(true);
                  }}
                />
              </StyledTableBodyCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Drawer
        anchor={"right"}
        open={showModal}
        sx={{
          "& .MuiDrawer-paper": {
            width: "29%",
            background: "#1A1A22"
          },
        }}
        onClose={() => {
          setshowModal((prev) => !prev);
        }}
      >
        <ContentWrapper>
          <AccountDetail
            data={selectedModalData}
            onClose={() => {
              setshowModal((prev) => !prev);
            }}
          />
        </ContentWrapper>
      </Drawer>
    </>
  );
};

const CsvUploadButton = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-radius: 6px;
  border: 1px solid #352b42;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  gap: 12px;
  height: 44px;
`;

const Header = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #352b42;
  border-collapse: collapse;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
  }
  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.palette.white} !important;
  }
`;

const CheckBoxIcon = styledMui("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  width: 24,
  height: 24,
  background: theme.palette.lightGrey,
}));

const CheckedBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const IndeterminateBox = styledMui(CheckBoxIcon)(({ theme }) => ({
  borderColor: theme.palette.darkerGrey,
  background: theme.palette.campaignBlue,
}));

const StyledTableHeadCell = styled.th`
  padding: 16px;
  text-align: start;

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightBlue};
  }
`;

const StyledTableBodyCell = styled.th`
  padding: 16px;
  text-align: start;

  .icon {
    color: ${(props) => props.theme.palette.lightGrey};
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.palette.white};
    }
  }

  && {
    ${(props) => props.theme.typography.bodyMBold};
    color: ${(props) => props.theme.palette.lightGrey};
  }
`;

const MainContentWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const ExportButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyS,
  display: "flex",
  width: "fit-content",
  borderRadius: 6,
  padding: "8px 20px",
  textTransform: "none",
  gap: "10px",
  color: theme.palette.white,
  background: theme.palette.campaignBlue,
}));

const RewardTypesGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  height: 44px;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "12px",
    },
  },
}));

const CustomFormControl = styledMui(FormControl)({
  "& .MuiInputBase-root": {
    marginTop: "0",
  },
});

const InputField = styledMui(Input)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: "402px",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "10px",
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "16px 20px",
  color: theme.palette.lightGrey,
  maxHeight: "44px",

  "& .MuiInput-input": {
    padding: 0,
    color: theme.palette.lightGrey,
    "&::placeholder": {
      color: theme.palette.lightGrey,
      opacity: 1,
    },
  },
}));

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;

const TabButton = styled.div`
  display: flex;
  padding: 30px 10px;

  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
  border-bottom: 5px solid;
  border-color: ${(props) => props.theme.palette.lightBlue};
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MenuHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};

  .title {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const formFieldOverrides = {
  ...mainTheme.typography.bodyM,
  border: "1px solid #352B42",
  borderRadius: "6px",
  color: "#F4F3EE",
  padding: "12px 12px 12px 20px",

  svg: {
    color: "#F4F3EE",
  },

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 0.7,
    },
  },

  "& .MuiNativeSelect-select": {
    padding: 0,
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "#7F86AD",
    },
  },

  "input::-webkit-datetime-edit-fields-wrapper": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#F4F3EE",
  },

  "input[type=date]::-webkit-calendar-picker-indicator": {
    filter:
      "invert(98%) sepia(54%) saturate(245%) hue-rotate(347deg) brightness(110%) contrast(91%)", //#F4F3EE
  },

  "input[type=time]::-webkit-calendar-picker-indicator": {
    filter:
      "invert(98%) sepia(54%) saturate(245%) hue-rotate(347deg) brightness(110%) contrast(91%)", //#F4F3EE
  },

  ".Mui-disabled": {
    "-webkit-text-fill-color": "grey",
    color: "grey",
    svg: {
      color: "grey",
    },
  },
};

export default MemberTable;
