/**
 *
 * uestAccordion
 *
 */

import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/Remove";

function QuestAccordion({ title, children, id, defaultExpanded = true }) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const executeScroll = () => {
    const ele = document.getElementById(id);
    if (ele) {
      ele.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Wrapper>
      <Header
        onClick={() => {
          setExpanded((state) => !state);
          if (!expanded) {
            executeScroll();
          }
        }}
      >
        <Title>{title}</Title>
        <div>
          {expanded ? (
            <ExpandLessIcon style={{ color: "#F4F3EE" }} />
          ) : (
            <ExpandMoreIcon style={{ color: "#F4F3EE" }} />
          )}
        </div>
      </Header>
      {expanded && <Content>{children}</Content>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.white};
  line-height: normal;
  flex: 1 0 0;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.bodySMedium};
  }
`;

const Header = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

const Content = styled.div`
  margin-top: 12px;
`;

QuestAccordion.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
};

export default memo(QuestAccordion);
