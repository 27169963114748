import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "../Chart";
import style from "../Dashboard/Style.module.css";
import { getAnalyticData } from "../service";
import { DateInToMonthNameAndDateFormat } from "components/dateFormatter";
import Percentage from "../ArtistDashboard/components/Percentage";
import { mainTheme } from "theme/mainTheme";
import { useTranslation } from "react-i18next";

const AnalyticDashboard = ({ artistId, data }) => {
  const [members, setMembers] = useState(0);
  const [membersGrowth, setMembersGrowth] = useState(0);
  const [rewards, setRewards] = useState(0);
  const [rewardsGrowth, setRewardsGrowth] = useState(0);
  const [topMemberships, setTopMemberships] = useState([]);
  const [membershipTimeseries, setMembershipTimeseries] = useState([]);
  const [topRewards, setTopRewards] = useState([]);
  const [rewardsTimeseries, setRewardsTimeseries] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const {
      members = 0,
      membersGrowth = 0,
      rewards = 0,
      rewardsGrowth = 0,
      topMemberships = [],
      membershipTimeseries = [],
      topRewards = [],
      rewardsTimeseries = [],
    } = data;

    setMembers(members);
    setMembersGrowth(membersGrowth);
    setRewards(rewards);
    setRewardsGrowth(rewardsGrowth);
    setTopMemberships(topMemberships);
    setMembershipTimeseries(membershipTimeseries);
    setTopRewards(topRewards);
    setRewardsTimeseries(rewardsTimeseries);
  }, [data]);

  const [activeRewardTab, setActiveRewardTab] = useState("rewards");
  const [activeMemberActivityTab, setActiveMemberActivityTab] =
    useState("songs");
  const [totalRewardGraph, setTotalRewardGraph] = useState([]);
  const [spotifyGraph, setSpotifyGraphData] = useState([]);
  const [spotifyTopData, setSpotifyTopData] = useState({
    songs: [],
    albums: [],
  });
  const [analyticsData, setAnalyticsData] = useState({
    numOfMember: 0,
    generatedSales: 0,
    redeemedRewards: 0,
  });

  const [memberActivityGraph, setMemberActivityGraph] = useState([]);

  useEffect(() => {
    // getAnalyticData(artistId).then((res) => {
    //   const {
    //     data,
    //     topRewards,
    //     salesGenerated,
    //     totalMembers,
    //     totalRedeemedRewards,
    //     mintDataMemberships,
    //     mintDataRewards,
    //     spotifyTimeSeries,
    //     topAlbums,
    //     topArtists,
    //   } = res.data.data.response;
    //   const membershipData = mintDataMemberships?.map((x) => ({
    //     count: x.count,
    //     createdAt: x._id,
    //   }));
    //   const rewardsData = mintDataRewards?.map((x) => ({
    //     count: x.count,
    //     createdAt: x._id,
    //   }));
    //   const albumTimeSeries = spotifyTimeSeries?.albumTimeSeries?.map((x) => ({
    //     count: x.count,
    //     createdAt: x._id,
    //   }));
    //   const tracksTimeSeries = spotifyTimeSeries?.tracksTimeSeries?.map(
    //     (x) => ({
    //       count: x.count,
    //       createdAt: x._id,
    //     })
    //   );
    //   const rewardGraphArr = {
    //     rewards: mapGraphData(rewardsData),
    //     membership: mapGraphData(membershipData),
    //   };
    //   const spotifyGraphArr = {
    //     songs: mapGraphData(tracksTimeSeries),
    //     albums: mapGraphData(albumTimeSeries),
    //   };
    //   setAnalyticsData({
    //     ...analyticsData,
    //     redeemedRewards: totalRedeemedRewards,
    //     numOfMember: totalMembers,
    //     generatedSales: salesGenerated?.amount,
    //   });
    //   setTopRewardsArr(topRewards);
    //   setTotalRewardGraph(rewardGraphArr);
    //   setSpotifyGraphData(spotifyGraphArr);
    //   setSpotifyTopData({ albums: topAlbums, songs: topArtists });
    // });
  }, [artistId]);

  const mapGraphData = (data) => {
    // if all the counts are zero show placeholder data. TODO: remove in future
    const isDummyData = data?.every((x) => !x.count);
    if (!data?.length) {
      return [];
    }
    return data.map((val) => {
      let month = DateInToMonthNameAndDateFormat(
        val.createdAt
      ).finalFormattedMonth;
      let day = DateInToMonthNameAndDateFormat(val.createdAt).formattedDay;

      return {
        date: month,
        value: isDummyData ? Math.floor(Math.random() * 90 + 10) : val.count,
        day: day,
      };
    });
  };

  return (
    <Wrapper>
      <Item style={{ height: "fit-content" }}>
        <Card style={{ padding: "24px" }}>
          <P size={"18px"} weight={500} color="#6096FF" lineHeight="21.78px">
            {t("AnalyticDashboard.members")}
          </P>
          <Flex
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <P size={"40px"} lineHeight="48.41px" weight={600}>
              {members}
            </P>
            <Percentage
              value={membersGrowth}
              style={{
                ...mainTheme.typography.header,
              }}
            ></Percentage>
          </Flex>
        </Card>
      </Item>
      {/* <Item>
        <Card style={{ paddingInline: "20px" }}>
          <P size={"15px"} weight={800} color="#5e92f7">
            Sales Generated
          </P>
          <Flex style={{ justifyContent: "space-between" }}>
            <P
              size={"35px"}
              margin={"0px"}
              weight={800}
              style={{ marginBottom: "20px" }}
            >
              {analyticsData.generatedSales}
            </P>
            <P color="#19c37c" size={"13px"} weight={700}>
              0%
            </P>
          </Flex>
        </Card>
      </Item> */}
      <Item style={{ height: "fit-content" }}>
        <Card style={{ padding: "24px" }}>
          <P size={"18px"} weight={500} color="#6096FF" lineHeight="21.78px">
          {t("AnalyticDashboard.benefitsredeemed")}
          </P>
          <Flex
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <P size={"40px"} lineHeight="48.41px" weight={600}>
              {rewards}
            </P>
            <Percentage
              value={rewardsGrowth}
              style={{
                ...mainTheme.typography.header,
              }}
            ></Percentage>
          </Flex>
        </Card>
      </Item>
      <Item className={style.graphChart}>
        <ChartContr>
          <Tabs>
            <Flex>
              <P size={"18px"} color="#6096FF" weight={500}>
                {activeRewardTab === "rewards"
                  ?t("AnalyticDashboard.benefitlabel") 
                  : t("AnalyticDashboard.membershiplabel")}
              </P>
            </Flex>
            <Tab
              onClick={() => setActiveRewardTab("membership")}
              isActive={activeRewardTab === "membership"}
            >
                {t("AnalyticDashboard.Membership")}
            </Tab>
            <Tab
              onClick={() => setActiveRewardTab("rewards")}
              isActive={activeRewardTab === "rewards"}
            >
               {t("AnalyticDashboard.Benefits")}
            </Tab>
          </Tabs>
          {activeRewardTab === "rewards" ? (
            <Chart graphChart={rewardsTimeseries} />
          ) : (
            <Chart graphChart={membershipTimeseries} />
          )}
        </ChartContr>
        <ListContr>
          <P
            style={{ marginBottom: "32px" }}
            margin={"0px"}
            size={"18px"}
            color="#6096FF"
            weight={500}
          >
            {activeRewardTab === "rewards"
              ? t("AnalyticDashboard.benefitlabel")
              : t("AnalyticDashboard.membershiplabel")}
          </P>
          <ListWrapper className={style.listWrapper}>
            {activeRewardTab === "rewards"
              ? topRewards.map((reward, index) => {
                  return (
                    <List
                      key={reward._id}
                      style={{
                        border: `${
                          index === topRewards.length - 1 ? "0px solid" : null
                        }`,
                        width: "100%",
                        paddingBottom: "20px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "20px" }}>
                        <P color="#7e85ad" size={"18px"} weight={500}>
                          {index + 1}
                        </P>
                        <P color="#7e85ad" size={"18px"} weight={500}>
                          {reward.name}
                        </P>
                      </div>
                      <P>{reward.total}</P>
                    </List>
                  );
                })
              : topMemberships.map((reward, index) => {
                  return (
                    <List
                      key={reward._id}
                      style={{
                        border: `${
                          index === topRewards.length - 1 ? "0px solid" : null
                        }`,
                        width: "100%",
                        paddingBottom: "20px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "20px" }}>
                        <P color="#7e85ad" size={"18px"} weight={500}>
                          {index + 1}
                        </P>
                        <P color="#7e85ad" size={"18px"} weight={500}>
                          {reward.name}
                        </P>
                      </div>
                      <P>{reward.total}</P>
                    </List>
                  );
                })}
          </ListWrapper>
        </ListContr>
      </Item>
      {/* <Item className={style.graphChart}>
        <Flex>
          <ChartContr>
            <Tabs>
              <Flex>
                <P size={"15px"} color="#5e92f7" weight={700}>
                  Total Members Activity
                </P>
              </Flex>
              <Tab
                onClick={() => setActiveMemberActivityTab("songs")}
                isActive={activeMemberActivityTab === "songs"}
              >
                Songs
              </Tab>
              <Tab
                onClick={() => setActiveMemberActivityTab("albums")}
                isActive={activeMemberActivityTab === "albums"}
              >
                Albums
              </Tab>
              <Tab
                onClick={() => setActiveMemberActivityTab("Playlists")}
                isActive={activeMemberActivityTab === "Playlists"}
              >
                Playlists
              </Tab>
              <Tab
                onClick={() => setActiveMemberActivityTab("Searches")}
                isActive={activeMemberActivityTab === "Searches"}
              >
                Searches
              </Tab>
            </Tabs>
            <Chart graphChart={spotifyGraph[activeMemberActivityTab]} />
          </ChartContr>
          <ListContr>
            <P
              style={{ marginBottom: "20px" }}
              size={"15px"}
              weight={600}
              margin={"0px"}
              color="#5e92f7"
            >
              {activeMemberActivityTab === "songs" ? "Top Songs" : "Top Albums"}
            </P>
            <ListWrapper className={style.listWrapper}>
              {list?.map((li, index) => {
                return (
                  <List
                    key={index}
                    style={{
                      border: `${
                        index === list.length - 1 ? "0px solid" : null
                      }`,
                      width: "100%",
                    }}
                  >
                    <P color="#7e85ad" size={"13px"}>{`${index + 1}. ${
                      li.name
                    }`}</P>
                    <P>{li.frequency}</P>
                  </List>
                );
              })}
            </ListWrapper>
          </ListContr>
        </Flex>
      </Item> */}
    </Wrapper>
  );
};
const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 114px 1fr;
  grid-gap: 16px;
`;
const Item = styled.div`
  width: 100%;
  display: grid;
  border-radius: 7px;
  background: #1a1a22;
  overflow: hidden;
  height: 100%;
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const P = styled.div`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  line-height: ${(props) => props.lineHeight};
`;

const ListContr = styled.div`
  width: 100%;
  padding: 40px;
`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;
const List = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1.5px solid #433f4e;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Tab = styled.div`
  ${(props) => props.theme.typography.bodyS}
  display: flex;
  align-items: center;
  margin-right: 24px;
  height: 40px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#F4F3EE" : "#6096FF")};
  border-bottom: ${(props) => (props.isActive ? "2px solid #F4F3EE" : "none")};
`;

const ChartContr = styled.div`
  width: 73%;
  padding: 40px;
  background: #1b212f;
`;

export default AnalyticDashboard;
