import React, { memo } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ClassNames } from "@emotion/react";
import { useTranslation } from "react-i18next";

const RadioButtonGroup = ({ items, label, onChange, checked, subLabel, disabled = false, selected=false ,isReverce =false}) => {
  const { t } = useTranslation();
  return (
    <FieldWrapper>
      <Label>{label}</Label>
        {subLabel && <Sublabel>{subLabel}</Sublabel>}
      <CustomFormControl>
        <CustomRadioGroup row defaultValue={checked || false}>
          <CustomFormControlLabel
            disabled={disabled}
            className={ClassNames.selectBox}
            value="false"
            control={<CustomRadio disableRipple />}
            label={isReverce ? t("RadioButtonGroup.no"):t("RadioButtonGroup.yes")}
            onChange={onChange}
            sx={{ ...formFieldOverrides, padding: "16px 20px" }}
          />
          <CustomFormControlLabel
            defaultChecked={selected}
            disabled={disabled}
            value="true"
            control={<CustomRadio disableRipple />}
            label={isReverce ? t("RadioButtonGroup.yes"):t("RadioButtonGroup.no")}
            onChange={onChange}
            sx={{ ...formFieldOverrides, padding: "16px 20px" }}
          />
        </CustomRadioGroup>
      </CustomFormControl>
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const CustomFormControl = styledMui(FormControl)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "100px"
}));

const CustomFormControlLabel = styledMui(FormControlLabel)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  color: "#7F86AD",

  ":hover": {
    background: theme.palette.darkGrey,
  },

  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "&.MuiFormControlLabel-root": {
    margin: 0,
    border: "1px solid #352B42",
  },
  "& .MuiFormControlLabel-label": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.lightGrey,
    margin: "0",
  },
}));

const CustomRadioGroup = styledMui(RadioGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
}));

const CustomRadio = styledMui(Radio)(({ theme }) => ({
  padding: "0px",
  borderColor: theme.palette.lightGrey,
}));

const formFieldOverrides = {
  svg: {
    color: "#7F86AD",
  },

  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  padding: "16px 20px",
  color: "#7F86AD",

  "& .Mui-checked": {
    // borderColor: "#6096FF",
    background: "#6096FF",
    "& + .MuiFormControlLabel-label": {
      color: "#6096FF",
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  font-weight: 400;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Sublabel = styled(Label)`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;
export default RadioButtonGroup;
