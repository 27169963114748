/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import AppHeader from "components/AppHeader";
import logo from "images/icons/girlscrewicon.svg";
import OnboardingPage from "containers/OnboardingPage";
import Dashboard from "containers/Dashboard";
import UserPortal from "containers/UserPortal";
import ResetPassword from "containers/ResetPassword";
import BrandLandingPage from "containers/BrandLandingPage";
import BrandLandingPageWithSlug from "containers/BrandLandingPageWithSlug";
import LandingPageEdit from "containers/LandingPageEdit";
import SignUpModalEdit from "containers/SignUpModalEdit";
import BrandSignUpModal from "containers/BrandSignUpModal";
import QrCodeModalEdit from "containers/QrCodeModalEdit";
import BrandQrCodeModal from "containers/BrandQrCodeModal";
import VerifyEmail from "containers/UserPortal/components/VerifyEmail";
import ProtectedRoutes from "components/ProtectedRoutes";
import PublicRoutes from "components/PublicRoutes";
import ThemeStyled from "theme/ThemeStyled";
import ThemeMui from "theme/ThemeMui";
import MembershipContractModal from "components/MembershipContractModal";
import RewardContractModal from "components/RewardContractModal";
import Memberships from "containers/Memberships";
import LandingPage from "containers/LandingPage";
import ReportsPage from "containers/Reports";
import RewardsPage from "containers/Rewards";
import ProfileSettings from "containers/ProfileSettings";
import { SocketProvider } from "utils/socketIO";
import Members from "containers/Members/Members";
import ShopifyStores from "containers/ShopifyStores/ShopifyStoreOrders";
import TwitterAnalytics from "containers/TwitterAnalytics";
import Discord from "containers/DiscordPage/index";
import CampaignsPage from "containers/CampaignsPage";
import NewCampaign from "containers/NewCampaign";
import Verify from "containers/UserOnboarding/components/Verify";
import Verified from "containers/UserOnboarding/components/Verified";
import { GoogleOAuthProvider } from "@react-oauth/google";

import ProfileUser from "containers/ProfileUser";

import AirdropPage from "containers/AirdropPage";
import IntegrationsPage from "containers/IntegrationsPage";
import NewAirdrop from "containers/NewAirdrop";
import SocialLinks from "containers/SocialLinks";
import UserOnboarding from "containers/UserOnboarding";
import ArtistPage from "containers/ArtistPage";
import SongListen from "containers/SongListen";
import Integrations from "containers/Integrations";
import InstagramDashboard from "containers/InstagramDashboard/InstagramDashboard";
import TermCondition from "containers/TermCondition";
import PrivacyPolicy from "containers/Privacypolicy";
import FacebookSDKScript from "components/UserModal/components/facebookVerify/FacebookSDKScript";
import Brands from "containers/brands/Brand";
import BrandMembers from "containers/brandMembers/BrandMembers";
import BrandMembersReward from "containers/brandMembersReward/brandMembersReward";
import UserHomePage from "containers/UserHomePage";
import UserBrandMembershipPage from "containers/UserBrandMembershipPage";
import { MusicKitProvider } from "utils/musicKit";
import { Toaster } from "react-hot-toast";
import VerifyPhone from "containers/UserPortal/components/VerifyPhone";

import { useTranslation } from "react-i18next";
import { QuestsPage } from "containers/Quests";

const AppWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  ${"" /* overflow: auto; */}
  min-height: 100%;
  flex-direction: column;
  font-family: "Inter";
  // >*{
  //   zoom:0.7;
  // }
`;

const brandInfo = {
  name: "Girls Crew",
  logo,
};

export default function Home() {

  return (
    <AppWrapper>
      <SocketProvider>
        <ThemeMui>
          <ThemeStyled>
            <FacebookSDKScript />
            <Routes>
              <Route element={<PublicRoutes />}>
                <Route
                  path="/"
                  element={
                    <>
                      <OnboardingPage />
                    </>
                  }
                />
              </Route>
              <Route path="/terms-of-use" element={<TermCondition />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/:artistSlug" element={<ArtistPage />} />

              <Route path="/membership/:brandSlug" element={<UserPortal />} />

              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/landingpage" element={<BrandLandingPage />} />
              <Route
                path="/website/:brandSlug"
                element={<BrandLandingPageWithSlug />}
              />
              <Route path="/landingpage-user" element={<UserPortal />} />
              {/* <Route path="/verify-mobile" element={<VerifyPhone />}/> */}
              {/* <Route path="/brands" element={<Brands />} /> */}
              {/* <Route path="/userhome" element={<UserHomePage />} />
              <Route
                path="/userhome/:brandId"
                element={<UserBrandMembershipPage />}
              /> */}
              {/* <Route path="/brands/:brandId" element={<BrandMembers />} />{" "} */}
              {/* <Route
                path="/brands/:brandId/:membershipId"
                element={<BrandMembersReward />}
              /> */}
              <Route
                path="/userhome/:brandId/:membershipId"
                element={<UserPortal />}
              />
              {/* Add the new route */}
              {/* <Route path="/profile-settings-User" element={<ProfileUser />} /> */}
              <Route path="/signupmodal" element={<BrandSignUpModal />} />
              <Route path="/listenSong" element={<SongListen />} />
              <Route path="/artistpage" element={<ArtistPage />} />
              <Route path="/qrcode/:qrId" element={<BrandQrCodeModal />} />
              <Route path="/login" element={<UserOnboarding />} />
              <Route path="/verify-user-email" element={<Verify />} />
              <Route path="/link-spotify" element={<Verified />} />
              <Route element={<ProtectedRoutes />}>
                {/* <Route
                  path="/dashboard"
                  element={
                    <>
                      <AppHeader brandInfo={brandInfo} />
                      <Dashboard />
                      <MembershipContractModal />
                      <RewardContractModal />
                    </>
                  }
                /> */}
                <Route path="/signupmodal-edit" element={<SignUpModalEdit />} />
                <Route path="/qrcode-edit/:qrId" element={<QrCodeModalEdit />} />
                <Route path="/landingpage-edit" element={<LandingPageEdit />} />
                <Route path="/verify-email" element={<VerifyEmail />} />

                <Route path="/memberships" element={<Memberships />} />
                <Route path="/landingpage-preview" element={<LandingPage />} />
                {/* <Route path="/reports" element={<ReportsPage />} /> */}
                <Route path="/rewards" element={<RewardsPage />} />
                <Route path="/quests" element={<QuestsPage />} />
                <Route path="/campaigns" element={<CampaignsPage />} />
                <Route path="/create-campaign" element={<NewCampaign />} />
                <Route path="/members" element={<Members />} />
                <Route path="/shopifyStores" element={<ShopifyStores />} />
                <Route path="/twitters" element={<TwitterAnalytics />} />
                <Route
                  path="/instagramDashboard"
                  element={<InstagramDashboard />}
                />
                <Route path="/discord" element={<Discord />} />
                <Route path="/airdrop" element={<AirdropPage />} />
                <Route path="/integrations" element={<Integrations />} />
                <Route path="/create-airdrop" element={<NewAirdrop />} />
                {/* <Route path="/integrations" element={<IntegrationsPage />} /> */}

                <Route path="/profile-settings" element={<ProfileSettings />} />
              </Route>
            </Routes>
          </ThemeStyled>
        </ThemeMui>
      </SocketProvider>
    </AppWrapper>
  );
}
