import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";

const PublicRoutes = () => {
  const tokens = localStorage.getItem("brandTokens");
  if (tokens) {
    return <Navigate to="/memberships" />;
  } else {
    return <Outlet />;
  }
};

export default PublicRoutes;
