import React, { useState, useEffect } from "react";
import { styled as styledMui } from "@mui/material/styles";
import PropTypes from "prop-types";
import API from "utils/userAPI";
import { IconCopy } from "images/icons/svg-components";
import {
  Button,
  CircularProgress,
  InputBase,
  Modal,
  ButtonBase,
  Checkbox,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ImageCampaignLogo,
  ImageUserPortalDefault,
  defaultProfile,
} from "images";
import styled, { css } from "styled-components";
import { mainTheme } from "theme/mainTheme";
import { useTranslation } from "react-i18next";

const NftTransferForm = ({
  tokenId,
  email,
  membershipId,
  amountOfNFtTransfer,
  showModal,
  transferNFT,
  membershipname,
  dispatch,
  fetchUserProfile,
  brandId,
  setShowModal,
  balanceOfNftOfUserMetadata,
  selectedCards,
  transferProgress,
  setTransferProgress
}) => {
  const { i18n } = useTranslation();
  const [polygonAddress, setPolygonAddress] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [transactionhashDetails, setTransactionhashDetails] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handlePolygonAddressChange = (event) => {
    setPolygonAddress(event.target.value);
  };
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowModal(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text ?? "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };
  const [isCopied, setIsCopied] = useState(false);
  const handleAddressSubmit = async () => {
    // Add your polygon address validation logic here
    // For example, you can check the address format
    setError(null);
    setLoading(true);

    if (isValidPolygonAddress(polygonAddress)) {
      // Send a request to the backend to send OTP to the user's email
      // You can replace the following line with your actual API call
      const otpSent = await sendOTPEmail(polygonAddress);

      if (otpSent) {
        setShowOtpInput(true);
      } else {
        // Handle error sending OTP
        setError("Error sending OTP. Please try again.");
      }
    } else {
      // Handle invalid address format
      setError("Invalid Polygon address format. Please check and try again.");
    }
    setLoading(false);
  };

  const handleOtpChange = (event) => {
    setOtpCode(event.target.value);
  };

  const handleOtpSubmit = async () => {
    setError(null);
    setLoading(true);
    // Send OTP code to the backend for verification
    // You can replace the following line with your actual API call
    const otpVerified = await verifyOtpCode(otpCode);

    if (otpVerified) {
      // Handle successful verification
      // alert("OTP code verified successfully!");
      setTransferProgress(otpVerified?.transactionStatus);
      setTransactionhashDetails(otpVerified?.transactionHash);

      dispatch(fetchUserProfile(membershipId, brandId, true));
    } else {
      // Handle OTP code verification failure
      setError("Invalid OTP code. Please try again.");
    }
    setLoading(false);
  };

  const isValidPolygonAddress = (address) => {
    // Add your Polygon address validation logic here
    // For example, check the length and character set
    return /^[0-9a-fA-Fx]+$/.test(address) && address.length === 42;
  };

  const sendOTPEmail = async (address) => {
    try {
      const res = await API.post("/users/transferNftToUsersWallet", {
        transferToAddress: address,
        tokenId,
        email,
        membershipId,
        amountOfNft: amountOfNFtTransfer,
        language: i18n?.language || "en",
      });

      const data = res?.data?.data;
      console.log(data, "transfferNfttowallet");

      return true;
    } catch (error) {
      if (error?.message?.includes("400")) {
        setError("The transfer for this NFT is already in progress");
      } else {
        setError("OTP error: " + error.message);
      }

      return false;
    }
  };

  const verifyOtpCode = async (otp) => {
    // Replace this function with your actual API call to verify OTP
    // For example, you can use Axios or fetch to send a POST request to your backend
    // with the user's Polygon address and OTP code
    try {
      const res = await API.post("/users/verify/TransferNftToUserWallet", {
        pincode: otp,
        email,
        tokenId,
        membershipId,
      });

      const data = res?.data?.data;
      console.log(data, "data");

      return res?.data?.data;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Verify error: " + error.message);
      return false;
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    const currentTime = `${formattedHours}:${minutes < 10 ? "0" : ""
      }${minutes} ${amOrPm}`;

    return currentTime;
  };

  function processTokenData(data) {
    const result = {};

    data.forEach((item) => {
      const contractAddress = item.contractAddress;
      const tokenId = item.tokenId;

      if (!result[contractAddress]) {
        result[contractAddress] = {
          tokenIds: [],
          amount: [],
        };
      }

      const contractResult = result[contractAddress];
      const index = contractResult.tokenIds.indexOf(tokenId);

      if (index === -1) {
        contractResult.tokenIds.push(tokenId);
        contractResult.amount.push(1);
      } else {
        contractResult.amount[index]++;
      }
    });

    return result;
  }

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filterdata = balanceOfNftOfUserMetadata?.filter((_, index) =>
      selectedCards.includes(index)
    );
    if (filterdata) {
      // setFilteredData(processTokenData(filterdata));
      setFilteredData(filterdata);
    }
  }, [balanceOfNftOfUserMetadata, selectedCards]);
  console.log(filteredData, "filteredData");
  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
        setError(null);
      }}
      closeAfterTransition
      disableAutoFocus={true}
    >
      <ModalContentWrapper style={{ maxWidth: "620px" }}>
        <CloseIcon
          sx={closeButtonStyle}
          onClick={() => {
            setShowModal(false);
            setError(null);
          }}
        />
        <div className="modal-header">
          <span className="title">{transferProgress === "NONE" ? 'Transfer NFT' : 'Successfully Transferred NFT!'}</span>
        </div>
        <div className="benefitnft">
          <MembershipImage src={transferNFT || ImageUserPortalDefault} />
          <MembershipTitle>{membershipname}</MembershipTitle>
          {transferProgress === "NONE" ?
            <>
              <FieldWrapper>
                <InputWrapper>
                  <Label>Receiving Address</Label>
                  <FormField
                    id={"addressField"}
                    sx={formFieldOverrides}
                    placeholder={"Wallet Address"}
                    fullWidth
                    value={polygonAddress}
                    onChange={handlePolygonAddressChange}
                  />
                </InputWrapper>
                {!showOtpInput &&
                  <ActionButton
                    onClick={handleAddressSubmit}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress /> : 'Confirm'}
                  </ActionButton>
                }
              </FieldWrapper>

              {showOtpInput && (
                <FieldWrapper>
                  <InputWrapper>
                    <Label>Verification Code</Label>
                    <FormField
                      id={"otpField"}
                      sx={formFieldOverrides}
                      placeholder={"6 Digit Code"}
                      fullWidth
                      value={otpCode}
                      onChange={handleOtpChange}
                    />
                  </InputWrapper>
                  <ActionButton
                    onClick={handleOtpSubmit}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress /> : 'Verify Code'}
                  </ActionButton>
                </FieldWrapper>
              )}
            </>
            : <FieldWrapper>
              <span className="success-message">Transfer initiated, please note that the transfer can take a couple of minutes to finish. We will send you a confirmation email once the transfer is completed.</span>
              <ActionButton
                onClick={() => setShowModal(false)}
              >
                Return To User Portal
              </ActionButton>
            </FieldWrapper>}
        </div>
        {error && <ErrorText>{error}</ErrorText>}
      </ModalContentWrapper>
    </Modal>
  );
};

const MembershipImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 300px;
  border-radius: 12px;
  object-fit: cover;
`;

const MembershipTitle = styled.span`
  ${(props) => props.theme.typography.header2};
  line-height: normal;
  text-align: center;
`;

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.subHeader};
    line-height: normal;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const ErrorText = styled.span`
  color: red;
  text-align: center;
`;

const ActionButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  height: "60px",
  lineHeight: "normal",
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    lineHeight: "normal",
    height: "48px",
  },
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

export const closeButtonStyle = {
  position: "absolute",
  top: "20px",
  right: "20px",
  width: "20px",
  height: "20px",
  color: "#7F86AD",
  cursor: "pointer",
};

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  color: "#7F86AD",
  height: "60px",
  gap: "12px",
  alignSelf: "stretch",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};
const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  lineHeight: "normal",
  "@media (max-width: 768px)": {
    fontSize: "16px"
  }
}));

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 55px 40px 55px;
  gap: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.darkestGrey};

  @media (max-width: 768px){
    padding: 32px 20px 32px 20px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      text-align: center;
      color: ${(props) => props.theme.palette.white};
      ${(props) => props.theme.typography.header1};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.header2};
      }
    }
  }

  .benefitnft {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    .success-message {
      ${(props) => props.theme.typography.bodyM};
      text-align: center;
    }
  }
`;

export default NftTransferForm;
