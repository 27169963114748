import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import ConnectTwitterModal from "components/ConnectTwitterModal";
import ConnectYoutubeModel from "components/ConnectYoutubeModel";
import ConnectDiscordModal from "components/ConnectDiscordModal";
import ConnectWebsiteModal from "components/ConnectWebsiteModal";
import brandAPI from "utils/brandAPI";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";
import { BsSpotify } from "react-icons/bs";
import { BsTwitch } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { FaShopify } from "react-icons/fa";
import ConnectShopifyModal from "components/ConnectShopifyModal";
import ConnectSpotifyModel from "components/ConnectSpotifyModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const socialData = [
  // {
  //   icon: <AiFillInstagram />,
  //   name: "Instagram",
  // },
  {
    icon: <BsYoutube />,
    name: "YouTube",
  },
  {
    icon: <AiOutlineTwitter />,
    name: "Twitter",
  },
  {
    icon: <BsDiscord />,
    name: "Discord",
  },
  // {
  //   icon: <SiApplemusic />,
  //   name: "Apple Music",
  // },
  // {
  //   icon: <FaTiktok />,
  //   name: "Tiktok",
  // },
  {
    icon: <BsSpotify />,
    name: "Spotify",
  },
  {
    icon: <BsGlobe />,
    name: "Website",
  },
  {
    icon: <FaShopify />,
    name: "Shopify",
    route: "/shopifyStores",
  },
];
export default function Integrations() {
  // const { brand } = useSelector((state) => state.manageBrand);
  // console.log(brand, "redux");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showTwitterConnectModal, setShowTwitterConnectModal] = useState(false);
  const [showSpotifyConnectModal, setShowSpotifyConnectModal] = useState(false);
  const [showShopifyConnectModal, setShowShopifyConnectModal] = useState(false);
  const [showDiscordConnectModal, setShowDiscordConnectModal] = useState(false);

  const [showYoutubeConnectModal, setShowYoutubeConnectModal] = useState(false);

  const [showWebsiteConnectModal, setShowWebsiteConnectModal] = useState(false);
  const [isHovered, setIsHovered] = useState({});

  const [brandData, setBrandData] = useState({});

  const [shopifyStoresForBrandID, setshopifyStoresForBrandID] = useState([]);
  const [instagramHashtagValue, setInstagramHashtagValue] = useState("");

  const init = async () => {
    const res = await brandAPI.get("/brands/me");
    let discordServers;
    let discordSelectedServers;
    try {
      discordServers = await brandAPI.get(
        `/discord/servers/${res.data.data.brand._id}`
      );
      discordSelectedServers = await brandAPI.get(
        `/discord/selected/${res.data.data.brand._id}`
      );
    } catch (error) {
      console.log(error);
    }
    setBrandData({
      email: res.data.data.brand.email,
      brandId: res.data.data.brand._id,
      Discord: discordServers?.data?.data?.servers ? true : false,
      servers: discordServers?.data?.data?.servers,
      selectedServers: discordSelectedServers?.data?.data?.servers,
      YouTube: res?.data?.data?.brand?.youtubeAuth ? true : false,
      YoutubeUrl:
        res?.data?.data?.brand?.socials?.youtube &&
        res?.data?.data?.brand?.socials?.youtube.substring(
          res?.data?.data?.brand?.socials?.youtube.lastIndexOf("/") + 1
        ),
      isBrand: true,
      Website: res.data.data.brand.websiteUrl,
      Twitter: res.data.data.brand.twitterUsername,
      spotifyArtistId: res.data.data.brand?.spotifyArtistId || "",
      spotifyArtistAvatar: res.data.data.brand?.spotifyArtistAvatar || "",
      spotifyArtistName: res.data.data.brand?.spotifyArtistName || "",
      socialUrls:res.data?.data?.brand?.socialUrls?res.data.data.brand?.socialUrls:""
    });
  };

  const getShopifyStoreByBrand = async () => {
    try {
      // const res = await API.get("/shopify/getTopOrdersByStore", {
      //   params: { storeId: "testing2-campaignlayer.myshopify.com" },
      // });

      const response = await brandAPI.get("/shopify/getShopifyStoreByBrand");
      // console.log(response);
      setshopifyStoresForBrandID(response?.data?.data);
    } catch (error) {
      console.log("get cheapest membership error:", error);
    }
  };

  const getInstagramHashtag = async () => {
    try {
      const response = await brandAPI.get("brands/me");
      // console.log(response?.data?.data?.brand?.instagramHashtag, "meinfo");
      setInstagramHashtagValue(response?.data?.data?.brand?.instagramHashtag);
    } catch (error) {
      console.log("get cheapest membership error:", error);
    }
  };
  // console.log("instagramHashtagValue", instagramHashtagValue);
  useEffect(() => {
    init();
    getShopifyStoreByBrand();
    getInstagramHashtag();
    return () => {};
  }, []);


  const youtubeHandleDisconnect = async () => {
    const res = await brandAPI.post("/youtube/disconnect", {
      brandId: brandData.brandId,
    });

    setBrandData({ ...brandData, YouTube: false });
  };
  const handleConnect = (platform) => {
    switch (platform) {
      case "Twitter":
        setShowTwitterConnectModal(true);
        break;
      case "Spotify":
        setShowSpotifyConnectModal(true);
        break;
      case "Shopify":
        setShowShopifyConnectModal(true);
        break;
      case "Discord":
        setShowDiscordConnectModal(true);
        break;
      case "Website":
        setShowWebsiteConnectModal(true)
        break;
      case "YouTube":
        setShowYoutubeConnectModal(true)
        // youtubeVerification();
        break;
      case "Instagram":
        navigate("/instagramDashboard");
      default:
        break;
    }
  };
  const handleDisConnect = (platform) => {
    switch (platform) {
      case "YouTube":
        setShowYoutubeConnectModal(true)
      // youtubeHandleDisconnect();
        break;
      case "Discord":
        handleConnect("Discord");
        break;
      case "Website":
        handleConnect("Website");
        break;
      case "Spotify":
        handleConnect("Spotify");
        break;
      default:
        break;
    }
  };

  return (
    <Layout activeTab={"integrations"}>
      <PageWrapper>
        <Header>
          <HeaderWrapper>{t("Integrations.integrations")}</HeaderWrapper>
        </Header>
        <MainContentWrapper>
          {socialData.map((item) => {
            const isShopifyConnected =
              shopifyStoresForBrandID &&
              shopifyStoresForBrandID[0]?.shopifyStoreAccessToken &&
              shopifyStoresForBrandID[0]?.shopifyStoreAccessToken &&
              item.name === "Shopify";
            const isSpotifyConnected = brandData?.spotifyArtistId !== "";
            const isConnected =
              (brandData && brandData[item?.name]) ||
              (item.name === "Shopify" && isShopifyConnected) ||
              (item.name === "Spotify" && isSpotifyConnected);
            return (
              <Item key={item.name} isConnected={isConnected}>
                <div className="left-content">
                  <IntegrationIcon isConnected={isConnected}>
                    {item.icon}
                  </IntegrationIcon>
                  <div>
                    <IntegrationText isConnected={isConnected}>
                      {item.name}
                    </IntegrationText>
                    {brandData && brandData[item?.name] && (
                      <Tag>
                        {item?.name !== "Website" && "@"}
                        {item?.name == "YouTube"
                          ? brandData.YoutubeUrl
                          : brandData[item?.name]}
                      </Tag>
                    )}
                  </div>
                </div>
                <button
                  onMouseEnter={() =>
                    setIsHovered((prev) => ({ ...prev, [item.name]: true }))
                  }
                  onMouseLeave={() =>
                    setIsHovered((prev) => ({ ...prev, [item.name]: false }))
                  }
                  onClick={() => {
                    isConnected
                      ? handleDisConnect(item?.name)
                      : handleConnect(item?.name);
                  }}
                  style={{
                    background: "var(--darkest-grey, #1a1a22)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: isConnected
                      ? isHovered[item.name]
                        ? "#6096FF"
                        : "#0BD680"
                      : "#7f86ad",
                    gap: "8px",
                    padding: "12px 20px",
                    border: isConnected
                      ? isHovered[item.name]
                        ? "1px solid #6096FF"
                        : "1px solid var(--dark-grey, #352B42)"
                      : "1px solid var(--dark-grey, #352B42)",
                    borderRadius: "6px",
                    width: "100%",
                    maxWidth: "155px",
                  }}
                >
                  {isHovered[item.name] && isConnected ? (
                    item.name === "Discord" || item.name === "Website" ? (
                      "Edit"
                    ) : (
                      "Disconnect"
                    )
                  ) : isConnected ? (
                    <>
                      Connected
                      <CheckCircleIcon
                        style={{ height: "18px", width: "18px", margin: 0 }}
                      ></CheckCircleIcon>
                    </>
                  ) : (
                    "Connect"
                  )}
                </button>
              </Item>
            );
          })}
        </MainContentWrapper>
      </PageWrapper>
      <ConnectTwitterModal
        showModal={showTwitterConnectModal}
        setShowModal={setShowTwitterConnectModal}
        twitterSocialUrl={brandData?.socialUrls?.twitter||""}
        onConncted={init}
      />
      <ConnectYoutubeModel
        showModal={showYoutubeConnectModal}
        setShowModal={setShowYoutubeConnectModal}
        brandData={brandData}
        setBrandData={setBrandData}
        youtubeSocialUrl={brandData?.socialUrls?.youtube||""}
        onConncted={init}
      />
      <ConnectShopifyModal
        showModal={showShopifyConnectModal}
        setShowModal={setShowShopifyConnectModal}
        shopifyStoresForBrandID={shopifyStoresForBrandID}
        shopifySocialUrl={brandData?.socialUrls?.shopify||""}
        onConncted={init}
      />
      <ConnectSpotifyModel
        showModal={showSpotifyConnectModal}
        setShowModal={setShowSpotifyConnectModal}
        brand={brandData}
        onConncted={init}
        spotifySocialUrl={brandData?.socialUrls?.spotify||""}
      />
      <ConnectDiscordModal
        showModal={showDiscordConnectModal}
        setShowModal={setShowDiscordConnectModal}
        onConncted={init}
        brandData={brandData}
        discordSocialUrl={brandData?.socialUrls?.discord||""}
      />
      <ConnectWebsiteModal
        showModal={showWebsiteConnectModal}
        setShowModal={setShowWebsiteConnectModal}
        onConncted={init}
        brandData={brandData}
      />
    </Layout>
  );
}

const IntegrationText = styled.div`
  color: ${(props) => (props.isConnected ? "#F4F3EE" : "#7F86AD")};
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Item = styled.div`
  display: flex;
  padding: 20px 20px 20px 28px;
  align-items: center;
  width: 48%;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => (props.isConnected ? "#1A1A22" : "#21212E")};
  justify-content: space-between;
  .left-content {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  button {
    ${(props) => props.theme.typography.bodySMedium};
  }
`;
const Tag = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;
const MainContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const IntegrationIcon = styled.div`
  > * {
    width: 32px;
    height: 32px;
  }
  color: ${(props) => (props.isConnected ? "#6096FF" : "#7F86AD")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
