import AppHeader from "components/AppHeader";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import logo from "images/icons/girlscrewicon.svg";
import { ButtonBase, Divider, InputBase, FormHelperText } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconUpload } from "images/icons/svg-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrand } from "containers/ManageBrand/action";
import API from "utils/brandAPI";
import { uploadFile } from "utils/s3";
import { ImageDefaultBrand, ImageDefaultUser } from "images";
import YoutubeVerified from "components/UserModal/components/YoutubeVerify";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brand } = useSelector((state) => state.manageBrand);
  const [brandUpdated, setBrandUpdated] = useState(false);

  const initalValue = {
    name: brand.name || "",
    userFirstName: "",
    userLastName: "",
    userEmail: brand.email || "",
    userPosition: "",
    brandLogo: "",
    userPicture: "",
    artistSlug: "",
    slug: "",
  };
  const [formValues, setFormValues] = useState({ ...initalValue });
  const {
    name,
    userFirstName,
    userLastName,
    userEmail,
    userPosition,
    brandLogo,
    userPicture,
    slug,
    artistSlug,
  } = formValues;

  const brandLogoURL =
    brandLogo instanceof File
      ? URL.createObjectURL(brandLogo)
      : brandLogo
      ? brandLogo
      : "";
  const userPictureURL =
    userPicture instanceof File
      ? URL.createObjectURL(userPicture)
      : userPicture
      ? userPicture
      : "";

  const goBack = () => {
    navigate("/memberships");
  };

  const handleChange = (event, name) => {
    if (name === "brandLogo" || name === "userPicture") {
      setFormValues({
        ...formValues,
        [name]: event ? event.target.files[0] : "",
      });
      return;
    }
    setFormValues({ ...formValues, [name]: event.target.value });
  };

  const handleSave = async () => {
    let updatedFormValues = { ...formValues };
    let brandLogoURL = brandLogo;
    let userPictureURL = userPicture;

    if (brandLogoURL instanceof File) {
      brandLogoURL = await uploadFile(brandLogo);
    }
    if (userPictureURL instanceof File) {
      userPictureURL = await uploadFile(userPicture);
    }

    updatedFormValues = {
      ...updatedFormValues,
      brandLogo: brandLogoURL,
      userPicture: userPictureURL,
    };

    await API.patch("brands/update-info", updatedFormValues).then((res) => {
      setBrandUpdated(true);
    });

  };

  const isSaveActive = Object.values(formValues).some((value) => value !== "");

  useEffect(() => {
    dispatch(fetchBrand());
    setBrandUpdated(false);
  }, [brandUpdated]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      name: brand.name ?? "",
      brandLogo: brand.brandLogo ?? "",
      userEmail: brand.email ?? "",
      userFirstName: brand.userFirstName ?? "",
      userLastName: brand.userLastName ?? "",
      userPosition: brand.userPosition ?? "",
      userPicture: brand.spotifyArtistAvatar ?? "",
      artistSlug: brand.artistSlug ?? "",
      slug: brand.slug ?? "",
    });
  }, [brand]);

  return (
    <>
      <AppHeader />
      <ContentWrapper className="container-fluid">
        <LeftMenu>
          <ActiveMenuItem disableRipple disableElevation isSelected={true}>
            Profile Settings
          </ActiveMenuItem>
        </LeftMenu>
        <MainContent>
          <HeaderWrapper>
            <LeftHeader>
              <BackButton onClick={goBack}>
                <ChevronLeftIcon />
              </BackButton>
              <HeaderTitle>Profile Settings</HeaderTitle>
            </LeftHeader>
            <SaveButton onClick={handleSave} disabled={!isSaveActive}>
              Save Changes
            </SaveButton>
          </HeaderWrapper>
          <Wrapper>
            <BrandInfoWrapper>
              <Image>
                {<img src={brandLogoURL ? brandLogoURL : ImageDefaultBrand} />}
                <TextOverlay
                  onClick={(event) => {
                    event.stopPropagation();
                    document.getElementById("uploadBrandLogo").click();
                  }}
                >
                  <input
                    id="uploadBrandLogo"
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      handleChange(event, "brandLogo");
                    }}
                  />
                  <IconUpload />
                  Upload Image
                </TextOverlay>
              </Image>
              <BrandInfo>
                <BrandName>{name ? name : brand.name}</BrandName>
                <Divider
                  sx={{
                    height: "1px",
                    borderColor: "#352B42",
                    margin: "20px 0",
                  }}
                />
                <FieldWrapper>
                  <Label>Company Name</Label>
                  <FormField
                    sx={formFieldOverrides}
                    value={name}
                    onChange={(e) => {
                      handleChange(e, "name");
                    }}
                  />
                </FieldWrapper>
              </BrandInfo>
            </BrandInfoWrapper>
            <UserInfoWrapper>
              <UserInfoHeader>
                <Image>
                  <img
                    src={userPictureURL ? userPictureURL : ImageDefaultUser}
                  />
                  <TextOverlay
                    onClick={(event) => {
                      event.stopPropagation();
                      document.getElementById("uploadUserPicture").click();
                    }}
                  >
                    <input
                      id="uploadUserPicture"
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        handleChange(event, "userPicture");
                      }}
                    />
                    <IconUpload />
                    Upload Image
                  </TextOverlay>
                </Image>
                <UserInfo>
                  <UserName>
                    {userFirstName ? userFirstName : ""}{" "}
                    {userLastName ? userLastName : ""}
                  </UserName>
                  <UserPositon>
                    {userPosition ? userPosition : "Position"}
                    {" @ "}
                    {name ? name : brand.name}
                  </UserPositon>
                </UserInfo>
              </UserInfoHeader>
              <PersonalInfoWrapper>
                <PersonalInfoTitle>
                  Personal Information
                  <Divider
                    sx={{
                      height: "1px",
                      borderColor: "#352B42",
                      marginTop: "20px",
                    }}
                  />
                </PersonalInfoTitle>
                <Fields>
                  <FieldWrapper>
                    <Label>First Name</Label>
                    <FormField
                      sx={formFieldOverrides}
                      placeholder="First name"
                      value={userFirstName}
                      onChange={(e) => {
                        handleChange(e, "userFirstName");
                      }}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Label>Last Name</Label>
                    <FormField
                      sx={formFieldOverrides}
                      placeholder="Last name"
                      value={userLastName}
                      onChange={(e) => {
                        handleChange(e, "userLastName");
                      }}
                    />
                  </FieldWrapper>
                </Fields>
                <Fields>
                  <FieldWrapper>
                    <Label>Email Address</Label>
                    <FormField
                      sx={formFieldOverrides}
                      disabled
                      value={userEmail}
                      onChange={(e) => {
                        handleChange(e, "userEmail");
                      }}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Label>Position</Label>
                    <FormField
                      sx={formFieldOverrides}
                      placeholder="Position"
                      value={userPosition}
                      onChange={(e) => {
                        handleChange(e, "userPosition");
                      }}
                    />
                  </FieldWrapper>
                </Fields>
                <Fields>
                  {/* <FieldWrapper>
                    <Label>Spotify Modal Slug</Label>
                    <FormField
                      sx={formFieldOverrides}
                      placeholder="Artist Slug"
                      value={artistSlug}
                      onChange={(e) => {
                        handleChange(e, "artistSlug");
                      }}
                    />
                  </FieldWrapper> */}
                  <FieldWrapper>
                    <Label>User Portal Slug</Label>
                    <FormField
                      sx={formFieldOverrides}
                      placeholder="Brand Slug"
                      value={slug}
                      onChange={(e) => {
                        handleChange(e, "slug");
                      }}
                    />
                  </FieldWrapper>
                </Fields>
              </PersonalInfoWrapper>
            </UserInfoWrapper>
          </Wrapper>
        </MainContent>
      </ContentWrapper>
    </>
  );
};

const EmptyImage = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.lightGrey};
  height: 136px;
  width: 136px;
  border-radius: 100%;
`;

export const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 136px;
  height: 136px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
  background-color: black;
  border-radius: 100%;
  opacity: 0.8;
  cursor: pointer;
  gap: 5px;
`;

export const Image = styled.div`
  position: relative;
  height: 136px;
  width: 136px;
  border-radius: 100%;

  img {
    height: 136px;
    width: 136px;
    border-radius: 100%;
  }

  &:hover {
    ${TextOverlay} {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 74%;
  overflow-y: scroll;

  /* Hide the scrollbar on WebKit browsers (e.g. Chrome, Safari) */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide the scrollbar on Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Fields = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const UserInfoHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
  align-items: center;
`;

const PersonalInfoTitle = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
`;

const PersonalInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;

const UserPositon = styled.div`
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const UserName = styled.div`
  ${(props) => props.theme.typography.header1};
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const UserInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
`;

const formFieldOverrides = {
  width: "100%",
  backgroundColor: "#21212E",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#7F86AD",
  padding: "12px 16px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
    "&.Mui-disabled": {
      "-webkit-text-fill-color": "grey",
    },
  },
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.subHeader};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const FormField = styledMui(InputBase)({});

const BrandName = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) => props.theme.typography.header1};
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: #6096ff;
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:hover {
    opacity: 0.7;
  }
`;

const BrandInfoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.darkestGrey};
  display: flex;
  width: 100%;
  padding: 60px;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderTitle = styled.div`
  ${(props) => props.theme.typography.header1};
`;

const StyledFormHelperText = styledMui(FormHelperText)`
  color: red;
`;

const SaveButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  color: theme.palette.white,
  width: "fit-content",
  textTransform: "none",
  padding: "14px 20px",
  background: theme.palette.campaignBlue,
  borderRadius: 6,

  "&:hover": {
    backgroundColor: theme.palette.darkerGrey,
    filter: "none",
    color: theme.palette.white,
  },

  ":disabled": {
    color: theme.palette.lightGrey,
    background: theme.palette.darkGrey,
    opacity: 1,
  },
}));

const MenuItem = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightBlue,
  justifyContent: "flex-start",
  width: "100%",
  textTransform: "none",
  padding: "16px 28px",
  background: "transparent",
  borderRadius: 6,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  "&:hover": {
    backgroundColor: "#222239",
    filter: "none",
    color: theme.palette.white,
  },
}));

const ActiveMenuItem = styledMui(MenuItem)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.palette.darkerGrey : "",
  color: theme.palette.white,
  margin: "5px",
}));

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 27.5px;
  width: 290px;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.palette.darkGrey};
`;

const MainContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 40px 40px 19px 33px;
  gap: 20px;
`;

export default ProfileSettings;
