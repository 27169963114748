import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { defaultProfile } from "images";
import { formatSreams } from "containers/Analytics/ArtistDashboard/utils";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CheckIcon from "@mui/icons-material/Check";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import { IconCrown } from "images/icons/svg-components";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import { IconCheck } from "images/icons/svg-components";

const LeaderboardCard = ({
  leader,
  index,
  setShowUserModal,
  isMyRank = false,
  saveButtonType = "Song"
}) => {
  const leaderboardData = isMyRank ? leader?.leaderBoardData?leader?.leaderBoardData[0]:[] : leader.leaderBoardData
  const rankCrown = (rank) => {
    switch (rank) {
      case 1:
        return (
          <IconCrown
            style={{
              background: "#FFDD55",
              borderRadius: "4px",
              color: "black",
            
            }}
          />
        );
      case 2:
        return (
          <IconCrown
            style={{
              background: "#D9D9D9",
              borderRadius: "4px",
              color: "black",
            
            }}
          />
        );
      case 3:
        return (
          <IconCrown
            style={{
              background: "#BE6B1E",
              borderRadius: "4px",
              color: "black",
            
            }}
          />
        );
      default:
        return `#${rank ?rank :"-"}`;
    }
  };
  return (
    <StyledTableRow key={index}>
      {!leader ? (
        <StyledTableCell colSpan={5}>
          <LoginToSeeYourRankButton onClick={() => setShowUserModal(true)}>
            Log in to See Your Rank
          </LoginToSeeYourRankButton>
        </StyledTableCell>
      ) : (
        <>
          <StyledTableCell component="th" scope="row" style={{color:"white"}}>
            {isMyRank
              ? rankCrown(parseInt(leaderboardData?.index))
              : rankCrown(parseInt(index))}
          </StyledTableCell>
          <StyledTableCell>
            <UsernameWrapper>
              {leaderboardData?.userImage ? (
                <img
                  src={leaderboardData?.userImage}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultProfile;
                  }}
                />
              ) : (
                <img src={defaultProfile} />
              )}
              <span className="username">
                {leaderboardData?.spotifyUserName ? leaderboardData.spotifyUserName : "-"}
              </span>
            </UsernameWrapper>
          </StyledTableCell>
          <StyledTableCell style={{whiteSpace: "nowrap"}}>
            {leader?.isAlbumSaved ? (
              <AlbumWrapper>
                <IconCheck
                  style={{
                    // color: "white",
                    // background: "#294bfc",
                    // borderRadius: "50%",
                    marginRight: "10px",
                    fontSize: "1.2rem",
                  }}
                />
                {/* For Sonny Fodera demo change it to Saved Song */}
                {/* <span className="username">Saved Album</span> */}
                <span className="username">Saved {saveButtonType}</span>
              </AlbumWrapper>
            ) : (
              <AlbumWrapper>
                <CloseIcon
                  style={{
                    color: "black",
                    background: "#FF8379",
                    borderRadius: "50%",
                    fontSize: "1.2rem",
                    padding: "4px",
                    marginRight: "10px",
                  }}
                />
                {/* For Sonny Fodera demo change it to Saved Song */}
                {/* <span className="username">Saved Album</span> */}
                <span className="username">Saved {saveButtonType}</span>
              </AlbumWrapper>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {leader?.artistWisedata
              ? formatSreams(
                  Math.floor(leader?.artistWisedata)
                )
              : "0"}
          </StyledTableCell>
          {/* <StyledTableCell>
            {leader?.subtotalPrice ? "$"+  Math.floor(leader?.subtotalPrice) : "$"+0}
          </StyledTableCell> */}
        </>
      )}
    </StyledTableRow>
  );
};

const StyledTableCell = styledMui(TableCell)(({ theme }) => ({
  fontSize: "20px",
  padding: "20px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#21212e",
    color: "#7d84ab",
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#7d84ab",
    borderBottom: "2px solid #352b42",
  },
}));

const StyledTableRow = styledMui(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1a1a22",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#1a1a22",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const UsernameWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 100%;
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
`;

const AlbumWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 100%;
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
`;

const LoginToSeeYourRankButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "18px 20px",
  height: "60px",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

export default LeaderboardCard;
