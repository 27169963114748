/**
 *
 * SignUpSection
 *
 */

import React, { memo, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { func, object } from "prop-types";
import BackButton from "../BackButton/Loadable";
import { clearErrors } from "containers/OnboardingPage/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageChanger from "languageProvider/languagechanger";
function SignUpSection({
  onBackClick,
  onVerifyEmailClick,
  formValues,
  handleChange,
  error,
  onLoginClick,
}) {
  const { t } = useTranslation();
  const { name, email, password, passwordConfirm } = formValues;
  const dispatch = useDispatch();
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      onVerifyEmailClick();
    }
  };
  useEffect(() => {
    dispatch(clearErrors());
  }, []);

  return (
    <Wrapper onKeyDown={handleKeyPress}>
      <Title>{t("SignUpSection.title")}</Title>
      <FieldWrapper>
        {/* <BackButton onClick={onBackClick} /> */}
        <LabelWrapper>
          <Label>{t("SignUpSection.passwordLabel")}</Label>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder={t("SignUpSection.passwordPlaceholder")}
          inputProps={{
            "aria-label": "password",
          }}
          fullWidth
          value={password}
          type="password"
          onChange={(event) => handleChange(event, "password")}
        />
        <LabelWrapper>
          <Label>{t("SignUpSection.confirmPasswordLabel")}</Label>
        </LabelWrapper>
        <FormField
          sx={formFieldOverrides}
          placeholder={t("SignUpSection.confirmPasswordPlaceholder")}
          inputProps={{
            "aria-label": "confirm passowrd",
          }}
          type="password"
          fullWidth
          value={passwordConfirm}
          onChange={(event) => handleChange(event, "passwordConfirm")}
        />
      </FieldWrapper>
      {error && <Error>{error}</Error>}
      <LoginButton onClick={onVerifyEmailClick} variant="contained">
        {t("SignUpSection.createAccountButton")}
      </LoginButton>
      <LanguageChanger style={{justifyContent:"flex-end"}}/>
    </Wrapper>
  );
}

const ForgotPasswordButton = styled.div`
  display: flex;
  text-decoration: underline;
  cursor: pointer;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodySMedium,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 0",
  width: "179px",

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const FormField = styledMui(InputBase)({});

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: ${(props) => props.theme.palette.white};
`;

const Error = styled.div`
  color: red;
  font-size: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 656px;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CTA = styledMui(Button)(() => ({
  marginTop: 28,
  width: 544,
  height: 58,
  fontSize: 24,
  borderRadius: 10,
  fontWeight: 400,
  textTransform: "none",
  color: "#000000",
  backgroundColor: "#D9D9D9",
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
  fontFamily: "Poppins",
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "28px",
  color: "#F4F3EE",
  padding: "13px 20px",

  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
    },
  },
};

SignUpSection.propTypes = {
  onBackClick: func,
  onVerifyEmailClick: func,
  formValues: object,
  handleChange: func,
};

export default memo(SignUpSection);
