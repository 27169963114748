/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, CircularProgress, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useTranslation } from "react-i18next";

import { IconMetamask, IconGoogle } from "images/icons/svg-components";
import API from "utils/userAPI";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import {
  ConnectWallet,
  Web3Button,
  useAddress,
  useConnectionStatus,
} from "@thirdweb-dev/react";
const Main = ({
  formValues,
  onFormValueChanges,
  error,
  handleLogin,
  handleGoogleLogin,
  loading,
  brandId,
  membershipId,
  membershipType,
}) => {
  const { email } = formValues;
  const { t } = useTranslation();

  useEffect(() => {
    const inputElement = document.getElementById("emailField");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);
  const address = useAddress();
  const navigate = useNavigate();

  const signInUsingWallet = async () => {
    try {
      console.log("ehllo", { walletAddress: address, brandId: brandId });
      const res = await API.post("/auth/wallet/user", {
        walletAddress: address,
        brandId: brandId,
      });
      console.log(res, "hello");
      localStorage.setItem("userTokens", JSON.stringify(res?.data?.data));
      localStorage.setItem(
        "userId",
        JSON.stringify(res?.data?.data?.user?._id)
      );
      navigate(`/userhome/${brandId}/${membershipId}`);
    } catch (e) {
      console.log(e);
    }
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (CodeResponse) => {
      handleGoogleLogin(CodeResponse.code);
    },
    onError: (err) => {
      console.log("Login Failed", err);
    },
  });

  return (
    <Wrapper>
      <MainText>{t("Main.title")}</MainText>
      <FieldWrapper isError={error !== ""}>
        <div className="input-wrapper">
          <Label>{t("Main.email")}</Label>
          <FormField
            id={"emailField"}
            sx={formFieldOverrides}
            placeholder={t("Main.emailplaceholder")}
            inputProps={{
              "aria-label": "email",
            }}
            fullWidth
            value={email}
            onChange={(event) => onFormValueChanges(event, "email")}
          />
        </div>
        {error && <Error>{error}</Error>}
        <LoginButton
          type="submit"
          onClick={() => handleLogin()}
          variant="contained"
        >
          {loading ? <CircularProgress /> : t("Main.continue")}
        </LoginButton>
        <DividerCustom>{t("Main.or")}</DividerCustom>
        <GoogleLoginButton
          variant="outlined"
          startIcon={
            <IconGoogle
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          }
          onClick={() => googleLogin()}
        >
          {t("Main.googlebutton")}
        </GoogleLoginButton>

        {/* {
          <DividerCustom>Or Continue using Wallet</DividerCustom>
        )}
        {address
          ? membershipType &&
          membershipType == "NFT-based membership" && (
            <ConnectWalletButton onClick={signInUsingWallet}>
              <IconMetamask /> Login Using Wallet
            </ConnectWalletButton>
          )
          : membershipType &&
          membershipType == "NFT-based membership" && (
            <ConnectWallet
              style={connectWalletStyles}
              theme="dark"
              btnTitle="Connect Wallet"
              modalSize="wide"
              dropdownPosition={{
                side: "bottom",
                align: "center",
              }}
              switchToActiveChain={true}
            />
          } */}
      </FieldWrapper>
    </Wrapper>
  );
};

const connectWalletStyles = {
  width: "100%",
  display: "flex",
  height: "60px",
  padding: "20px 40px",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  alignSelf: "stretch",
  color: "#1A1A22",
  fontFamily: "Inter",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
};
const ConnectWalletButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  border: `1px solid ${theme.palette.darkGrey}`,
  textTransform: "none",
  borderRadius: "6px",
  padding: "20px 40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "20px",
  height: "60px",
  lineHeight: "normal",
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    height: "48px",
  },
  "&:hover": {
    opacity: 0.9,
  },
}));

const GoogleLoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  height: "60px",
  gap: "20px",
  lineHeight: "normal",
  color: theme.palette.lightGrey,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  border: "1px solid #352B42",

  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    lineHeight: "normal",
    height: "48px",
  },

  "&:hover": {
    backgroundColor: "#D9D9D988",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
    borderColor: theme.palette.darkGrey,
  },
}));

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  ${"" /* height: 249px; */}
  @media (max-width: 768px) {
    gap: 40px;
    height: 100%;
  }
`;

const MainText = styled.div`
  display: flex;
  ${(props) => props.theme.typography.header1};
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
  justify-content: center;
  align-self: stretch;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.header2};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const DividerCustom = styledMui(Divider)(({ theme }) => ({
  ...theme.typography.bodyM,
  color: theme.palette.lightGrey,
  width: "100%",
  display: "flex",
  alignItems: "center",
  span: {
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  "&::before, &::after": {
    height: "1px",
    backgroundColor: theme.palette.darkGrey,
  },
}));

const LoginButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header2,
  color: theme.palette.white,
  backgroundColor: theme.palette.campaignBlue,
  textTransform: "none",
  borderRadius: "6px",
  padding: "12px 20px",
  height: "60px",
  lineHeight: "normal",
  "@media (max-width: 768px)": {
    ...theme.typography.bodySMedium,
    lineHeight: "normal",
    height: "48px",
  },
  "&:hover": {
    backgroundColor: "#D9D9D988",
  },
}));

const formFieldOverrides = {
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "16px 20px",
  color: "#7F86AD",
  height: "60px",
  gap: "12px",
  alignSelf: "stretch",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  input: {
    padding: 0,
    "&::placeholder": {
      color: "#7F86AD",
      opacity: 1,
    },
  },
};

const BaseText = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
`;

const Label = styled(BaseText)`
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.lightBlue};
  line-height: normal;
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.subHeader};
    line-height: normal;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  @media (max-width: 768px) {
    gap: ${(props) => (props.isError ? "20px" : "40px")};
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  ${"" /* margin-top: 10px; */}
  color: red;
`;

const SecondaryButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  textTransform: "none",
  backgroundColor: "transparent",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const SpotifyButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  textTransform: "none",
  backgroundColor: "#21BA52",
  border: "1px solid #352B42",
  borderRadius: "6px",
  padding: "18px 20px",
  gap: "20px",
  height: "60px",

  "&:hover": {
    backgroundColor: "#D1D7F5",
  },

  "&.Mui-disabled": {
    "-webkit-text-fill-color": "#7F86AD",
  },
}));

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.white,
  lineHeight: "normal",
  "@media (max-width: 768px)": {
    fontSize: "16px",
  },
}));

export default Main;
