/**
 *
 * ClaimMembershipSnackbar
 *
 */

import React, { memo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import styled from "styled-components";
import { Snackbar, Slide } from "@mui/material";
import makeSelectUserPortal from "containers/UserPortal/selector";
import { Close } from "@mui/icons-material";
import { toggleSnackbar } from "containers/UserPortal/actions";
import { useTranslation } from "react-i18next";

function ClaimMembershipSnackbar({ showSnackbar, dispatch, membershipName }) {
  const { t } = useTranslation();

  const handleClose = () => {
    if (showSnackbar) {
      dispatch(toggleSnackbar());
    }
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
      {showSnackbar && (
        <Snackbar
          autoHideDuration={3000}
          open={showSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleClose}
          TransitionComponent={TransitionLeft}
          key={"claimMembershipSnackbar"}
        >
          <ContentWrapper>
            <LabelWrapper>
              <Label>{t("ClaimMembershipSnackbar.title")}</Label>
              <Sublabel>
                {t("ClaimMembershipSnackbar.welcome")} {membershipName}
              </Sublabel>
            </LabelWrapper>
            <Close
              onClick={handleClose}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "8px",
                right: "8px",
              }}
            />
          </ContentWrapper>
        </Snackbar>
      )}
    </>
  );
}

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: centers;
  margin-right: 6px;
`;

const Label = styled.div`
  ${(props) => props.theme.typography.header2};
  font-size: 18px;
  color: ${(props) => props.theme.palette.white};
`;

const Sublabel = styled.div`
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};

  span {
    color: ${(props) => props.theme.palette.lightBlue};
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 28px 32px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.darkestGrey};
`;

const mapStateToProps = createStructuredSelector({
  userPortalState: makeSelectUserPortal(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ClaimMembershipSnackbar);
