/**
 *
 * BrandHeader
 *
 */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import companyLogoImg from "../../images/campaignlogo.png";
import { Backdrop, ButtonBase, Divider, Fade } from "@mui/material";
import Modal from "@mui/material/Modal";
import API from "utils/userAPI";
import {  useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconProfile } from "images/icons/svg-components";
import { ImageDefaultBrand, ImageDefaultUser } from "images";
import { Button } from "@mui/material";
import {
  logoutUser,
} from "../../containers/UserPortal/actions.js";
import LoginIcon from '@mui/icons-material/Login';
// eslint-disable-next-line react/prop-types
function BrandHeader({formValues,setFormValues}) {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((prev) => !prev);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isOnboarding = window.location.pathname === "/";

  return (
    <BottomBorder>
      <Wrapper className="container-fluid">
        <CompanyLogo onClick={() => navigate("/userhome")}>
          <img src={companyLogoImg} alt="logo" />
        </CompanyLogo>
        {!isOnboarding && (
          
          <RightHeader>
              {!formValues&& <PreviewButton onClick={()=>{ navigate('/login', {});}}>
               Log in
            <ButtonIcon>
            <LoginIcon />
          </ButtonIcon>
        </PreviewButton>}
            <ProfileButton
              src={ImageDefaultUser}
              onClick={() => {
                formValues&& toggleModal();
              }}
            />
            <Modal
              open={showModal}
              onClose={toggleModal}
              closeAfterTransition
              disableAutoFocus={true}
            >
              <ProfileMenu>
                {/* <UserName>Test</UserName>
                <Divider
                  sx={{ borderColor: "#352B42", margin: "20px 0" }}
                ></Divider> */}

               {formValues? <ProfileMenuButton onClick={async()=>{
                   dispatch(logoutUser());
                   localStorage.removeItem("userTokens");
                   localStorage.removeItem("userId");
                   setFormValues("");
                   await API.post("/auth/logout");
                   toggleModal()
                }}>Log Out</ProfileMenuButton>:
                <>
                {/* <ProfileMenuButton onClick={async()=>{
                  navigate('/login', {});
                }}>Log In</ProfileMenuButton> */}
                </>
                }
              </ProfileMenu>
            </Modal>
          </RightHeader>
        )}
      </Wrapper>
    </BottomBorder>
  );
}
const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #352b42;
`;

const Wrapper = styled.div`
  display: flex;
  height: 92px;
  padding: 0 40px;
  justify-content: space-between;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  border: 1px solid #352b42;
  border-radius: 8px;
  column-gap: 16px;
  padding: 10px 16px;
`;

const RightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Username = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.lightBlue};
`;

const UserLogo = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.palette.lightGrey};

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
`;

const ButtonIcon = styled.span`
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
`;
const CompanyLogo = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  img {
    width: 250px;
    height: 40px;
  }
`;

const ProfileButton = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 28px;
  border-radius: 100%;
`;

const ProfileMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 82px;
  right: 40px;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.darkerGrey};
  border-radius: 8px;
  z-index: 10;
`;

const ProfileMenuButton = styledMui(ButtonBase)(({ theme }) => ({
  ...theme.typography.header3,
  color: theme.palette.lightGrey,
  width: "fit-content",

  padding: "10px",
  ":hover": {
    color: theme.palette.lightBlue,
  },
}));

const UserName = styled.div`
  padding: 10px;
  ${(props) => props.theme.typography.header2};
  color: ${(props) => props.theme.palette.white};
`;

const UserMail = styled.div`
  padding: 10px;
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
`;


const BaseButton = styledMui(Button)(({ theme }) => ({
  ...theme.typography.bodyM,
  width: 157,
  height: 44,
  borderRadius: 10,
  textTransform: "none",

  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
}));

const PreviewButton = styledMui(BaseButton)(({ theme }) => ({
  border: "1px solid #352B42",
  color: theme.palette.lightBlue,
  marginLeft: "auto",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
  width: "fit-content",
  background: "transparent",
  borderRadius: "6px",
  height: "52px",
  fontSize: "18px",
}));

BrandHeader.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
});

export default connect(mapStateToProps)(BrandHeader);
