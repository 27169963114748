/**
 *
 * LandingPage
 *
 */

import React, { memo, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import styled from "styled-components";
import { FilterButton } from "components";
import Chart from "./Chart";
import { Figure } from "./Figure";
import { Top } from "./Top";
import { Summary } from "./Summary";
import Grid from "@mui/material/Grid";
import "./style.css";
import {
  Box,
  Table,
  TableBody,
  InputBase,
  FormControl,
  NativeSelect,
} from "@mui/material";
import { useQuery } from "react-query";
import API from "utils/brandAPI";
import { useRef } from "react";
import { styled as styledMui } from "@mui/material/styles";
import { IconCalendar } from "images/icons/svg-components";
import DarkThemeDatePicker from "components/DarkThemeDatePicker";
import queryString from "query-string";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import AnalyticDashboard from "./Dashboard";
import Leaderboard from "./Leaderboard";
import ArtistDashboard from "./ArtistDashboard";
import {
  ImageDefaultBrand,
  ImageDefaultUser,
  ImageSonnyFodera,
  ImageUserProfileMock,
} from "images";
import { SET_ARTISTNAME } from "containers/ManageBrand/constants";
import { useTranslation } from "react-i18next";

export function Analytics() {
  const dispatch = useDispatch();
const { t } = useTranslation();
  const [artistsArr, setArtistsArr] = useState([]);
  const [artistId, setArtistId] = useState("");
  const [artist, setArtist] = useState("Artist");
  const [spotifyState, setSpotifyState] = useState([]);
  const [dateIntimeStamp, setdateInTimeStamp] = useState({});
  const [currentTab, setCurrentTab] = useState("program");
  const [timePeriod, setTimePeriod] = useState("");
  const filterOptions = ["Filter"];
  const [artistStats, setArtistStats] = useState([]);
  const [streamChart, setStreamChart] = useState([]);
  const [topStreams, setTopStreams] = useState([]);
  const [artistTotalStream, setArtistTotalStream] = useState({
    total: 0,
    growth: 0,
  });
  const dateFilterOptions = [
   t("Analytics.filterlabel")
    // "Last week",
  ];

  let [open, setOpen] = React.useState(false);
  const filterRef = useRef();
  const prevOpen = useRef(open);
  let brandArtistId = localStorage.getItem("artistId") || "";
  let brandId = localStorage.getItem("brandId") || "";
  let brandArtistName = localStorage.getItem("artistName") || "";
  let brandArtistAvatar = localStorage.getItem("artistAvatar") || "";
  // const [artistInfo, setArtistInfo] = useState({ id: "", name: "", image: "" });
  const [programData, setProgramData] = useState({
    members: 0,
    membersGrowth: 0,
    rewards: 0,
    rewardsGrowth: 0,
    topMemberships: [],
    membershipTimeseries: [],
    topRewards: [],
    rewardsTimeseries: [],
  });

  useEffect(() => {
    setArtistId(brandArtistId);

    return () => {};
  }, []);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const reqFilter = (arts, timeStamp) => {
    let queryParms = {};
    if (arts?.id || artistId) {
      queryParms = { ...queryParms, artistId: arts.id || artistId };
      setArtist(arts.name);
    }
    if (timeStamp?.start && timeStamp?.end) {
      queryParms = {
        ...queryParms,
        start: timeStamp.start,
        end: timeStamp.end,
      };
    }

    API.get(`spotify/stats?${queryString.stringify(queryParms)}`).then(
      (res) => {
        setSpotifyState(res.data.data);
      }
    );
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      filterRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleTimePeriodSelect = (period) => {
    switch (period) {
      case "Last week":
        setTimePeriod(
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toString()
        );
        break;
      case "Last month":
        setTimePeriod(
          new Date(new Date().setMonth(new Date().getMonth() - 1)).toString()
        );
        break;
      default:
        setTimePeriod("");
        break;
    }
  };

  const { data: artistData } = useQuery(
    ["artists"],
    () => API.get(`/spotify/artists`),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        if (!brandArtistId && res?.data?.data?.length > 0) {
          setArtistId(res.data.data[0].id);
        }
      },
      enabled: !brandArtistId,
    }
  );

  useQuery(
    ["artist_stats", brandArtistName,brandArtistId,brandArtistId,brandArtistName],
    () => API.get(`/spotify/artist_stats?artistName=${brandArtistName}&artistId=${brandArtistId}`),
    {
      keepPreviousData: true,
      enabled: !!brandArtistName,
      enabled: !!brandArtistId,
      onSuccess: (res) => {
        setArtistStats(res?.data?.data);
        setArtistTotalStream(res?.data?.total_streams_by_artist);
        setTopStreams(res.data.top_songs);
         setStreamChart(res.data.song_streams);
      },
    }
  );
  // useQuery(
  //   ["total_streams_by_artist", brandArtistId],
  //   () => API.get(`/ ),
  //   {
  //     keepPreviousData: true,
  //     enabled: !!brandArtistId,
  //     onSuccess: (res) => {
  //       setArtistTotalStream(res.data.data);
  //     },
  //   }
  // );
  // useQuery(
  //   ["song_streams", brandArtistId],
  //   () => API.get(`/spotify/song_streams_by_artist?artistId=${brandArtistId}`),
  //   {
  //     keepPreviousData: true,

  //     enabled: !!brandArtistName,
  //     onSuccess: (res) => {
  //       setStreamChart(res.data.data);
  //     },
  //   }
  // );
  // useQuery(
  //   ["top_songs", brandArtistName],
  //   () => API.get(`/spotify/top_songs?artistName=${brandArtistName}`),
  //   {
  //     keepPreviousData: true,

  //     enabled: !!brandArtistName,
  //     onSuccess: (res) => {
  //       setTopStreams(res.data.data);
  //     },
  //   }
  // );

  useQuery(
    ["analtyics_users"],
    () => API.get(`/brands/analytics/users?brandId=${brandId}`),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setProgramData((prevProgramData) => ({
          ...prevProgramData,
          members: res?.data?.data?.total,
        }));
      },
    }
  );
  // useQuery(
  //   ["analtyics_rewards"],
  //   () => API.get(`/brands/analytics/rewards?brandId=${brandId}`),
  //   {
  //     keepPreviousData: true,
  //     onSuccess: (res) => {
  //       setProgramData((prevProgramData) => ({
  //         ...prevProgramData,
  //         rewards: res?.data?.data?.total,
  //       }));
  //     },
  //   }
  // );
  useQuery(
    ["analtyics_top_rewards"],
    () => API.get(`/brands/analytics/top_rewards?brandId=${brandId}`),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setProgramData((prevProgramData) => ({
          ...prevProgramData,
          topRewards: res?.data?.data,
           topMemberships: res?.data?.topMemberships,
           rewards: res?.data?.rewards?.total
        }));
      },
    }
  );
  // useQuery(
  //   ["analtyics_top_memberships"],
  //   () => API.get(`/brands/analytics/top_memberships?brandId=${brandId}`),
  //   {
  //     keepPreviousData: true,
  //     onSuccess: (res) => {
  //       setProgramData((prevProgramData) => ({
  //         ...prevProgramData,
  //         topMemberships: res?.data?.data,
  //       }));
  //     },
  //   }
  // );
  useQuery(
    ["analtyics_membership_chart"],
    () => API.get(`/brands/analytics/membership_chart?brandId=${brandId}`),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setProgramData((prevProgramData) => ({
          ...prevProgramData,
          membershipTimeseries: res?.data?.data,
        }));
      },
    }
  );
  useQuery(
    ["analtyics_reward_chart"],
    () => API.get(`/brands/analytics/reward_chart?brandId=${brandId}`),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setProgramData((prevProgramData) => ({
          ...prevProgramData,
          rewardsTimeseries: res?.data?.data,
        }));
      },
    }
  );

  return (
    <>
      <Helmet>
        <title>Analytics</title>
        <meta name="description" content="Description of Analytics" />
      </Helmet>
      <PageWrapper>
        <HeaderWrapper>{t("Analytics.analytics")}</HeaderWrapper>

        <Box display={"flex"} sx={{ alignItems: "center", marginTop: "-14px" }}>
          <Tab
            active={currentTab === "program"}
            onClick={() => setCurrentTab("program")}
          >
            {t("Analytics.program")}
          </Tab>
          {/* <Tab
            active={currentTab === "leaderboard"}
            onClick={() => setCurrentTab("leaderboard")}
          >
            Leaderboard
          </Tab> */}
          <Tab
            active={currentTab === "artist-dashboard"}
            onClick={() => setCurrentTab("artist-dashboard")}
          >
             {t("Analytics.artistdashboard")}
          </Tab>
          <Box
            marginLeft={"auto"}
            sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}
          >
            {!brandArtistId && (
              <FormControl style={{ marginLeft: "auto" }}>
                <NativeSelect
                  onChange={(event) => {
                    setArtistId(event.target.value);
                  }}
                  label="Filter"
                  input={<FormField />}
                  IconComponent={() => (
                    <KeyboardArrowDownOutlinedIcon fontSize="small" />
                  )}
                >
                  {(artistData?.data?.data || [])
                    .sort((a, b) => (a.name || "").localeCompare(b.name))
                    .map((artist) => (
                      <option key={artist.id} value={artist.id}>
                        {artist.name}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
            )}
            <FormControl style={{ marginLeft: 20 }}>
              <NativeSelect
                onChange={(event) => {
                  handleTimePeriodSelect(event.target.value);
                }}
                label="Filter"
                input={<FormField />}
                IconComponent={() => <CalendarMonthOutlined fontSize="small" />}
              >
                {dateFilterOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          {brandArtistId && (
            <ArtistProfileWrapper>
              <img
                style={{ height: "40px", width: "40px", borderRadius: "100%" }}
                src={brandArtistAvatar || ImageDefaultUser}
              ></img>
              <div className="artistname">{brandArtistName}</div>
            </ArtistProfileWrapper>
          )}
        </Box>

        {currentTab === "program" && (
          <AnalyticDashboard
            artistId={brandArtistId || artistId}
            timePeriod={timePeriod}
            data={programData}
          />
        )}
        {/* {currentTab === "leaderboard" && (
          <Leaderboard
            artistId={brandArtistId || artistId}
            timePeriod={timePeriod}
          />
        )} */}
        {currentTab === "artist-dashboard" && (
          <ArtistDashboard
            artistInfo={{ name: brandArtistName }}
            topStreams={topStreams}
            artistStats={artistStats}
            chartData={streamChart}
            artistTotalStream={artistTotalStream}
          />
        )}
      </PageWrapper>
    </>
  );
}

const ArtistProfileWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.palette.darkerGrey};
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  padding: 10px 20px;
  margin-left: 16px;

  .artistname {
    ${(props) => props.theme.typography.bodyM};
    color: ${(props) => props.theme.palette.white};
  }
`;

const Tab = styled.button`
  color: ${(props) => (props.active ? "#F4F3EE" : "#6096FF")};
  height: 56px;
  background: transparent;
  border-bottom: ${(props) => (props.active ? "1px solid #F4F3EE" : "none")};
  margin-right: 16px;
  width: fit-content;
  padding: 0;
  border-radius: 0;
  transition: all 0.3s ease;
  ${(props) => props.theme.typography.header3}
  &:hover {
    opacity: 0.7;
  }
`;

const ArtistName = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  color: #ccc;
  font-size: 18px;
`;

const LeadershipWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 24px 0 18px;
  border-radius: 6px;
  img {
    border-radius: 6px;
  }
`;
const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 44px;
  color: ${(props) => props.theme.palette.white};
  ${(props) => props.theme.typography.header1};
`;
const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.bodyM,
  border: "1px solid",
  borderColor: theme.palette.darkGrey,
  borderRadius: "6px",
  padding: "12px 12px 12px 20px",
  color: theme.palette.lightGrey,
  height: "44px",
  fontSize: 18,
  width: "100%",

  "& .MuiNativeSelect-select": {
    padding: 0,

    "&.MuiInputBase-input": {
      paddingRight: "0px",
    },
  },

  "&:first-child": {
    marginRight: "16px",
  },
}));

const CalendarIcon = styledMui(IconCalendar)(() => ({
  width: "18px",
  height: "18px",
}));

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: 18px;
  padding-right: 28px;
  margin-bottom: 16px;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const AnalyticsImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 18px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px 48px;
  width: 100%;
  height: 100%;
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 52px;
  justify-content: flex-start;
`;
const DisplayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1300px;
  height: 900px;
`;

const Text = styled.p`
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  max-width: fit-content;
`;
Analytics.propTypes = {};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Analytics);
