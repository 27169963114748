import React, { useState } from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button, Modal, Box, Divider } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import GoogleIcon from "images/icons/GoogleIcon.svg";
import { ImageCampaignLogo, ImageGiftboxLogo } from "images";
import { IconMail } from "images/icons/svg-components";
import { useMutation } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { staticBrandId } from "containers/UserPortal";

import { LoadingGIF } from "images";
import API from "utils/userAPI";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import {
  fetchUserRewardHoldings,
  fetchUserMembershipHoldings,
  fetchUserProfile,
  initialiseUserPortal,
} from "containers/UserPortal/actions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SignUpModalDesignForms from "containers/SignUpModalEdit/components/SignUpModalDesignForms";
const Verify = () => {
  const { state } = useLocation();

  const { formValues, brandId, stateValues } = state
  const { email } = formValues;
  const [error, setError] = useState("");
  const [code, setCode] = useState("      ");
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onLogin = () => {
    if (stateValues?.brandId && stateValues?.membershipId) {
      navigate(`/userhome/${stateValues.brandId}/${stateValues.membershipId}`, {
        state: {
          albumSaved: stateValues?.albumSaved,
        },
      });
    } else {
      navigate("/userhome");
    }
  };

  const onVerify = async (spotifyId) => {
    if (stateValues?.membershipType === "NFT-based membership" || spotifyId != "") {
      onLogin();
    } else if (!stateValues?.brandId && !stateValues?.membershipId) {
      navigate("/userhome");
    }
    else {
      navigate(`/link-spotify`, {
        state: {
          formValues: formValues,
          brandId: brandId,
          membershipId: stateValues?.membershipId,
        },
      });
    }
  };

  const { isLoading: isVerifying, mutate: verify } = useMutation(
    (data) => API.post(`/auth/verify/user`, data),
    {
      onSuccess: (res) => {
        if (res.data.status === "success") {
          // toast.success("Operation successful");
          window.dataLayer.push({
            'event': 'campaign_login',
            'eventCategory': 'Button Click',
            'eventAction': 'Click',
          });
          localStorage.setItem("userTokens", JSON.stringify(res.data.data));
          localStorage.setItem("userId", res.data.data.user._id);
          dispatch(initialiseUserPortal(brandId));
          dispatch(fetchUserMembershipHoldings(brandId));
          dispatch(fetchUserProfile(stateValues?.membershipId, brandId));
          onVerify(res.data.data.user.spotifyId);
        }
      },
      onError: (err) => {
        // toast.error("An error occurred");
        // console.log("hello");
        setError(err.response.data?.message || err.response.data);
        console.error("Error:", err);

        setIndex(0); // Handle error scenario as needed
      },
    }
  );

  const { isLoading: isResending, mutate: resend } = useMutation(
    (data) => API.post(`/auth/verify/user/resend`, data),
    {
      onSuccess: () => { },
      onError: (err) => {
        // toast.error("An error occurred");
        setError(err.response.data?.message || err.response.data);
        setIndex(0);
      },
    }
  );
  const maxLengthCheck = (object) => {
    if (object.target.value && object.target.value.length > 1) {
      // eslint-disable-next-line no-param-reassign
      object.target.value = object.target.value.slice(0, 1);
    }
  };

  const handleKeyPress = async (event) => {
    // Handle the delete/backspace key
    if (event.keyCode === 8 || event.keyCode === 46) {
      setCode(code.substring(0, code.length - 1));
      setIndex(index > 1 ? index - 1 : 0);
      return;
    }
    // code for copy paste but not complete
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      for (let index = 0; index < 6; index++) {
        document
          .getElementById(index + "_otpnumber")
          .setAttribute("style", "border-color:#1BC47D;");
      }
      const copiedCode = await navigator.clipboard.readText();
      setCode(copiedCode);
      copiedCode
        .split("")
        .map((char, index) => (document.getElementById(index + "_otpnumber").value = char));
      setIndex(5);
      // event.target.blur();
      handleVerify(copiedCode);
    }
  };

  const onInputChange = (evt, idx) => {
    if (evt.target.value && evt.target.value.length > 0) {
      document
        .getElementById(evt.target.id)
        .setAttribute("style", "border-color:#1BC47D;");
      let newCode = code;
      let splits = code.split("");
      splits[idx] = evt.target.value;
      newCode = splits.join("");
      setCode(newCode);
      setIndex(idx + 1);
      if (idx === 5) {
        evt.target.blur();
        // enter action
        handleVerify(newCode);
      }
    } else
      document
        .getElementById(evt.target.id)
        .setAttribute("style", "border-color: #7F86AD;");
  };

  const handleVerify = async (pincode) => {
    setError("");
    verify({
      email,
      pincode,
      brandId,
    });
  };

  const handlePaste = async (event) => {
    try {
      for (let index = 0; index < 6; index++) {
        document
          .getElementById(index + "_otpnumber")
          .setAttribute("style", "border-color:#1BC47D;");
      }
      const copiedCode = await navigator.clipboard.readText();
      setCode(copiedCode);
      copiedCode
        .split("")
        .map((char, index) => (document.getElementById(index + "_otpnumber").value = char));
      setIndex(6);
      event.target.blur();
      handleVerify(copiedCode);

    } catch (e) { console.log(e) }
  };

  const handleResend = () => {
    setError("");
    resend({
      email,
    });
  };

  return (
    <OnboardingLayout>
      <ImageDiv isCampaign={staticBrandId !== state?.brandId}>
        <img src={staticBrandId !== state?.brandId ? ImageCampaignLogo : ImageGiftboxLogo}></img>
      </ImageDiv>
      <Content onKeyDown={handleKeyPress} tabIndex="0" page={""}>
        <Wrapper>
          <MailIcon>
            <svg
              className="large-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="95"
              height="95"
              viewBox="0 0 95 95"
              fill="none"
            >
              <rect width="95" height="95" rx="47.5" fill="#294BFC" />
              <path
                d="M27.2988 39.0763L45.0099 50.1542C46.5178 51.0974 48.4822 51.0974 49.9901 50.1542L67.7011 39.0763M31.788 62.2413H63.212C65.6913 62.2413 67.7011 60.3556 67.7011 58.0295V36.9704C67.7011 34.6442 65.6913 32.7585 63.212 32.7585H31.788C29.3087 32.7585 27.2988 34.6442 27.2988 36.9704V58.0295C27.2988 60.3556 29.3087 62.2413 31.788 62.2413Z"
                stroke="#F4F3EE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              className="small-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
            >
              <rect y="0.5" width="80" height="80" rx="40" fill="#294BFC" />
              <path
                d="M22.9885 33.4061L37.9031 42.7349C39.1729 43.5292 40.8272 43.5292 42.097 42.7349L57.0115 33.4061M26.7689 52.9135H53.2312C55.319 52.9135 57.0115 51.3256 57.0115 49.3667V31.6327C57.0115 29.6739 55.319 28.0859 53.2312 28.0859H26.7689C24.681 28.0859 22.9885 29.6739 22.9885 31.6327V49.3667C22.9885 51.3256 24.681 52.9135 26.7689 52.9135Z"
                stroke="#F4F3EE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </MailIcon>
          {/* <HeaderWrapper> */}
          <div className="verify-text-section">
            <HeaderText>  {t("Verify.confirm")}</HeaderText>
            <SecondaryText>
              {t("Verify.label1")}{" "}
              <span style={{ fontWeight: "700" }}>{email}</span>
            </SecondaryText>
          </div>
          {/* </HeaderWrapper> */}
          {isVerifying ? (
            <Image src={LoadingGIF} alt="Verifying.." />
          ) : (
            <>
              <Fields onKeyDown={handleKeyPress}>
                <FormField
                  sx={formFieldOverrides}
                  type="number"
                  id={0 + "_otpnumber"}
                  onPaste={handlePaste}
                  onInput={maxLengthCheck}
                  inputProps={{
                    "aria-label": "code",
                    inputMode: 'numeric'
                  }}
                  inputRef={(input) => input && index === 0 && input.focus()}
                  onChange={(e) => onInputChange(e, 0)}
                />
                <FormField
                  sx={formFieldOverrides}
                  type="number"
                  id={1 + "_otpnumber"}
                  onInput={maxLengthCheck}
                  onPaste={handlePaste}
                  inputProps={{
                    "aria-label": "code",
                    inputMode: 'numeric'
                  }}
                  inputRef={(input) => input && index === 1 && input.focus()}
                  onChange={(e) => onInputChange(e, 1)}
                />
                <FormField
                  sx={formFieldOverrides}
                  type="number"
                  id={2 + "_otpnumber"}
                  onInput={maxLengthCheck}
                  onPaste={handlePaste}
                  inputProps={{
                    "aria-label": "code",
                    inputMode: 'numeric'
                  }}
                  inputRef={(input) => input && index === 2 && input.focus()}
                  onChange={(e) => onInputChange(e, 2)}
                />
                <FormField
                  sx={formFieldOverrides}
                  type="number"
                  id={3 + "_otpnumber"}
                  onInput={maxLengthCheck}
                  onPaste={handlePaste}
                  inputProps={{
                    "aria-label": "code",
                    inputMode: 'numeric'
                  }}
                  inputRef={(input) => input && index === 3 && input.focus()}
                  onChange={(e) => onInputChange(e, 3)}
                />
                <FormField
                  sx={formFieldOverrides}
                  type="number"
                  id={4 + "_otpnumber"}
                  onInput={maxLengthCheck}
                  onPaste={handlePaste}
                  inputProps={{
                    "aria-label": "code",
                    inputMode: 'numeric'
                  }}
                  inputRef={(input) => input && index === 4 && input.focus()}
                  onChange={(e) => onInputChange(e, 4)}
                />
                <FormField
                  sx={formFieldOverrides}
                  type="number"
                  id={5 + "_otpnumber"}
                  onPaste={handlePaste}
                  onInput={maxLengthCheck}
                  inputProps={{
                    "aria-label": "code",
                    inputMode: 'numeric'
                  }}
                  inputRef={(input) => input && index === 5 && input.focus()}
                  onChange={(e) => onInputChange(e, 5)}
                />
              </Fields>
              {error && <ErrorText>{error}</ErrorText>}
              <TertiaryText>
                {t("Verify.resend")}
                <span
                  className="sendcode"
                  style={{ color: "#6096FF", marginLeft: 16 }}
                  onClick={handleResend}
                >
                  {isResending ? t("Verify.sending") : t("Verify.resendcode")}
                </span>
              </TertiaryText>
            </>
          )}
          <TertiaryText className="tnc">
            {t("Verify.label2")}
            <br />
            <span
              style={{ color: "#6096FF" }}
              onClick={() => {
                navigate("/terms-of-use", {
                  state: {
                    ...stateValues,
                    email: email,
                    page: "verify",
                  },
                });
              }}
            >
              {" "}
              {t("Verify.termsofservices")}{" "}
            </span>
            &
            <span
              style={{ color: "#6096FF" }}
              onClick={() => {
                navigate("/privacy-policy", {
                  state: {
                    ...stateValues,
                    email: email,
                    page: "verify",
                  },
                });
              }}
            >
              {" "}
              {t("Verify.privacypolicy")}{" "}
            </span>
          </TertiaryText>
        </Wrapper>
      </Content>
      {staticBrandId === state?.brandId &&
        <Footer>
          Powered by <img src={ImageCampaignLogo} width="150px" height="24px" />
        </Footer>
      }
    </OnboardingLayout>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props) => (props.page !== "verified" ? "60px" : "0px")};
  width: 627px;
  border-radius: 16px;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.darkestGrey};
  @media (max-width: 768px) {
    width: 100%;
    background: none;
    padding: 0px 20px;
  }
`;

const OnboardingLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  gap: 32px;
  margin-top: 48px;
  @media (max-width: 768px) {
    gap: 65px;
    margin-top: 45px;
  }
`;

const Image = styled.img`
  border-radius: 100px;
  margin: 0 auto;
  border: 11px solid rgba(0, 0, 0, 0.4);
  width: 62px;
  height: 62px;
`;

const ImageDiv = styled.div`
  img {
    height: ${(props) => (props.isCampaign ? "36px" : "51px")};
    max-width: 217px;
    width: 100%;
    @media (max-width: 768px) {
      height: ${(props) => (props.isCampaign ? "28px" : "40px")};
      max-width: 168px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;
  max-height: 480px;
  .verify-text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  @media (max-width: 768px) {
    gap: 40px;
    max-height: none;
    .tnc {
      margin-top: 100px;
      ${(props) => props.theme.typography.bodyS};
    }
  }
`;
const Fields = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  @media (max-width: 768px) {
    gap: 12px;
  }
  ${"" /* @media screen and (width > 630px){
    gap: 20px;
    justify-content: center;
  } */
  }
`;

const VerifyingText = styled.span`
  font-size: 20px;
  text-align: center;
`;

const ErrorText = styled.span`
  color: red;
  text-align: center;
`;

const FormField = styledMui(InputBase)(({ theme }) => ({
  ...theme.typography.header,
  display: "flex",
  color: "#1BC47D",
  height: "60px",
  width: "51px",
}));

const formFieldOverrides = {
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },

  input: {
    padding: 0,
    textAlign: "center",
    border: "1px solid",
    borderRadius: "6px",
    borderColor: " #7F86AD",
  },
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

const MailIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: ${(props) => props.theme.palette.campaignBlue};
  border-radius: 100%;
  .small-icon {
    display: none;
  }
  @media (max-width: 768px) {
    .large-icon {
      display: none;
    }
    .small-icon {
      display: block;
    }
  }
`;

const SecondaryText = styled.span`
  text-align: center;
  white-space: normal;
  ${(props) => props.theme.typography.header3};
  line-height: normal;
  color: ${(props) => props.theme.palette.lightGrey};
`;

const TertiaryText = styled.span`
  text-align: center;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
  .sendcode {
      display: block;
  }
  @media screen and (width < 630px) {
    ${(props) => props.theme.typography.header3};
    line-height: normal;
    .tnc {
      margin-top: auto;
      ${(props) => props.theme.typography.bodyS};
    }
  }
`;

const Footer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 28px;
  ${(props) => props.theme.typography.bodyM};
  color: ${(props) => props.theme.palette.lightGrey};
`;

const HeaderText = styled.span`
  ${(props) => props.theme.typography.header1};
  text-align: center;
  line-height: normal;
  color: ${(props) => props.theme.palette.white};
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.header2};
  }
`;

const SubheaderText = styled.span`
  ${(props) => props.theme.typography.header3};
  color: ${(props) => props.theme.palette.lightGrey};
  text-align: center;
`;

export default Verify;
